import type { StageBodyComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { RenderComponentsStyled } from '../../RenderComponentsStyled.tsx';
import { useSequenceStageContext } from '../sequence/stage/sequence-stage-context.ts';

export const StageBody: FunctionComponent<StageBodyComponent> = () => {
  const stageContext = useSequenceStageContext();

  if (stageContext) {
    return <RenderComponentsStyled components={stageContext.stage.components} />;
  }

  return null;
};
