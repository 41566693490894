import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { ElementTag } from '@wirechunk/lib/mixer/element-tag.ts';
import type { BoxComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { RenderMixerChildren } from '../RenderMixerChildren.tsx';

export const boxDefaultTag: ElementTag = 'div';

// Maybe use this for a Grid component.
// const applyPrefix =
//   (prefix: Breakpoint) =>
//   (className: string): string =>
//     `${prefix}:${className}`;

export const Box: FunctionComponent<BoxComponent> = (props) => {
  const Tag = props.tag || boxDefaultTag;

  return (
    <Tag
      id={props.elementId || undefined}
      className={
        componentClassName(props)
        // Maybe use this for a Grid component.
        // props.columnWidths && columnClassNames[props.columnWidths].join(' '),
        // props.smColumnWidths &&
        //   columnClassNames[props.smColumnWidths].map(applyPrefix('sm')).join(' '),
        // props.mdColumnWidths &&
        //   columnClassNames[props.mdColumnWidths].map(applyPrefix('md')).join(' '),
        // props.lgColumnWidths &&
        //   columnClassNames[props.lgColumnWidths].map(applyPrefix('lg')).join(' '),
      }
    >
      <RenderMixerChildren>{props.children}</RenderMixerChildren>
    </Tag>
  );
};
