import type { DashboardComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { Button, DropdownMenu } from '@wirechunk/ui';
import type { FunctionComponent } from 'react';
import { Fragment, use } from 'react';
import { Link } from 'react-router';
import { CurrentUserContext } from '../../contexts/current-user-context.tsx';
import { hrefIsExternal } from '../../util/links.ts';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout.tsx';
import { MenuItem } from '../DashboardLayout/Sidebar/MenuItem/MenuItem.tsx';
import { RenderMixerChildren } from '../RenderMixerChildren.tsx';

export const Dashboard: FunctionComponent<DashboardComponent> = ({
  mainNavItems,
  extraNavItems,
  children,
}) => {
  const { user } = use(CurrentUserContext);

  return (
    <DashboardLayout
      sidebarBody={(onHide) => (
        <Fragment>
          {mainNavItems
            ?.filter(
              ({ productItem, roles }) =>
                (!productItem ||
                  (user?.__typename === 'User' && user.productItems.includes(productItem))) &&
                (!roles.length || (user?.__typename === 'User' && roles.includes(user.role))),
            )
            .map(({ id, label, url }) => (
              <MenuItem key={id} title={label} to={url} onHide={onHide} />
            ))}
        </Fragment>
      )}
      sidebarFooter={
        extraNavItems
          ? (onHide) => {
              const items = extraNavItems.filter(
                ({ productItem, roles }) =>
                  (!productItem ||
                    (user?.__typename === 'User' && user.productItems.includes(productItem))) &&
                  (!roles.length || (user?.__typename === 'User' && roles.includes(user.role))),
              );
              if (items.length) {
                return (
                  <footer className="border-top-1 py-3 px-4">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger>
                        <Button
                          type="button"
                          variant="ghost"
                          color="gray"
                          highContrast
                          className="font-medium"
                        >
                          Help and more
                        </Button>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content className="z-4">
                        {items.map(({ id, label, url }) => (
                          <DropdownMenu.Item key={id} asChild>
                            {hrefIsExternal(url) ? (
                              <a href={url} target="_blank" onClick={onHide} rel="noreferrer">
                                <span className="p-menuitem-text">{label}</span>
                              </a>
                            ) : (
                              <Link to={url} onClick={onHide}>
                                <span className="p-menuitem-text">{label}</span>
                              </Link>
                            )}
                          </DropdownMenu.Item>
                        ))}
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </footer>
                );
              }
              return null;
            }
          : null
      }
    >
      <RenderMixerChildren>{children}</RenderMixerChildren>
    </DashboardLayout>
  );
};
