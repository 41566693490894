import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren, Ref } from 'react';
import type { LayoutProps } from '../props/layout.props';
import type { MarginProps } from '../props/margin.props';
import { Box, type BoxProps } from './box';

type LegendProps = PropsWithChildren<LayoutProps & MarginProps> &
  Pick<BoxProps, 'className' | 'display'> & {
    ref?: Ref<HTMLLegendElement>;
  };

export const Legend: FunctionComponent<LegendProps> = ({ className, children, ...props }) => (
  <Box
    {...props}
    // Default browser styling includes left padding.
    pl={props.pl ?? '0'}
    className={clsx('rt-Label', 'rt-Legend', className)}
    asChild
  >
    <legend>{children}</legend>
  </Box>
);
