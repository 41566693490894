import { useQuery } from '@wirechunk/apollo-client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { OrgSitePageLinkComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { SvgContentCopy } from '@wirechunk/material-symbols-react-400/20/outlined/content-copy.tsx';
import { SvgOpenInNew } from '@wirechunk/material-symbols-react-400/20/outlined/open-in-new.tsx';
import { Flex, IconButton, Link, Tooltip } from '@wirechunk/ui';
import type { FunctionComponent } from 'react';
import { useErrorCollector } from '../../../contexts/error-collector-context.tsx';
import { useToast } from '../../../contexts/ToastContext.tsx';
import { sitePageUrl } from '../../../util/site-page-url.ts';
import { Spinner } from '../../spinner/spinner.tsx';
import { withOrgSite } from '../../with-organization-site/with-org-site.tsx';
import { OrgSitePagesFromPageTemplateDocument } from './queries.generated.ts';
import { OptionalIdFilterOperator } from '#api';

export const OrgSitePageLink: FunctionComponent<OrgSitePageLinkComponent> = withOrgSite(
  ({ site, pageTemplateId, ...props }) => {
    const { toastSuccess } = useToast();
    const { onError } = useErrorCollector();
    const { data, loading } = useQuery(OrgSitePagesFromPageTemplateDocument, {
      fetchPolicy: 'cache-and-network',
      onError,
      ...(pageTemplateId
        ? {
            variables: {
              siteId: site.id,
              pageTemplateId: {
                operator: OptionalIdFilterOperator.Equals,
                value: [pageTemplateId],
              },
            },
          }
        : { skip: true }),
    });
    const page = data?.site.pages.pages[0];

    return (
      <span className={componentClassName(props)}>
        {loading && <Spinner />}
        {page &&
          (props.displayPlainText ? (
            sitePageUrl(site.domain, page.path)
          ) : (
            <Flex display="inline-flex" align="center" gap="2" as="span">
              <Link
                href={sitePageUrl(site.domain, page.path)}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                className="flex align-items-center gap-1 line-height-3"
              >
                {props.linkText || page.title} <SvgOpenInNew />
              </Link>
              <Tooltip content="Copy link">
                <IconButton
                  variant="ghost"
                  className="h-max w-max"
                  onClick={() => {
                    void navigator.clipboard.writeText(sitePageUrl(site.domain, page.path));
                    toastSuccess('Copied page link.');
                  }}
                >
                  <SvgContentCopy />
                </IconButton>
              </Tooltip>
            </Flex>
          ))}
      </span>
    );
  },
);
