'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import * as SelectPrimitive from '@radix-ui/react-select';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { extractProps } from '../helpers/extract-props.js';
import { marginPropDefs } from '../props/margin.props.js';
import { ChevronDownIcon, ThickCheckIcon } from './icons.js';
import {
  selectRootPropDefs,
  selectTriggerPropDefs,
  selectContentPropDefs,
} from './select.props.js';
import type { MarginProps } from '../props/margin.props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import { type ComponentRef, type Ref, use } from 'react';

type SelectRootOwnProps = GetPropDefTypes<typeof selectRootPropDefs>;

const SelectContext = React.createContext<SelectRootOwnProps['size']>(undefined);

interface SelectRootProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root>,
    SelectRootOwnProps {}

const SelectRoot: React.FC<SelectRootProps> = (props) => {
  const { children, size = selectRootPropDefs.size.default, ...rootProps } = props;
  return (
    <SelectPrimitive.Root {...rootProps}>
      <SelectContext value={size}>{children}</SelectContext>
    </SelectPrimitive.Root>
  );
};
SelectRoot.displayName = 'Select.Root';

type SelectTriggerElement = React.ComponentRef<typeof SelectPrimitive.Trigger>;
type SelectTriggerOwnProps = GetPropDefTypes<typeof selectTriggerPropDefs>;
interface SelectTriggerProps
  extends ComponentPropsWithout<typeof SelectPrimitive.Trigger, RemovedProps>,
    MarginProps,
    SelectTriggerOwnProps {
  ref?: Ref<SelectTriggerElement>;
}
const SelectTrigger = ({ ref, ...props }: SelectTriggerProps) => {
  const contextSize = use(SelectContext);
  const { children, className, color, radius, placeholder, ...triggerProps } = extractProps(
    // Pass size value from the context to generate styles
    { size: contextSize, ...props },
    // Pass size prop def to allow it to be extracted
    { size: selectRootPropDefs.size },
    selectTriggerPropDefs,
    marginPropDefs,
  );
  return (
    <SelectPrimitive.Trigger asChild>
      <button
        data-accent-color={color || ''}
        data-radius={radius}
        {...triggerProps}
        ref={ref}
        className={clsx('rt-reset', 'rt-SelectTrigger', className)}
      >
        <span className="rt-SelectTriggerInner">
          <SelectPrimitive.Value placeholder={placeholder}>{children}</SelectPrimitive.Value>
        </span>
        <SelectPrimitive.Icon asChild>
          <ChevronDownIcon className="rt-SelectIcon" />
        </SelectPrimitive.Icon>
      </button>
    </SelectPrimitive.Trigger>
  );
};
SelectTrigger.displayName = 'Select.Trigger';

type SelectContentElement = React.ComponentRef<typeof SelectPrimitive.Content>;
type SelectContentOwnProps = GetPropDefTypes<typeof selectContentPropDefs>;
interface SelectContentProps
  extends ComponentPropsWithout<typeof SelectPrimitive.Content, RemovedProps>,
    SelectContentOwnProps {
  container?: React.ComponentPropsWithoutRef<typeof SelectPrimitive.Portal>['container'];
  ref?: Ref<SelectContentElement>;
}
const SelectContent = ({ ref, ...props }: SelectContentProps) => {
  const contextSize = use(SelectContext);
  const { className, children, color, container, ...contentProps } = extractProps(
    // Pass size value from the context to generate styles
    { size: contextSize, ...props },
    // Pass size prop def to allow it to be extracted
    { size: selectRootPropDefs.size },
    selectContentPropDefs,
  );
  return (
    <SelectPrimitive.Portal container={container}>
      <SelectPrimitive.Content
        data-accent-color={color || ''}
        sideOffset={4}
        {...contentProps}
        asChild={false}
        ref={ref}
        className={clsx(
          { 'rt-PopperContent': contentProps.position === 'popper' },
          'rt-SelectContent',
          className,
        )}
      >
        <ScrollAreaPrimitive.Root type="auto" className="rt-ScrollAreaRoot">
          <SelectPrimitive.Viewport asChild className="rt-SelectViewport">
            <ScrollAreaPrimitive.Viewport
              className="rt-ScrollAreaViewport"
              style={{ overflowY: undefined }}
            >
              {children}
            </ScrollAreaPrimitive.Viewport>
          </SelectPrimitive.Viewport>
          <ScrollAreaPrimitive.Scrollbar
            className="rt-ScrollAreaScrollbar rt-r-size-1"
            orientation="vertical"
          >
            <ScrollAreaPrimitive.Thumb className="rt-ScrollAreaThumb" />
          </ScrollAreaPrimitive.Scrollbar>
        </ScrollAreaPrimitive.Root>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
};
SelectContent.displayName = 'Select.Content';

type SelectItemElement = ComponentRef<typeof SelectPrimitive.Item>;
interface SelectItemProps
  extends ComponentPropsWithout<typeof SelectPrimitive.Item, RemovedProps> {}
const SelectItem = ({
  ref: forwardedRef,
  ...props
}: SelectItemProps & {
  ref?: Ref<SelectItemElement>;
}) => {
  const { className, children, ...itemProps } = props;
  return (
    <SelectPrimitive.Item
      {...itemProps}
      asChild={false}
      ref={forwardedRef}
      className={clsx('rt-SelectItem', className)}
    >
      <SelectPrimitive.ItemIndicator className="rt-SelectItemIndicator">
        <ThickCheckIcon className="rt-SelectItemIndicatorIcon" />
      </SelectPrimitive.ItemIndicator>
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
};
SelectItem.displayName = 'Select.Item';

type SelectGroupElement = ComponentRef<typeof SelectPrimitive.Group>;
interface SelectGroupProps
  extends ComponentPropsWithout<typeof SelectPrimitive.Group, RemovedProps> {}
const SelectGroup = ({
  ref: forwardedRef,
  className,
  ...props
}: SelectGroupProps & {
  ref?: Ref<SelectGroupElement>;
}) => (
  <SelectPrimitive.Group
    {...props}
    asChild={false}
    ref={forwardedRef}
    className={clsx('rt-SelectGroup', className)}
  />
);
SelectGroup.displayName = 'Select.Group';

type SelectLabelElement = ComponentRef<typeof SelectPrimitive.Label>;
interface SelectLabelProps
  extends ComponentPropsWithout<typeof SelectPrimitive.Label, RemovedProps> {}
const SelectLabel = ({
  ref: forwardedRef,
  className,
  ...props
}: SelectLabelProps & {
  ref?: Ref<SelectLabelElement>;
}) => (
  <SelectPrimitive.Label
    {...props}
    asChild={false}
    ref={forwardedRef}
    className={clsx('rt-SelectLabel', className)}
  />
);
SelectLabel.displayName = 'Select.Label';

type SelectSeparatorElement = ComponentRef<typeof SelectPrimitive.Separator>;
interface SelectSeparatorProps
  extends ComponentPropsWithout<typeof SelectPrimitive.Separator, RemovedProps> {}
const SelectSeparator = ({
  ref: forwardedRef,
  className,
  ...props
}: SelectSeparatorProps & {
  ref?: Ref<SelectSeparatorElement>;
}) => (
  <SelectPrimitive.Separator
    {...props}
    asChild={false}
    ref={forwardedRef}
    className={clsx('rt-SelectSeparator', className)}
  />
);
SelectSeparator.displayName = 'Select.Separator';

export {
  SelectRoot as Root,
  SelectTrigger as Trigger,
  SelectContent as Content,
  SelectItem as Item,
  SelectGroup as Group,
  SelectLabel as Label,
  SelectSeparator as Separator,
};

export type {
  SelectRootProps as RootProps,
  SelectTriggerProps as TriggerProps,
  SelectContentProps as ContentProps,
  SelectItemProps as ItemProps,
  SelectGroupProps as GroupProps,
  SelectLabelProps as LabelProps,
  SelectSeparatorProps as SeparatorProps,
};
