import type { FunctionComponent } from 'react';
import { Suspense, use } from 'react';
import { PageContext, ViewMode } from '../../contexts/PageContext/page-context.tsx';
import { NotFound } from '../NotFound/NotFound.tsx';
import { PageWithMeta } from '../Page/page-with-meta.tsx';
import { ErrorBoundary } from '../RootErrorBoundary/error-boundary.tsx';

export const Page: FunctionComponent = () => {
  const pageContext = use(PageContext);
  if (pageContext.viewMode === ViewMode.NotFound) {
    return <NotFound />;
  }
  const { page } = pageContext;
  return (
    page && (
      <ErrorBoundary>
        <Suspense>
          <PageWithMeta page={page} />
        </Suspense>
      </ErrorBoundary>
    )
  );
};
