import type {
  DataTableRow,
  TimeDuration,
  UploadedFile,
} from '@wirechunk/schemas/context-data/context-data';
import { isNumber, isPlainObject, isString } from 'lodash-es';
import type { RemapPropertiesToUnknown } from '../util-types.ts';
import type { ValidationResult } from '../validation-result.js';
import type { InputComponent } from './types/categories.ts';
import { isComponentWithChildren, isInputComponent } from './types/categories.ts';
import type { Component, Name, NonEmptyChildren, Styles } from './types/components.ts';
import { DataSource, PaddingAmount, TextInputComponentFormat, Width } from './types/components.ts';

export const componentHasChildren = (
  component: Component,
): component is Component & NonEmptyChildren =>
  isComponentWithChildren(component) && !!component.children?.length;

type UnknownTimeDurationValue = RemapPropertiesToUnknown<TimeDuration>;

export const isTimeDurationValue = (value: unknown): value is TimeDuration =>
  isPlainObject(value) &&
  ((value as UnknownTimeDurationValue).hours === null ||
    isNumber((value as UnknownTimeDurationValue).hours)) &&
  ((value as UnknownTimeDurationValue).minutes === null ||
    isNumber((value as UnknownTimeDurationValue).minutes));

const widths = Object.values(Width);

export const isWidth = (value: unknown): value is Width => widths.includes(value as never);

const textInputComponentFormats = Object.values(TextInputComponentFormat);

export const isTextInputComponentFormat = (value: unknown): value is TextInputComponentFormat =>
  textInputComponentFormats.includes(value as never);

export const isDataTableRow = (value: unknown): value is DataTableRow =>
  isPlainObject(value) &&
  isString((value as RemapPropertiesToUnknown<DataTableRow>).id) &&
  isPlainObject((value as RemapPropertiesToUnknown<DataTableRow>).data);

export const isDataTableRowArray = (value: unknown): value is DataTableRow[] =>
  Array.isArray(value) && value.every(isDataTableRow);

export const dataTableRowArrayOrDefaultEmpty = (value: unknown): DataTableRow[] =>
  isDataTableRowArray(value) ? value : [];

export const isUploadedFile = (value: unknown): value is UploadedFile =>
  isPlainObject(value) && isString((value as RemapPropertiesToUnknown<UploadedFile>).fileId);

export const findComponentByType = <C extends Component>(
  components: Component[],
  type: C['type'],
): C | null =>
  components.reduce<C | null>((foundComponent, component) => {
    if (foundComponent) {
      return foundComponent;
    }
    if (component.type === type) {
      return component as C;
    }
    if (componentHasChildren(component)) {
      return findComponentByType(component.children, type);
    }
    return null;
  }, null);

export const findRecursiveInComponents = (
  components: Component[],
  predicate: (comp: Component) => boolean,
): Component | null => {
  for (const comp of components) {
    if (predicate(comp)) {
      return comp;
    }
    if (comp.children) {
      const found = findRecursiveInComponents(comp.children, predicate);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export type ValidInputComponent<T extends InputComponent = InputComponent> = T & {
  name: NonNullable<Name['name']>;
};

// isValidInputComponent returns true if and only if the given component is an InputComponent that
// has a name (not just defined but non-empty).
export const isValidInputComponent = (component: Component): component is ValidInputComponent =>
  isInputComponent(component) && !!component.name;

const isMixerComponent = (value: unknown): value is Component =>
  isPlainObject(value) && isString((value as RemapPropertiesToUnknown<Component>).type);

export const isMixerComponentsArray = (value: unknown): value is Component[] =>
  Array.isArray(value) && value.every(isMixerComponent);

// TODO: This is too simplistic.
export const isStyles = (value: unknown): value is Styles => isPlainObject(value);

/**
 * Parses and returns the JSON string if it is a valid components array but otherwise returns an empty array.
 * Note the string must not be empty or an error will be thrown.
 */
export const parseComponents = (json: string): Component[] => {
  const cs = JSON.parse(json) as unknown;
  if (isMixerComponentsArray(cs)) {
    return cs;
  }
  return [];
};

/**
 * Parses and returns the JSON string if it is a valid components array but otherwise returns null.
 */
export const parseOptionalComponents = (json: string | null | undefined): Component[] | null => {
  if (json) {
    const cs = JSON.parse(json) as unknown;
    if (isMixerComponentsArray(cs)) {
      return cs;
    }
  }
  return null;
};

export const parseStyles = (json: string): ValidationResult<Styles> => {
  if (!json) {
    return {
      ok: false,
      error: 'Invalid JSON provided.',
    };
  }
  try {
    const parsed: unknown = JSON.parse(json);
    if (isStyles(parsed)) {
      return {
        ok: true,
        value: parsed,
      };
    }
    return {
      ok: false,
      error: 'Data is not a valid styles object.',
    };
  } catch (err) {
    return {
      ok: false,
      error: `Invalid JSON for a styles object. ${err instanceof Error ? err.message : 'Unknown error'}.`,
    };
  }
};

const paddingAmounts = Object.values(PaddingAmount);

export const isPaddingAmount = (value: unknown): value is PaddingAmount =>
  paddingAmounts.includes(value as never);

export const isDataSource = (value: unknown): value is DataSource =>
  Object.values(DataSource).includes(value as never);
