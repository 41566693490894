import type { MixerEventType } from '@wirechunk/lib/mixer/types/components.ts';
import { useLayoutEffect, useRef } from 'react';
import type { MixerEventDetailForType } from '../../util/mixer/events.ts';
import { isMixerEventForType, mixerQualifiedEventType } from '../../util/mixer/events.ts';

/** Register an event listener for a custom Mixer event. */
export const useMixerEventListener = <T extends MixerEventType>(
  type: T,
  callback: (detail: MixerEventDetailForType<T>) => void,
): void => {
  const savedCallback = useRef(callback);

  // A layout effect so we always have the most up-to-date instance of the callback when handling an event.
  // This simplifies the model for users of this hook because they don't need to worry about a stale version
  // of their callback being invoked and don't need to code defensively against that.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // A layout effect so that we can guarantee to be notified of events between the time the screen is painted
  // and the time when a useEffect hook could be run.
  useLayoutEffect(() => {
    const onEvent = (e: Event) => {
      if (isMixerEventForType(e, type)) {
        savedCallback.current(e.detail);
      }
    };
    const qualifiedType = mixerQualifiedEventType(type);
    window.document.addEventListener(qualifiedType, onEvent);
    return () => {
      window.document.removeEventListener(qualifiedType, onEvent);
    };
  }, [type]);
};
