import { booleanOrDefaultFalse } from '@wirechunk/lib/booleans.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { BooleanInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { MixerEventType } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { stringOrDefaultEmpty } from '@wirechunk/lib/strings.ts';
import { Flex } from '@wirechunk/ui';
import type { CheckboxChangeEvent } from 'primereact/checkbox';
import { Checkbox } from 'primereact/checkbox';
import { type FunctionComponent } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import type { MixerEventDetailForType } from '../../../util/mixer/events.ts';
import { mixerQualifiedEventType } from '../../../util/mixer/events.ts';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.tsx';
import { MixerLabel } from '../../mixer-common/mixer-label.tsx';
import { useStringValue } from '../../mixer-hocs/use-string-value.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';

export const GuardedBooleanInput: FunctionComponent<ValidInputComponent<BooleanInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const inputId = useInputId(props);

  const validationError = getValidationError(props);
  // Notice we coalesce an empty string to true.
  const trueValue = useStringValue(props) || true;

  const stringValue = stringOrDefaultEmpty(getValue(props));
  // Always a boolean value.
  const inputValue = stringValue
    ? stringValue === trueValue
    : booleanOrDefaultFalse(getValue(props));

  const handleChange = (event: CheckboxChangeEvent) => {
    const value = event.checked ? trueValue : false;
    setValue(props, value);
    const mixerEvent = new CustomEvent<MixerEventDetailForType<MixerEventType.InputChange>>(
      mixerQualifiedEventType(MixerEventType.InputChange),
      {
        detail: {
          type: MixerEventType.InputChange,
          component: props,
          value,
        },
      },
    );
    document.dispatchEvent(mixerEvent);
  };

  return (
    <div className={componentClassName(props)}>
      <Flex align="center" gapX="2" gapY="1">
        <Checkbox inputId={inputId} checked={inputValue} onChange={handleChange} />
        <MixerLabel label={props.label} inputId={inputId} />
      </Flex>
      {validationError && (
        <InputNotice severity={NoticeSeverity.Error}>{validationError}</InputNotice>
      )}
    </div>
  );
};

export const BooleanInput = withValidInputComponent<BooleanInputComponent>(GuardedBooleanInput);
