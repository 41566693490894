import { Select } from '@wirechunk/ui';
import { clsx } from 'clsx';
import type {
  DataTableProps,
  DataTableRowClassNameOptions,
  DataTableRowClickEvent,
  DataTableRowData,
  DataTableValue,
} from 'primereact/datatable';
import { DataTable } from 'primereact/datatable';
import type { PaginatorTemplate } from 'primereact/paginator';
import type { PropsWithChildren, ReactElement } from 'react';

type DataTableWithPaginatorProps<TValue extends DataTableValue> = PropsWithChildren<{
  // dataKey defaults to 'id'
  dataKey?: string;
  // The records are expected to each have an id property.
  value: TValue[];
  loading: boolean;
  totalRecords: number;
  page: number;
  limit: number;
  rowsMinimum?: number;
  rowsMaximum?: number;
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
  expandedRows?: DataTableProps<TValue[]>['expandedRows'];
  onRowToggle?: DataTableProps<TValue[]>['onRowToggle'];
  rowExpansionTemplate?: DataTableProps<TValue[]>['rowExpansionTemplate'];
  dense?: boolean;
  emptyMessage?: string;
  noBottomBorder?: boolean;
  tableClassName?: string;
  rowClassName?: (
    data: DataTableRowData<TValue[]>,
    options: DataTableRowClassNameOptions<TValue[]>,
  ) => string | undefined;
  paginatorClassName?: string;
  onRowClick?: (event: DataTableRowClickEvent) => void;
}>;

const pageLimits = [10, 20, 50, 100] as const;

export const DataTableWithPaginator = <TValue extends DataTableValue>({
  dataKey = 'id',
  value,
  loading,
  page,
  limit,
  totalRecords,
  setPage,
  setLimit,
  rowsMinimum = 10,
  rowsMaximum = 100,
  expandedRows,
  onRowToggle,
  rowExpansionTemplate,
  dense,
  emptyMessage,
  noBottomBorder,
  tableClassName,
  rowClassName,
  paginatorClassName,
  onRowClick,
  children,
}: DataTableWithPaginatorProps<TValue>): ReactElement => {
  const paginatorTemplate: PaginatorTemplate = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: () => (
      <Select.Root
        value={limit.toString()}
        onValueChange={(value) => {
          const valueNumber = Number(value);
          if (pageLimits.includes(valueNumber as never)) {
            setLimit(valueNumber);
            setPage(0);
          }
        }}
      >
        <Select.Trigger className="mr-2">
          Rows per page: <span className="font-medium">{limit}</span>
        </Select.Trigger>
        <Select.Content>
          {pageLimits.map(
            (value) =>
              value >= rowsMinimum &&
              value <= rowsMaximum && (
                <Select.Item key={value} value={value.toString()}>
                  {value}
                </Select.Item>
              ),
          )}
        </Select.Content>
      </Select.Root>
    ),
    CurrentPageReport: (options) => (
      <span className="text-align-center mx-2 text-color-body">
        {options.first} – {options.last} of {totalRecords}
      </span>
    ),
  };

  const paginate = totalRecords > 10;

  return (
    <DataTable<TValue[]>
      dataKey={dataKey}
      value={value}
      loading={loading}
      rows={limit}
      first={page * limit}
      totalRecords={totalRecords}
      onPage={(e) => {
        setPage(e.page || 0);
      }}
      expandedRows={expandedRows}
      onRowToggle={onRowToggle}
      rowExpansionTemplate={rowExpansionTemplate}
      emptyMessage={emptyMessage}
      className={dense ? 'dense' : undefined}
      tableClassName={clsx(
        tableClassName,
        noBottomBorder && !paginate && 'table-last-row-border-bottom-none',
      )}
      rowClassName={rowClassName || undefined}
      paginatorClassName={clsx(
        paginatorClassName,
        noBottomBorder && paginate && 'border-bottom-none',
      )}
      paginatorTemplate={paginatorTemplate}
      paginator={paginate}
      onRowClick={onRowClick}
      lazy
    >
      {children}
    </DataTable>
  );
};
