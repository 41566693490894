import * as React from 'react';
import { clsx } from 'clsx';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { extractProps } from '../helpers/extract-props.js';
import { requireReactElement } from '../helpers/require-react-element.js';
import { popoverContentPropDefs } from './popover.props.js';

import type { PopoverContentOwnProps } from './popover.props.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { ComponentRef, Ref } from 'react';

interface PopoverRootProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Root> {}
const PopoverRoot: React.FC<PopoverRootProps> = (props: PopoverRootProps) => (
  <PopoverPrimitive.Root {...props} />
);
PopoverRoot.displayName = 'Popover.Root';

type PopoverTriggerElement = ComponentRef<typeof PopoverPrimitive.Trigger>;
interface PopoverTriggerProps
  extends ComponentPropsWithout<typeof PopoverPrimitive.Trigger, RemovedProps> {}
const PopoverTrigger = ({
  ref: forwardedRef,
  children,
  ...props
}: PopoverTriggerProps & {
  ref?: Ref<PopoverTriggerElement>;
}) => (
  <PopoverPrimitive.Trigger {...props} ref={forwardedRef} asChild>
    {requireReactElement(children)}
  </PopoverPrimitive.Trigger>
);
PopoverTrigger.displayName = 'Popover.Trigger';

type PopoverContentElement = ComponentRef<typeof PopoverPrimitive.Content>;
interface PopoverContentProps
  extends ComponentPropsWithout<typeof PopoverPrimitive.Content, RemovedProps>,
    PopoverContentOwnProps {
  container?: React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Portal>['container'];
}
const PopoverContent = ({
  ref: forwardedRef,
  ...props
}: PopoverContentProps & {
  ref?: Ref<PopoverContentElement>;
}) => {
  const { className, forceMount, container, ...contentProps } = extractProps(
    props,
    popoverContentPropDefs,
  );
  return (
    <PopoverPrimitive.Portal container={container} forceMount={forceMount}>
      <PopoverPrimitive.Content
        align="start"
        sideOffset={8}
        collisionPadding={10}
        {...contentProps}
        ref={forwardedRef}
        className={clsx('rt-PopperContent', 'rt-PopoverContent', className)}
      />
    </PopoverPrimitive.Portal>
  );
};
PopoverContent.displayName = 'Popover.Content';

type PopoverCloseElement = ComponentRef<typeof PopoverPrimitive.Close>;
interface PopoverCloseProps
  extends ComponentPropsWithout<typeof PopoverPrimitive.Close, RemovedProps> {}
const PopoverClose = ({
  ref: forwardedRef,
  children,
  ...props
}: PopoverCloseProps & {
  ref?: Ref<PopoverCloseElement>;
}) => (
  <PopoverPrimitive.Close {...props} ref={forwardedRef} asChild>
    {requireReactElement(children)}
  </PopoverPrimitive.Close>
);
PopoverClose.displayName = 'Popover.Close';

type PopoverAnchorElement = ComponentRef<typeof PopoverPrimitive.Anchor>;
interface PopoverAnchorProps
  extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Anchor> {}
const PopoverAnchor = ({
  ref: forwardedRef,
  children,
  ...props
}: PopoverAnchorProps & {
  ref?: Ref<PopoverAnchorElement>;
}) => <PopoverPrimitive.Anchor {...props} ref={forwardedRef} />;

PopoverAnchor.displayName = 'Popover.Anchor';

export {
  PopoverRoot as Root,
  PopoverContent as Content,
  PopoverTrigger as Trigger,
  PopoverClose as Close,
  PopoverAnchor as Anchor,
};
export type {
  PopoverRootProps as RootProps,
  PopoverContentProps as ContentProps,
  PopoverTriggerProps as TriggerProps,
  PopoverCloseProps as CloseProps,
  PopoverAnchorProps as AnchorProps,
};
