import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { Components } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.tsx';
import { useInputValidationErrorMessage } from './input-validation-error-message-context.ts';

export const InputValidationErrorMessage: FunctionComponent<
  Components['InputValidationErrorMessage']
> = (props) => {
  const validationError = useInputValidationErrorMessage();
  return (
    validationError && (
      <InputNotice className={componentClassName(props)} severity={NoticeSeverity.Error}>
        {validationError}
      </InputNotice>
    )
  );
};
