import { SvgClose } from '@wirechunk/material-symbols-react-400/20/outlined/close.tsx';
import { noop } from 'lodash-es';
import { Button } from 'primereact/button';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { use, Fragment, useMemo, createContext, useState } from 'react';
import type { ErrorHandler } from '../hooks/useErrorHandler.tsx';
import { handleKnownError, parseWebErrorMessage } from '../util/errors.ts';
import { useSessionStatusContext } from './SessionStatusContext/session-status-context.tsx';
import { useToast } from './ToastContext.tsx';

export type ErrorCollectorContext = Pick<ErrorHandler, 'onError'>;

const ErrorCollectorContext = createContext<ErrorCollectorContext>({
  onError: noop,
});

export const ErrorCollectorContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { toastError } = useToast();
  const [messages, setMessages] = useState<string[]>([]);
  const sessionContext = useSessionStatusContext();

  const value = useMemo<ErrorCollectorContext>(
    () => ({
      onError: (error) => {
        if (!handleKnownError(error, sessionContext, toastError)) {
          const message = parseWebErrorMessage(error);
          setMessages((messages) =>
            messages.includes(message) ? messages : [...messages, message],
          );
        }
      },
    }),
    [sessionContext, toastError],
  );

  return (
    <Fragment>
      {messages.length > 0 && (
        <div className="py-1 px-3 bg-red-9 text-white flex flex-column gap-1 opacity-90 absolute top-0 w-full z-5">
          {messages.map((message) => (
            <div key={message} className="flex justify-content-between align-items-center">
              <div className="font-medium text-sm">{message}</div>
              <Button
                className="background-none border-none border-circle p-1"
                onClick={() => {
                  setMessages((messages) => messages.filter((m) => m !== message));
                }}
              >
                <SvgClose fill="white" width="21px" height="21px" />
              </Button>
            </div>
          ))}
        </div>
      )}
      <ErrorCollectorContext value={value}>{children}</ErrorCollectorContext>
    </Fragment>
  );
};

export const useErrorCollector = (): ErrorCollectorContext => use(ErrorCollectorContext);
