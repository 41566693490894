import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { MapComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import type { FunctionComponent } from 'react';
import { use } from 'react';
import { PropsContext } from '../../contexts/props-context.ts';
import { GoogleMap } from '../GoogleMap/GoogleMap.tsx';

export const Map: FunctionComponent<MapComponent> = (props) => {
  let googlePlaceId: string | null | undefined;
  if (props.focus?.type === DataSource.Prop) {
    const propName = props.focus.name;
    if (propName) {
      const propsContext = use(PropsContext);
      googlePlaceId = stringOrDefaultNull(propsContext[propName]);
    }
  } else {
    googlePlaceId = props.focus?.googlePlaceId;
  }

  if (!googlePlaceId) {
    return null;
  }

  return <GoogleMap className={componentClassName(props)} googlePlaceId={googlePlaceId} />;
};
