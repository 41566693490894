import { useQuery } from '@wirechunk/apollo-client';
import type { ComponentType, FunctionComponent } from 'react';
import type { RegularCurrentUser } from '../../contexts/current-user-context.tsx';
import { withRegularCurrentUser } from '../../contexts/current-user-context.tsx';
import type { SiteContext } from '../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../hooks/useErrorHandler.tsx';
import { Spinner } from '../spinner/spinner.tsx';
import { OrgSiteContextDocument } from './queries.generated.ts';

type OrgSite = {
  site: SiteContext;
};

export const withOrgSite = <P,>(
  WrappedComponent: ComponentType<P & OrgSite & RegularCurrentUser>,
): FunctionComponent<P> =>
  withRegularCurrentUser((props) => {
    const { onError, ErrorMessage } = useErrorHandler();
    const { data, loading } = useQuery(OrgSiteContextDocument, {
      onError,
      fetchPolicy: 'cache-and-network',
      variables: {
        organizationId: props.user.orgId,
      },
    });

    if (loading) {
      return <Spinner py="3" />;
    }

    const site = data?.organization.site;
    if (site) {
      return <WrappedComponent {...props} site={site} />;
    }

    return <ErrorMessage />;
  });
