import { useQuery } from '@wirechunk/apollo-client';
import { formatDateTime } from '@wirechunk/lib/dates.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { StageFileInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { FileTypeCategory } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { isUploadedFile } from '@wirechunk/lib/mixer/utils.ts';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useFileDownload } from '../../../hooks/useFileDownload.ts';
import { useFileGetUrl } from '../../../hooks/useFileGetUrl/useFileGetUrl.ts';
import { usePollFileStatus } from '../../../hooks/usePollFileStatus/usePollFileStatus.ts';
import { useUploadFile } from '../../../hooks/useUploadFile/useUploadFile.ts';
import {
  isAudioMimeType,
  isVideoMimeType,
  mimeTypeToFileTypeCategory,
} from '../../../util/inputs.ts';
import { FileUpload } from '../../FileUpload/FileUpload.tsx';
import { Play } from '../../icons/Play.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { useSequenceStageContext } from '../sequence/stage/sequence-stage-context.ts';
import { FileDetailsDocument } from './queries.generated.ts';
import { FileStatus } from '#api';
import type { UploadFilePurpose } from '#api';

const defaultAllowedTypes: FileTypeCategory[] = Object.values(FileTypeCategory);

const GuardedStageFileInput: FunctionComponent<ValidInputComponent<StageFileInputComponent>> = (
  props,
) => {
  const stageContext = useSequenceStageContext();
  const { getValue } = useInputDataContext(props);
  const value = getValue(props);
  const fileId = isUploadedFile(value) ? value.fileId : null;
  const { onError, ErrorMessage } = useErrorHandler();
  const { getUrl, isLoading: isGettingUrl } = useFileGetUrl(onError);
  const { download } = useFileDownload();
  const uploadFile = useUploadFile(onError);
  const { startPollingFile, polling } = usePollFileStatus(onError);
  const {
    data,
    refetch,
    loading: fileDetailsLoading,
  } = useQuery(FileDetailsDocument, {
    onError,
    ...(fileId ? { variables: { id: fileId } } : { skip: true }),
  });
  const file = data?.file;
  const [showUploadInput, setShowUploadInput] = useState(file?.status !== FileStatus.Uploaded);
  const [showPlayer, setShowPlayer] = useState(false);
  const [playerFileUrl, setPlayerFileUrl] = useState<string | null>(null);

  useEffect(() => {
    if (file) {
      if (file.status === FileStatus.Uploaded) {
        setShowUploadInput(false);
        setPlayerFileUrl(null);
      } else if (file.status === FileStatus.Uploading) {
        startPollingFile(file.id, (status) => {
          if (status === FileStatus.Uploaded) {
            setShowUploadInput(false);
            void refetch();
          }
        });
      }
    }
  }, [file, startPollingFile, fileDetailsLoading, refetch]);

  const sequenceStageUser = stageContext?.sequenceStageUser;
  const purpose = useMemo<UploadFilePurpose | null>(
    () =>
      sequenceStageUser
        ? {
            sequenceStageUser: {
              sequenceStageUserId: sequenceStageUser.id,
              stateProperty: props.name,
            },
          }
        : null,
    [sequenceStageUser, props.name],
  );

  if (!purpose) {
    return null;
  }

  let uploadedFileDescription: string | null = null;
  if (file) {
    const type = mimeTypeToFileTypeCategory(file.mimeType);
    if (type) {
      uploadedFileDescription = `${type} file`;
    } else {
      uploadedFileDescription = 'File';
    }
  }

  const isAudio = file && isAudioMimeType(file.mimeType);
  const isVideo = file && isVideoMimeType(file.mimeType);

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      {polling ? (
        <div className="flex align-items-center gap-2 mb-2">
          <i className="pi pi-spinner pi-spin" /> Uploading a file&hellip;
        </div>
      ) : fileDetailsLoading ? (
        <Spinner py="3" />
      ) : (
        file && (
          <div>
            <div className="flex align-items-center gap-3">
              <div>
                {uploadedFileDescription} uploaded on {formatDateTime(file.createdAt)}
              </div>
              {(isAudio || isVideo) && (
                <Button
                  className="p-button-sm flex align-items-center gap-2 min-w-max w-max"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async () => {
                    setShowPlayer(true);
                    const url = await getUrl(file.id, false);
                    setPlayerFileUrl(url);
                  }}
                >
                  <span>Play</span>
                  <Play width="9.6" height="10.8" pathClassName="fill-white" />
                </Button>
              )}
              <Button
                label="Download"
                className="p-button-sm min-w-max w-max"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  const url = await getUrl(file.id, true);
                  download(file.id, url, file.downloadName);
                }}
              />
            </div>
            {showPlayer &&
              (playerFileUrl ? (
                isAudio ? (
                  <audio
                    autoPlay
                    controls
                    controlsList="nodownload noplaybackrate"
                    src={playerFileUrl}
                  />
                ) : (
                  <video
                    autoPlay
                    controls
                    controlsList="nodownload noplaybackrate"
                    src={playerFileUrl}
                    className="w-full mt-3"
                  />
                )
              ) : (
                isGettingUrl && <Spinner py="3" />
              ))}
          </div>
        )
      )}
      {showUploadInput ? (
        <div className={file ? 'mt-3' : undefined}>
          <FileUpload
            purpose={purpose}
            types={props.allowedTypes || defaultAllowedTypes}
            showClose={!!file}
            ErrorMessage={ErrorMessage}
            uploadFile={uploadFile}
            onUploaded={() => {
              //
            }}
            onClose={() => {
              setShowUploadInput(false);
            }}
          />
        </div>
      ) : (
        file && (
          <Button
            label="Replace file"
            className="p-button-sm p-button-secondary mt-3"
            onClick={() => {
              setShowUploadInput(true);
              setShowPlayer(false);
            }}
          />
        )
      )}
    </div>
  );
};

export const StageFileInput =
  withValidInputComponent<StageFileInputComponent>(GuardedStageFileInput);
