import { booleanOrDefaultFalse } from '@wirechunk/lib/booleans.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { MultipleChoiceInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { SvgCheck } from '@wirechunk/material-symbols-react-400/20/outlined/check.tsx';
import { SvgClose } from '@wirechunk/material-symbols-react-400/20/outlined/close.tsx';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';
import { RadioGroupContainer, RadioGroupItemWithLabel } from '../../radio-group/radio-group.tsx';

const GuardedMultipleChoiceInput: FunctionComponent<
  ValidInputComponent<MultipleChoiceInputComponent>
> = (props) => {
  const { getValue, setValue } = useInputDataContext(props);
  const inputIdRoot = useInputId(props);
  const inputValue = booleanOrDefaultFalse(getValue(props));
  const { question, choices, answerIndex } = props;

  const [selection, setSelection] = useState<number | null>(null);

  // We can't use the PrimeReact RadioButton component here because on Chrome and Safari it includes an absolutely
  // positioned child that screws up scrolling when it appears inside a grid item that scrolls.
  return (
    <div className={componentClassName(props)}>
      <RadioGroupContainer
        legend={question ?? undefined}
        value={inputValue ? (answerIndex?.toString() ?? '') : (selection?.toString() ?? '')}
        onValueChange={(value) => {
          // Don't allow changes after a correct submission.
          if (!inputValue) {
            const valueNumber = Number(value);
            setSelection(isNaN(valueNumber) ? null : valueNumber);
            if (valueNumber === answerIndex) {
              setValue(props, true);
            }
          }
        }}
        // Slightly less bottom margin than the FormField default.
        className="mb-2"
      >
        {choices?.map((choice, index) => (
          <RadioGroupItemWithLabel
            key={index}
            id={`${inputIdRoot}-${index}`}
            value={index.toString()}
            labelClassName="font-normal"
          >
            {choice}
          </RadioGroupItemWithLabel>
        ))}
      </RadioGroupContainer>
      {inputValue ? (
        <div className="flex align-items-center gap-1 mt-2 text-green-9">
          <SvgCheck /> <span className="font-medium">Correct</span>
        </div>
      ) : (
        selection !== null && (
          <div className="flex align-items-center gap-2 mt-2 text-red-9">
            <SvgClose /> <span className="font-medium">Not quite. Try again!</span>
          </div>
        )
      )}
    </div>
  );
};

export const QuizMultipleChoiceInput = withValidInputComponent<MultipleChoiceInputComponent>(
  GuardedMultipleChoiceInput,
);
