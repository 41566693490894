'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import { Slottable } from '@radix-ui/react-slot';

import { ScrollArea } from './scroll-area.js';
import {
  contextMenuContentPropDefs,
  contextMenuItemPropDefs,
  contextMenuCheckboxItemPropDefs,
  contextMenuRadioItemPropDefs,
} from './context-menu.props.js';
import { ThickCheckIcon, ThickChevronRightIcon } from './icons.js';
import { extractProps } from '../helpers/extract-props.js';
import { requireReactElement } from '../helpers/require-react-element.js';

import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

interface ContextMenuRootProps
  extends React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Root> {}
const ContextMenuRoot: React.FC<ContextMenuRootProps> = (props) => (
  <ContextMenuPrimitive.Root {...props} />
);
ContextMenuRoot.displayName = 'ContextMenu.Root';

type ContextMenuTriggerElement = ComponentRef<typeof ContextMenuPrimitive.Trigger>;
interface ContextMenuTriggerProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.Trigger, RemovedProps> {}
const ContextMenuTrigger = ({
  ref: forwardedRef,
  children,
  ...props
}: ContextMenuTriggerProps & {
  ref?: Ref<ContextMenuTriggerElement>;
}) => (
  <ContextMenuPrimitive.Trigger {...props} ref={forwardedRef} asChild>
    {requireReactElement(children)}
  </ContextMenuPrimitive.Trigger>
);
ContextMenuTrigger.displayName = 'ContextMenu.Trigger';

type ContextMenuContentOwnProps = GetPropDefTypes<typeof contextMenuContentPropDefs>;
type ContextMenuContentContextValue = ContextMenuContentOwnProps;
const ContextMenuContentContext = React.createContext<ContextMenuContentContextValue>({});
type ContextMenuContentElement = ComponentRef<typeof ContextMenuPrimitive.Content>;
interface ContextMenuContentProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.Content, RemovedProps>,
    ContextMenuContentContextValue {
  container?: React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Portal>['container'];
}
const ContextMenuContent = ({
  ref: forwardedRef,
  ...props
}: ContextMenuContentProps & {
  ref?: Ref<ContextMenuContentElement>;
}) => {
  const {
    size = contextMenuContentPropDefs.size.default,
    variant = contextMenuContentPropDefs.variant.default,
    highContrast = contextMenuContentPropDefs.highContrast.default,
  } = props;
  const { className, children, color, container, forceMount, ...contentProps } = extractProps(
    props,
    contextMenuContentPropDefs,
  );
  return (
    <ContextMenuPrimitive.Portal container={container} forceMount={forceMount}>
      <ContextMenuPrimitive.Content
        data-accent-color={color || ''}
        alignOffset={-Number(size) * 4}
        collisionPadding={10}
        {...contentProps}
        asChild={false}
        ref={forwardedRef}
        className={clsx(
          'rt-PopperContent',
          'rt-BaseMenuContent',
          'rt-ContextMenuContent',
          className,
        )}
      >
        <ScrollArea type="auto">
          <div className={clsx('rt-BaseMenuViewport', 'rt-ContextMenuViewport')}>
            <ContextMenuContentContext
              value={React.useMemo(
                () => ({ size, variant, color, highContrast }),
                [size, variant, color, highContrast],
              )}
            >
              {children}
            </ContextMenuContentContext>
          </div>
        </ScrollArea>
      </ContextMenuPrimitive.Content>
    </ContextMenuPrimitive.Portal>
  );
};
ContextMenuContent.displayName = 'ContextMenu.Content';

type ContextMenuLabelElement = ComponentRef<typeof ContextMenuPrimitive.Label>;
interface ContextMenuLabelProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.Label, RemovedProps> {}
const ContextMenuLabel = ({
  ref: forwardedRef,
  className,
  ...props
}: ContextMenuLabelProps & {
  ref?: Ref<ContextMenuLabelElement>;
}) => (
  <ContextMenuPrimitive.Label
    {...props}
    asChild={false}
    ref={forwardedRef}
    className={clsx('rt-BaseMenuLabel', 'rt-ContextMenuLabel', className)}
  />
);
ContextMenuLabel.displayName = 'ContextMenu.Label';

type ContextMenuItemElement = ComponentRef<typeof ContextMenuPrimitive.Item>;
type ContextMenuItemOwnProps = GetPropDefTypes<typeof contextMenuItemPropDefs>;
interface ContextMenuItemProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.Item, RemovedProps>,
    ContextMenuItemOwnProps {}
const ContextMenuItem = ({
  ref: forwardedRef,
  ...props
}: ContextMenuItemProps & {
  ref?: Ref<ContextMenuItemElement>;
}) => {
  const {
    className,
    children,
    color = contextMenuItemPropDefs.color.default,
    shortcut,
    ...itemProps
  } = props;
  return (
    <ContextMenuPrimitive.Item
      data-accent-color={color}
      {...itemProps}
      ref={forwardedRef}
      className={clsx('rt-reset', 'rt-BaseMenuItem', 'rt-ContextMenuItem', className)}
    >
      <Slottable>{children}</Slottable>
      {shortcut && <div className="rt-BaseMenuShortcut rt-ContextMenuShortcut">{shortcut}</div>}
    </ContextMenuPrimitive.Item>
  );
};
ContextMenuItem.displayName = 'ContextMenu.Item';

type ContextMenuGroupElement = ComponentRef<typeof ContextMenuPrimitive.Group>;
interface ContextMenuGroupProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.Group, RemovedProps> {}
const ContextMenuGroup = ({
  ref: forwardedRef,
  className,
  ...props
}: ContextMenuGroupProps & {
  ref?: Ref<ContextMenuGroupElement>;
}) => (
  <ContextMenuPrimitive.Group
    {...props}
    asChild={false}
    ref={forwardedRef}
    className={clsx('rt-BaseMenuGroup', 'rt-ContextMenuGroup', className)}
  />
);
ContextMenuGroup.displayName = 'ContextMenu.Group';

type ContextMenuRadioGroupElement = ComponentRef<typeof ContextMenuPrimitive.RadioGroup>;
interface ContextMenuRadioGroupProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.RadioGroup, RemovedProps> {}
const ContextMenuRadioGroup = ({
  ref: forwardedRef,
  className,
  ...props
}: ContextMenuRadioGroupProps & {
  ref?: Ref<ContextMenuRadioGroupElement>;
}) => (
  <ContextMenuPrimitive.RadioGroup
    {...props}
    asChild={false}
    ref={forwardedRef}
    className={clsx('rt-BaseMenuRadioGroup', 'rt-ContextMenuRadioGroup', className)}
  />
);
ContextMenuRadioGroup.displayName = 'ContextMenu.RadioGroup';

type ContextMenuRadioItemElement = ComponentRef<typeof ContextMenuPrimitive.RadioItem>;
type ContextMenuRadioItemOwnProps = GetPropDefTypes<typeof contextMenuRadioItemPropDefs>;
interface ContextMenuRadioItemProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.RadioItem, RemovedProps>,
    ContextMenuRadioItemOwnProps {}
const ContextMenuRadioItem = ({
  ref: forwardedRef,
  ...props
}: ContextMenuRadioItemProps & {
  ref?: Ref<ContextMenuRadioItemElement>;
}) => {
  const {
    children,
    className,
    color = contextMenuRadioItemPropDefs.color.default,
    ...itemProps
  } = props;
  return (
    <ContextMenuPrimitive.RadioItem
      {...itemProps}
      asChild={false}
      ref={forwardedRef}
      data-accent-color={color}
      className={clsx(
        'rt-BaseMenuItem',
        'rt-BaseMenuRadioItem',
        'rt-ContextMenuItem',
        'rt-ContextMenuRadioItem',
        className,
      )}
    >
      <Slottable>{children}</Slottable>
      <ContextMenuPrimitive.ItemIndicator className="rt-BaseMenuItemIndicator rt-ContextMenuItemIndicator">
        <ThickCheckIcon className="rt-BaseMenuItemIndicatorIcon rt-ContextMenuItemIndicatorIcon" />
      </ContextMenuPrimitive.ItemIndicator>
    </ContextMenuPrimitive.RadioItem>
  );
};
ContextMenuRadioItem.displayName = 'ContextMenu.RadioItem';

type ContextMenuCheckboxItemElement = ComponentRef<typeof ContextMenuPrimitive.CheckboxItem>;
type ContextMenuCheckboxItemOwnProps = GetPropDefTypes<typeof contextMenuCheckboxItemPropDefs>;
interface ContextMenuCheckboxItemProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.CheckboxItem, RemovedProps>,
    ContextMenuCheckboxItemOwnProps {}
const ContextMenuCheckboxItem = ({
  ref: forwardedRef,
  ...props
}: ContextMenuCheckboxItemProps & {
  ref?: Ref<ContextMenuCheckboxItemElement>;
}) => {
  const {
    children,
    className,
    shortcut,
    color = contextMenuCheckboxItemPropDefs.color.default,
    ...itemProps
  } = props;
  return (
    <ContextMenuPrimitive.CheckboxItem
      {...itemProps}
      asChild={false}
      ref={forwardedRef}
      data-accent-color={color}
      className={clsx(
        'rt-BaseMenuItem',
        'rt-BaseMenuCheckboxItem',
        'rt-ContextMenuItem',
        'rt-ContextMenuCheckboxItem',
        className,
      )}
    >
      <Slottable>{children}</Slottable>
      <ContextMenuPrimitive.ItemIndicator className="rt-BaseMenuItemIndicator rt-ContextMenuItemIndicator">
        <ThickCheckIcon className="rt-BaseMenuItemIndicatorIcon rt-ContextMenuItemIndicatorIcon" />
      </ContextMenuPrimitive.ItemIndicator>
      {shortcut && <div className="rt-BaseMenuShortcut rt-ContextMenuShortcut">{shortcut}</div>}
    </ContextMenuPrimitive.CheckboxItem>
  );
};
ContextMenuCheckboxItem.displayName = 'ContextMenu.CheckboxItem';

interface ContextMenuSubProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.Sub, RemovedProps> {}
const ContextMenuSub: React.FC<ContextMenuSubProps> = (props) => (
  <ContextMenuPrimitive.Sub {...props} />
);
ContextMenuSub.displayName = 'ContextMenu.Sub';

type ContextMenuSubTriggerElement = ComponentRef<typeof ContextMenuPrimitive.SubTrigger>;
interface ContextMenuSubTriggerProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.SubTrigger, RemovedProps> {}
const ContextMenuSubTrigger = ({
  ref: forwardedRef,
  ...props
}: ContextMenuSubTriggerProps & {
  ref?: Ref<ContextMenuSubTriggerElement>;
}) => {
  const { className, children, ...subTriggerProps } = props;
  return (
    <ContextMenuPrimitive.SubTrigger
      {...subTriggerProps}
      asChild={false}
      ref={forwardedRef}
      className={clsx(
        'rt-BaseMenuItem',
        'rt-BaseMenuSubTrigger',
        'rt-ContextMenuItem',
        'rt-ContextMenuSubTrigger',
        className,
      )}
    >
      {children}
      <div className="rt-BaseMenuShortcut rt-ContextMenuShortcut">
        <ThickChevronRightIcon className="rt-BaseMenuSubTriggerIcon rt-ContextMenuSubTriggerIcon" />
      </div>
    </ContextMenuPrimitive.SubTrigger>
  );
};
ContextMenuSubTrigger.displayName = 'ContextMenu.SubTrigger';

type ContextMenuSubContentElement = ComponentRef<typeof ContextMenuPrimitive.SubContent>;
interface ContextMenuSubContentProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.SubContent, RemovedProps> {
  container?: React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Portal>['container'];
}
const ContextMenuSubContent = ({
  ref: forwardedRef,
  ...props
}: ContextMenuSubContentProps & {
  ref?: Ref<ContextMenuSubContentElement>;
}) => {
  const { size, variant, color, highContrast } = React.use(ContextMenuContentContext);
  const { className, children, container, forceMount, ...subContentProps } = extractProps(
    { size, variant, color, highContrast, ...props },
    contextMenuContentPropDefs,
  );
  return (
    <ContextMenuPrimitive.Portal container={container} forceMount={forceMount}>
      <ContextMenuPrimitive.SubContent
        data-accent-color={color || ''}
        alignOffset={-Number(size) * 4}
        // Side offset accounts for the outer solid box-shadow
        sideOffset={1}
        collisionPadding={10}
        {...subContentProps}
        asChild={false}
        ref={forwardedRef}
        className={clsx(
          'rt-PopperContent',
          'rt-BaseMenuContent',
          'rt-BaseMenuSubContent',
          'rt-ContextMenuContent',
          'rt-ContextMenuSubContent',
          className,
        )}
      >
        <ScrollArea type="auto">
          <div className={clsx('rt-BaseMenuViewport', 'rt-ContextMenuViewport')}>{children}</div>
        </ScrollArea>
      </ContextMenuPrimitive.SubContent>
    </ContextMenuPrimitive.Portal>
  );
};
ContextMenuSubContent.displayName = 'ContextMenu.SubContent';

type ContextMenuSeparatorElement = ComponentRef<typeof ContextMenuPrimitive.Separator>;
interface ContextMenuSeparatorProps
  extends ComponentPropsWithout<typeof ContextMenuPrimitive.Separator, RemovedProps> {}
const ContextMenuSeparator = ({
  ref: forwardedRef,
  className,
  ...props
}: ContextMenuSeparatorProps & {
  ref?: Ref<ContextMenuSeparatorElement>;
}) => (
  <ContextMenuPrimitive.Separator
    {...props}
    asChild={false}
    ref={forwardedRef}
    className={clsx('rt-BaseMenuSeparator', 'rt-ContextMenuSeparator', className)}
  />
);
ContextMenuSeparator.displayName = 'ContextMenu.Separator';

export {
  ContextMenuRoot as Root,
  ContextMenuTrigger as Trigger,
  ContextMenuContent as Content,
  ContextMenuLabel as Label,
  ContextMenuItem as Item,
  ContextMenuGroup as Group,
  ContextMenuRadioGroup as RadioGroup,
  ContextMenuRadioItem as RadioItem,
  ContextMenuCheckboxItem as CheckboxItem,
  ContextMenuSub as Sub,
  ContextMenuSubTrigger as SubTrigger,
  ContextMenuSubContent as SubContent,
  ContextMenuSeparator as Separator,
};

export type {
  ContextMenuRootProps as RootProps,
  ContextMenuTriggerProps as TriggerProps,
  ContextMenuContentProps as ContentProps,
  ContextMenuLabelProps as LabelProps,
  ContextMenuItemProps as ItemProps,
  ContextMenuGroupProps as GroupProps,
  ContextMenuRadioGroupProps as RadioGroupProps,
  ContextMenuRadioItemProps as RadioItemProps,
  ContextMenuCheckboxItemProps as CheckboxItemProps,
  ContextMenuSubProps as SubProps,
  ContextMenuSubTriggerProps as SubTriggerProps,
  ContextMenuSubContentProps as SubContentProps,
  ContextMenuSeparatorProps as SeparatorProps,
};
