import * as React from 'react';
import { clsx } from 'clsx';
import { Slot } from '@radix-ui/react-slot';

import { badgePropDefs } from './badge.props.js';
import { extractProps } from '../helpers/extract-props.js';
import { marginPropDefs } from '../props/margin.props.js';

import type { MarginProps } from '../props/margin.props.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

type BadgeElement = ComponentRef<'span'>;
type BadgeOwnProps = GetPropDefTypes<typeof badgePropDefs>;
interface BadgeProps
  extends ComponentPropsWithout<'span', RemovedProps>,
    MarginProps,
    BadgeOwnProps {}
const Badge = ({
  ref: forwardedRef,
  ...props
}: BadgeProps & {
  ref?: Ref<BadgeElement>;
}) => {
  const { asChild, className, color, radius, ...badgeProps } = extractProps(
    props,
    badgePropDefs,
    marginPropDefs,
  );
  const Comp = asChild ? Slot : 'span';
  return (
    <Comp
      data-accent-color={color}
      data-radius={radius}
      {...badgeProps}
      ref={forwardedRef}
      className={clsx('rt-reset', 'rt-Badge', className)}
    />
  );
};
Badge.displayName = 'Badge';

export { Badge };
export type { BadgeProps };
