import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren, Ref } from 'react';
import type { LayoutProps } from '../props/layout.props';
import type { MarginProps } from '../props/margin.props';
import { Box, type BoxProps } from './box';

type LabelOwnProps = {
  htmlFor: string;
};

type LabelProps = PropsWithChildren<LayoutProps & MarginProps> &
  Pick<BoxProps, 'className' | 'display'> &
  LabelOwnProps & {
    ref?: Ref<HTMLLabelElement>;
  };

export const Label: FunctionComponent<LabelProps> = ({
  className,
  htmlFor,
  children,
  ...props
}) => (
  // @ts-expect-error -- ref type is correct
  <Box {...props} className={clsx('rt-Label', className)} asChild>
    <label htmlFor={htmlFor}>{children}</label>
  </Box>
);
