import { captureException } from '@sentry/react';
import type { FunctionComponent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from './spinner/spinner.tsx';

type RemoteMixerComponentProps = {
  url: string;
};

export const RemoteMixerComponent: FunctionComponent<RemoteMixerComponentProps> = ({ url }) => {
  const [Component, setComponent] = useState<FunctionComponent | null>(null);
  const [loading, setLoading] = useState(true);
  // This Ref helps us make sure we render the right component if the url prop changes, and it helps us
  // avoid calling setComponent if the component is unmounted.
  const currentUrl = useRef<string | null>(url);
  useEffect(() => {
    return () => {
      currentUrl.current = null;
    };
  }, []);
  useEffect(() => {
    currentUrl.current = url;
    setLoading(true);
    void (async () => {
      try {
        const { default: component } = (await import(/* @vite-ignore */ url)) as {
          default: unknown;
        };
        if (typeof component === 'function' && currentUrl.current === url) {
          // We must use the function form of this setter because component is a function.
          setComponent(() => component as FunctionComponent);
        }
      } catch (error) {
        captureException(error);
      }
      if (currentUrl.current === url) {
        setLoading(false);
      }
    })();
  }, [url]);

  if (loading) {
    // TODO: Use Suspense instead.
    return <Spinner p="3" />;
  }
  if (!Component) {
    return null;
  }
  return <Component />;
};
