import type { PublicSitePageQuery } from '@wirechunk/lib/shared-queries/public-site-page-query.generated.ts';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { ParseAndRenderPage } from '../ParseAndRenderPage.tsx';

type PageWithMetaProps = {
  page: NonNullable<PublicSitePageQuery['publicSite']['page']>;
};

export const PageWithMeta: FunctionComponent<PageWithMetaProps> = ({ page }) => {
  return (
    <Fragment>
      <title>{page.metaTitle || page.title}</title>
      {page.metaDescription && <meta name="description" content={page.metaDescription} />}
      {page.metaRobots.length ? <meta name="robots" content={page.metaRobots.join(', ')} /> : null}
      <ParseAndRenderPage componentsJSON={page.components} bodyStylesJSON={page.bodyStyles} />
    </Fragment>
  );
};
