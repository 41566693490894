import { ThemeContext } from '@wirechunk/ui';
import type { FunctionComponent, PropsWithChildren } from 'react';

const { grayColor } = window.wirechunk;

const contextValue: ThemeContext = {
  appearance: 'light',
  grayColor,
  panelBackground: 'solid',
  radius: 'medium',
  scaling: '100%',
};

export const ThemeProvider: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <ThemeContext value={contextValue}>{children}</ThemeContext>
);
