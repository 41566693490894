import { ApolloProvider } from '@wirechunk/apollo-client';
import { DirectionProvider, TooltipPrimitiveProvider } from '@wirechunk/ui';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { lazy } from 'react';
import { RouterProvider } from 'react-router/dom';
import { apolloClient } from './apollo-client.ts';
import { AnalyticsContextProvider } from './components/analytics-context-provider.tsx';
import { Passthrough } from './components/passthrough.tsx';
import { ErrorBoundary } from './components/RootErrorBoundary/error-boundary.tsx';
import { SessionUserProvider } from './components/SessionUserProvider.tsx';
import { ThemeProvider } from './components/theme-provider.tsx';
import { ErrorCollectorContextProvider } from './contexts/error-collector-context.tsx';
import { SessionStatusContextProvider } from './contexts/SessionStatusContext/session-status-context.tsx';
import { ToastProvider } from './contexts/ToastContext.tsx';
import { router } from './router.tsx';

import 'primeicons/primeicons.css';
import './css/theme.css';
import './css/index.css';

const StripeElementsProvider: FunctionComponent<PropsWithChildren> = window.wirechunk.stripePK
  ? lazy(() => import('./components/StripeElementsProvider.tsx'))
  : Passthrough;

export const App: FunctionComponent = () => (
  <ErrorBoundary showDialog>
    <TooltipPrimitiveProvider delayDuration={200}>
      <DirectionProvider dir="ltr">
        <ThemeProvider>
          <ToastProvider>
            <ApolloProvider client={apolloClient}>
              <SessionStatusContextProvider>
                <ErrorCollectorContextProvider>
                  <StripeElementsProvider>
                    <SessionUserProvider>
                      <AnalyticsContextProvider>
                        <RouterProvider router={router} />
                      </AnalyticsContextProvider>
                    </SessionUserProvider>
                  </StripeElementsProvider>
                </ErrorCollectorContextProvider>
              </SessionStatusContextProvider>
            </ApolloProvider>
          </ToastProvider>
        </ThemeProvider>
      </DirectionProvider>
    </TooltipPrimitiveProvider>
  </ErrorBoundary>
);
