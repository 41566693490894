import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren, Ref } from 'react';
import type { LayoutProps } from '../props/layout.props';
import type { MarginProps } from '../props/margin.props';
import { Box, type BoxProps } from './box';

type FormFieldProps = PropsWithChildren<
  LayoutProps & MarginProps & Pick<BoxProps, 'className' | 'display' | 'asChild'>
> & {
  ref?: Ref<HTMLDivElement>;
};

export const FormField: FunctionComponent<FormFieldProps> = ({ children, className, ...props }) => (
  <Box mb="3" {...props} className={clsx('rt-FormField', className)}>
    {children}
  </Box>
);
