import type { FunctionComponent, SVGProps } from 'react';

type SvgStarFilledProps = SVGProps<SVGSVGElement>;

export const SvgStarFilled: FunctionComponent<SvgStarFilledProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    width="20"
    height="20"
    fill="currentColor"
    {...props}
  >
    <path d="m243-144 63-266L96-589l276-24 108-251 108 252 276 23-210 179 63 266-237-141-237 141Z" />
  </svg>
);
