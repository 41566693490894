import * as React from 'react';
import { clsx } from 'clsx';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { radioCardsRootPropDefs } from './radio-cards.props.js';
import { Grid } from './grid.js';
import { extractProps } from '../helpers/extract-props.js';
import { marginPropDefs } from '../props/margin.props.js';

import type { MarginProps } from '../props/margin.props.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

type RadioCardsRootElement = ComponentRef<typeof RadioGroupPrimitive.Root>;
type RadioCardsRootOwnProps = GetPropDefTypes<typeof radioCardsRootPropDefs>;
interface RadioCardsRootProps
  extends ComponentPropsWithout<
      typeof RadioGroupPrimitive.Root,
      'asChild' | 'color' | 'defaultChecked'
    >,
    MarginProps,
    RadioCardsRootOwnProps {}
const RadioCardsRoot = ({
  ref: forwardedRef,
  ...props
}: RadioCardsRootProps & {
  ref?: Ref<RadioCardsRootElement>;
}) => {
  const { className, color, ...rootProps } = extractProps(
    props,
    radioCardsRootPropDefs,
    marginPropDefs,
  );
  return (
    <Grid asChild>
      <RadioGroupPrimitive.Root
        data-accent-color={color}
        {...rootProps}
        ref={forwardedRef}
        className={clsx('rt-RadioCardsRoot', className)}
      />
    </Grid>
  );
};
RadioCardsRoot.displayName = 'RadioCards.Root';

type RadioCardsItemElement = ComponentRef<typeof RadioGroupPrimitive.Item>;
interface RadioCardsItemProps
  extends ComponentPropsWithout<typeof RadioGroupPrimitive.Item, RemovedProps>,
    MarginProps {}
const RadioCardsItem = ({
  ref: forwardedRef,
  className,
  ...props
}: RadioCardsItemProps & {
  ref?: Ref<RadioCardsItemElement>;
}) => (
  <RadioGroupPrimitive.Item
    {...props}
    asChild={false}
    ref={forwardedRef}
    className={clsx('rt-reset', 'rt-BaseCard', 'rt-RadioCardsItem', className)}
  />
);
RadioCardsItem.displayName = 'RadioCards.Item';

export { RadioCardsRoot as Root, RadioCardsItem as Item };
export type { RadioCardsRootProps as RootProps, RadioCardsItemProps as ItemProps };
