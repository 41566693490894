import type {
  Me_User_Fragment,
  MeFragment,
} from '@wirechunk/lib/shared-queries/me-fragment.generated.ts';
import { Text } from '@wirechunk/ui';
import type { ComponentType, FunctionComponent, PropsWithChildren } from 'react';
import { createContext, use, useMemo } from 'react';

export type CurrentUser = MeFragment;

type CurrentUserContext = {
  user: CurrentUser | null;
  loadingUser: boolean;
};

type RequiredCurrentUserContext = {
  user: CurrentUser;
  loadingUser: boolean;
};

const isRequiredCurrentUserContext = (
  context: CurrentUserContext,
): context is RequiredCurrentUserContext => context.user !== null;

export const CurrentUserContext = createContext<CurrentUserContext>({
  user: null,
  loadingUser: false,
});

type CurrentUserProviderProps = PropsWithChildren<CurrentUserContext>;

export const CurrentUserProvider: FunctionComponent<CurrentUserProviderProps> = ({
  user,
  loadingUser,
  children,
}) => {
  const currentUserContext = useMemo<CurrentUserContext>(
    (): CurrentUserContext => ({
      user,
      loadingUser,
    }),
    [user, loadingUser],
  );

  return <CurrentUserContext value={currentUserContext}>{children}</CurrentUserContext>;
};

export const useCurrentUser = (): RequiredCurrentUserContext => {
  const currentUserContext = use(CurrentUserContext);

  if (isRequiredCurrentUserContext(currentUserContext)) {
    return currentUserContext;
  }

  throw new Error('There is no user authenticated.');
};

export type RegularCurrentUser = {
  user: Me_User_Fragment;
};

// Guards a component to check that a regular (non-admin) user is signed in. Displays a message if a user is not signed in.
export const withRegularCurrentUser =
  <P,>(Component: ComponentType<P & RegularCurrentUser>): FunctionComponent<P> =>
  (props: P) => {
    const currentUserContext = use(CurrentUserContext);
    if (!currentUserContext.user) {
      if (currentUserContext.loadingUser) {
        return null;
      }
      return <Text>It appears you are not signed in.</Text>;
    }
    if (currentUserContext.user.__typename === 'User') {
      return <Component {...props} user={currentUserContext.user} />;
    }
    return null;
  };
