import * as React from 'react';
import { clsx } from 'clsx';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { Text } from './text.js';
import { extractProps } from '../helpers/extract-props.js';
import { tooltipPropDefs } from './tooltip.props.js';

import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

type TooltipElement = ComponentRef<typeof TooltipPrimitive.Content>;
type TooltipOwnProps = GetPropDefTypes<typeof tooltipPropDefs>;
interface TooltipProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>,
    ComponentPropsWithout<typeof TooltipPrimitive.Content, RemovedProps | 'content'>,
    TooltipOwnProps {
  content: React.ReactNode;
  container?: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Portal>['container'];
}
const Tooltip = ({
  ref: forwardedRef,
  ...props
}: TooltipProps & {
  ref?: Ref<TooltipElement>;
}) => {
  const {
    children,
    className,
    open,
    defaultOpen,
    onOpenChange,
    delayDuration,
    disableHoverableContent,
    content,
    container,
    forceMount,
    ...tooltipContentProps
  } = extractProps(props, tooltipPropDefs);
  const rootProps = { open, defaultOpen, onOpenChange, delayDuration, disableHoverableContent };
  return (
    <TooltipPrimitive.Root {...rootProps}>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal container={container} forceMount={forceMount}>
        <TooltipPrimitive.Content
          sideOffset={4}
          collisionPadding={10}
          {...tooltipContentProps}
          asChild={false}
          ref={forwardedRef}
          className={clsx('rt-TooltipContent', className)}
        >
          <Text as="p" className="rt-TooltipText" size="1">
            {content}
          </Text>
          <TooltipPrimitive.Arrow className="rt-TooltipArrow" />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};
Tooltip.displayName = 'Tooltip';

export { Tooltip };
export type { TooltipProps };
