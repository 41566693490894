import { createContext, use } from 'react';

export type RadioGroupInputContext = {
  displayType: 'indicators' | 'cards';
};

const context = createContext<RadioGroupInputContext | null>(null);

export const RadioGroupInputContextProvider = context.Provider;

export const useRadioGroupInputContext = (): RadioGroupInputContext | null => use(context);
