import { collectAllCss } from '@wirechunk/lib/mixer/styles.ts';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { Fragment, memo, useMemo } from 'react';
import { RenderMixerChildren } from './RenderMixerChildren.tsx';

type RenderComponentsStyledProps = {
  components: Component[];
};

export const RenderComponentsStyled: FunctionComponent<RenderComponentsStyledProps> = memo(
  ({ components }) => {
    const css = useMemo(() => components.reduce(collectAllCss, ''), [components]);

    return (
      <Fragment>
        <style>{css}</style>
        <RenderMixerChildren>{components}</RenderMixerChildren>
      </Fragment>
    );
  },
);
