import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import styles from './dashboard-layout.module.css';
import type { SidebarInner } from './Sidebar/Sidebar.ts';
import { Sidebar } from './Sidebar/Sidebar.tsx';
import { Toolbar } from './toolbar/toolbar.tsx';

type DashboardLayoutProps = PropsWithChildren<{
  sidebarBody: SidebarInner;
  sidebarFooter?: SidebarInner | null;
  ToolbarLeftElements?: FunctionComponent;
  narrow?: boolean;
}>;

export const DashboardLayout: FunctionComponent<DashboardLayoutProps> = ({
  sidebarBody,
  sidebarFooter,
  ToolbarLeftElements,
  narrow,
  children,
}) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const handleShowSidebar = useCallback(() => {
    setShowSidebar(true);
  }, []);

  return (
    <div className={clsx(styles.dashboard, narrow && styles.narrow)}>
      <Toolbar
        className={styles.toolbar}
        onShowSidebar={handleShowSidebar}
        LeftElements={ToolbarLeftElements}
      />
      <Sidebar
        className={styles.sidebar}
        body={sidebarBody}
        footer={sidebarFooter}
        show={showSidebar}
        onHide={() => {
          setShowSidebar(false);
        }}
      />
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};
