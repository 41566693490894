import * as React from 'react';
import { clsx } from 'clsx';
import { Slot } from '@radix-ui/react-slot';

import { headingPropDefs } from './heading.props.js';
import { extractProps } from '../helpers/extract-props.js';
import { marginPropDefs } from '../props/margin.props.js';

import type { MarginProps } from '../props/margin.props.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

type HeadingElement = ComponentRef<'h1'>;
type HeadingOwnProps = GetPropDefTypes<typeof headingPropDefs>;
interface HeadingProps
  extends ComponentPropsWithout<'h1', RemovedProps>,
    MarginProps,
    HeadingOwnProps {}

const Heading = ({
  ref: forwardedRef,
  ...props
}: HeadingProps & {
  ref?: Ref<HeadingElement>;
}) => {
  const {
    children,
    className,
    asChild,
    as: Tag = 'h1',
    color,
    ...headingProps
  } = extractProps(props, headingPropDefs, marginPropDefs);
  return (
    <Slot
      data-accent-color={color}
      {...headingProps}
      ref={forwardedRef}
      className={clsx('rt-Heading', className)}
    >
      {asChild ? children : <Tag>{children}</Tag>}
    </Slot>
  );
};
Heading.displayName = 'Heading';

export { Heading };
export type { HeadingProps };
