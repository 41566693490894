import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '#api';

export type MeZendeskChatTokenQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeZendeskChatTokenQuery = {
  me2?:
    | { __typename: 'AdminUser' }
    | { __typename: 'User'; id: string; zendeskChatToken?: string | null }
    | null;
};

export const MeZendeskChatTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MeZendeskChatToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zendeskChatToken' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeZendeskChatTokenQuery, MeZendeskChatTokenQueryVariables>;
