import { createContext, use } from 'react';

export type LessonUserContext = {
  notes: string;
  setNotes: (notes: string) => void;
};

const context = createContext<LessonUserContext | null>(null);

export const LessonUserContextProvider = context.Provider;

export const useLessonUserContext = (): LessonUserContext | null => use(context);
