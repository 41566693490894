import { SvgProgressActivity } from '@wirechunk/material-symbols-react-400/20/outlined/progress-activity.tsx';
import { Flex } from '@wirechunk/ui';
import type { FlexProps } from '@wirechunk/ui';
import type { FunctionComponent } from 'react';
import styles from './spinner.module.css';

export const Spinner: FunctionComponent<FlexProps> = (props) => (
  <Flex justify="center" align="center" {...props}>
    <SvgProgressActivity className={styles.spin} />
  </Flex>
);
