import { useQuery } from '@wirechunk/apollo-client';
import { isPlainObject, sumBy } from 'lodash-es';
import { useMemo } from 'react';
import type { Question, StepHeading } from '../../components/mixer/ScoreMyCall/Questions.ts';
import { tryParseObject } from '../../util/json.ts';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type { ScoreMyCallEntriesQuery } from './queries.generated.ts';
import { ScoreMyCallEntriesDocument } from './queries.generated.ts';

const isQuestion = (q: Question | StepHeading): q is Question => 'name' in q;

export type ScoreMyCallEntryData = {
  questions: Array<Question | StepHeading>;
  values: Record<string, boolean>;
};

const isValidFormData = (formData: Record<string, unknown>): formData is ScoreMyCallEntryData =>
  'questions' in formData &&
  Array.isArray(formData.questions) &&
  'values' in formData &&
  isPlainObject(formData.values);

export type Entry = ScoreMyCallEntriesQuery['scoreMyCallEntries'][number] & {
  createdAtDate: Date;
  parsedFormData: ScoreMyCallEntryData;
  questionsTotal: number;
  questionsPassed: number;
};

type ScoreMyCallEntries = {
  entries: Entry[];
  isLoading: boolean;
};

export const useScoreMyCallEntries = ({
  platformId,
  shareRecording = null,
  onError,
}: {
  platformId: string;
  shareRecording?: boolean | null;
  onError: ErrorHandler['onError'];
}): ScoreMyCallEntries => {
  const { data, loading } = useQuery(ScoreMyCallEntriesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { platformId, shareRecording },
    onError,
  });

  return useMemo<ScoreMyCallEntries>(() => {
    if (data) {
      return {
        entries: data.scoreMyCallEntries.map<Entry>((entry) => {
          const parsedFormData = tryParseObject(entry.formData);
          const createdAtDate = new Date(entry.createdAt);
          if (isValidFormData(parsedFormData)) {
            const nonHeadingQuestions = parsedFormData.questions.filter<Question>(isQuestion);
            return {
              ...entry,
              createdAtDate,
              parsedFormData,
              questionsTotal: nonHeadingQuestions.length,
              questionsPassed: sumBy(nonHeadingQuestions, ({ name }) =>
                parsedFormData.values[name] ? 1 : 0,
              ),
            };
          }
          return {
            ...entry,
            createdAtDate,
            parsedFormData: { questions: [], values: {} },
            questionsTotal: 0,
            questionsPassed: 0,
          };
        }),
        isLoading: loading,
      };
    }

    return {
      entries: [],
      isLoading: loading,
    };
  }, [data, loading]);
};
