import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { ImageComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import type { FunctionComponent } from 'react';
import { use } from 'react';
import { PropsContext } from '../../contexts/props-context.ts';
import { SiteContext } from '../../contexts/SiteContext/SiteContext.tsx';

export const Image: FunctionComponent<ImageComponent> = (props) => {
  let src: string | null | undefined;
  switch (props.src?.type) {
    case DataSource.Direct:
      src = props.src.url;
      break;
    case DataSource.Prop:
      if (props.src.name) {
        const propsContext = use(PropsContext);
        src = stringOrDefaultNull(propsContext[props.src.name]);
      }
      break;
    case 'SiteLogoUrl': {
      const { logoUrl } = use(SiteContext);
      src = logoUrl;
      break;
    }
    default:
  }

  if (!src) {
    return null;
  }

  return <img src={src} alt={props.alt || ''} className={componentClassName(props)} />;
};
