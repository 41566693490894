import type { FileTypeCategory } from '@wirechunk/lib/mixer/types/components.ts';
import { SvgClose } from '@wirechunk/material-symbols-react-400/20/outlined/close.tsx';
import { SvgDeleteForever } from '@wirechunk/material-symbols-react-400/20/outlined/delete-forever.tsx';
import { SvgAdd } from '@wirechunk/material-symbols-react-400/24/outlined/add.tsx';
import { SvgUpload } from '@wirechunk/material-symbols-react-400/24/outlined/upload.tsx';
import { Button } from '@wirechunk/ui';
import { clsx } from 'clsx';
import type { ChangeEvent, DragEventHandler, FunctionComponent } from 'react';
import { Fragment, useRef, useState } from 'react';
import type { ErrorHandler } from '../../hooks/useErrorHandler.tsx';
import type { UploadFile } from '../../hooks/useUploadFile/useUploadFile.ts';
import { inputAcceptProp } from '../../util/inputs.ts';
import type { UploadFilePurpose } from '#api';

type FileItemProps = {
  file: File;
  uploading: boolean;
  onRemove: () => void;
};

const FileItem: FunctionComponent<FileItemProps> = ({ file, uploading, onRemove }) => {
  return (
    <div className="border border-round p-2">
      <div className="flex align-items-center justify-content-between mb-2 md:mb-0">
        <span className="font-medium">
          {file.name}
          {uploading && <span className="font-medium font-italic ml-2">Uploading&hellip;</span>}
        </span>
        <Button
          variant="soft"
          color="red"
          disabled={uploading}
          onClick={(evt) => {
            evt.stopPropagation();
            onRemove();
          }}
        >
          <SvgDeleteForever /> {uploading ? 'Cancel' : 'Remove'}
        </Button>
      </div>
    </div>
  );
};

type FileUploadProps = {
  className?: string;
  purpose: UploadFilePurpose;
  types?: FileTypeCategory[];
  showClose: boolean;
  ErrorMessage: ErrorHandler['ErrorMessage'];
  // The UploadFile object is taken as a prop so that the parent component can mount the useUploadFile hook and keep
  // it around while removing this FileUpload component. This is useful to allow the parent to keep polling for an
  // uploaded file's status after this component is removed.
  uploadFile: UploadFile;
  // onClose is called if the user clicks the close button.
  onClose?: () => void;
  // onUploaded is called after the file is uploaded, at which point the File's status may still be Uploading.
  onUploaded?: (() => void) | null;
  // onDonePollingUploaded is called after the file is uploaded and the polling loop is done, which could
  // be because the file was uploaded successfully or that the upload timed out or was canceled.
  onDonePollingUploaded?: () => void | Promise<void>;
};

export const FileUpload: FunctionComponent<FileUploadProps> = ({
  className,
  purpose,
  types,
  showClose,
  ErrorMessage,
  uploadFile: { upload, uploading, cancel },
  onClose,
  onUploaded,
  onDonePollingUploaded,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  // TODO: Styling for dragover event.
  // const dragOverHandler = (e: DragEvent) => {
  //   e.preventDefault();
  // };

  const onFilesSelected = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] ?? null);
  };

  const onFileDrop: DragEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const firstFile = e.dataTransfer.files[0];
    setFile(firstFile ?? null);
  };

  const onUpload = () => {
    if (!file) {
      return;
    }
    upload(
      file,
      purpose,
      () => {
        const { current: input } = fileInput;
        if (input) {
          input.value = '';
        }
        setFile(null);
        onUploaded?.();
      },
      onDonePollingUploaded,
    );
  };

  return (
    <Fragment>
      <ErrorMessage />
      <div className={clsx(className, 'border-1 border-round')}>
        <div className="flex flex-column md:flex-row gap-3 surface-ground p-3 border-bottom">
          <div className="flex-grow-1">
            <Button
              mr="3"
              disabled={!!file}
              onClick={() => {
                fileInput.current?.click();
              }}
            >
              <SvgAdd /> Choose a file
            </Button>
            <Button disabled={!file || uploading} onClick={onUpload}>
              <SvgUpload /> Upload
            </Button>
          </div>
          {showClose && (
            <Button variant="soft" color="red" onClick={onClose}>
              <SvgClose /> Cancel
            </Button>
          )}
        </div>
        {file ? (
          <div className="flex flex-column p-3 gap-3">
            <FileItem
              file={file}
              uploading={uploading}
              onRemove={() => {
                setFile(null);
                if (fileInput.current) {
                  fileInput.current.value = '';
                }
                cancel();
              }}
            />
          </div>
        ) : (
          <div
            className="flex align-items-center flex-column py-5 cursor-pointer"
            onDragOver={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
            }}
            onDrop={onFileDrop}
            onClick={() => {
              fileInput.current?.click();
            }}
          >
            <i
              className="pi pi-file p-4 border-circle"
              style={{
                fontSize: '3em',
                backgroundColor: 'var(--surface-ground)',
                color: 'var(--surface-400)',
              }}
            />
            <span className="mt-5 uppercase text-color-muted">Drop a file here</span>
          </div>
        )}
      </div>
      <input
        ref={fileInput}
        type="file"
        className="hidden"
        accept={types?.length ? inputAcceptProp(types) : undefined}
        disabled={uploading}
        onChange={onFilesSelected}
      />
    </Fragment>
  );
};
