import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { LessonNotesFieldComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { TextArea } from '@wirechunk/ui';
import type { FunctionComponent } from 'react';
import { useId } from 'react';
import { FormField } from '../../form-field/form-field.tsx';
import { MixerLabel } from '../../mixer-common/mixer-label.tsx';
import { useLessonUserContext } from '../course/lesson-user-context.ts';

export const LessonNotesField: FunctionComponent<LessonNotesFieldComponent> = (props) => {
  const lessonUserContext = useLessonUserContext();
  const inputId = useId();

  if (!lessonUserContext) {
    return null;
  }

  return (
    <FormField className={componentClassName(props)}>
      <MixerLabel label={props.label} inputId={inputId} fallbackLabel="Notes" />
      <TextArea
        id={inputId}
        className="w-full"
        value={lessonUserContext.notes}
        onChange={(e) => {
          lessonUserContext.setNotes(e.target.value);
        }}
      />
    </FormField>
  );
};
