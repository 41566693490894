import { clsx } from 'clsx';

import { BaseButton } from './_internal/base-button.js';
import type { ComponentPropsWithoutRef, ComponentRef, Ref } from 'react';

type IconButtonElement = ComponentRef<typeof BaseButton>;
interface IconButtonProps extends ComponentPropsWithoutRef<typeof BaseButton> {}
const IconButton = ({
  ref: forwardedRef,
  className,
  ...props
}: IconButtonProps & {
  ref?: Ref<IconButtonElement>;
}) => <BaseButton {...props} ref={forwardedRef} className={clsx('rt-IconButton', className)} />;
IconButton.displayName = 'IconButton';

export { IconButton };
export type { IconButtonProps };
