import * as React from 'react';
import { clsx } from 'clsx';
import { Slot } from '@radix-ui/react-slot';

import { Text } from './text.js';

import type { blockquotePropDefs } from './blockquote.props.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { MarginProps } from '../props/margin.props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

type BlockquoteElement = ComponentRef<'blockquote'>;
type BlockQuoteOwnProps = GetPropDefTypes<typeof blockquotePropDefs>;
interface BlockquoteProps
  extends ComponentPropsWithout<'blockquote', RemovedProps>,
    MarginProps,
    BlockQuoteOwnProps {}
const Blockquote = ({
  ref: forwardedRef,
  ...props
}: BlockquoteProps & {
  ref?: Ref<BlockquoteElement>;
}) => {
  const { asChild, children, className, ...blockquoteProps } = props;
  const Comp = asChild ? Slot : 'blockquote';
  return (
    <Text
      asChild
      {...blockquoteProps}
      ref={forwardedRef}
      className={clsx('rt-Blockquote', className)}
    >
      <Comp>{children}</Comp>
    </Text>
  );
};
Blockquote.displayName = 'Blockquote';

export { Blockquote };
export type { BlockquoteProps };
