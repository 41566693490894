import { useLazyQuery } from '@wirechunk/apollo-client';
import { useCallback, useState } from 'react';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import { useInterval } from '../useInterval.ts';
import { FileStatusDocument } from './queries.generated.ts';
import { FileStatus } from '#api';

type PollFileStatus = {
  // startPollingFile starts polling for the file's status. If there is already a polling loop running,
  // calling this function with the same file ID will not do anything, but calling the function with a
  // different file ID will stop the existing polling loop and start a new one.
  startPollingFile: (id: string, onDone?: (status: FileStatus) => void | Promise<void>) => void;
  stopPollingFile: () => void;
  polling: boolean;
};

export const usePollFileStatus = (onError: ErrorHandler['onError']): PollFileStatus => {
  const [getFile] = useLazyQuery(FileStatusDocument, {
    onError,
    fetchPolicy: 'no-cache',
  });

  type Poll = {
    fn: () => Promise<void>;
    fileId: string;
  };
  const [poll, setPoll] = useState<Poll | null>(null);
  useInterval(poll?.fn || null, 400);

  const startPollingFile = useCallback<PollFileStatus['startPollingFile']>(
    (id, onDone) => {
      setPoll((poll) => {
        if (poll && poll.fileId === id) {
          return poll;
        }
        return {
          fn: async () => {
            await getFile({
              variables: { id },
              onCompleted: (data) => {
                if (data.file.status !== FileStatus.Uploading) {
                  setPoll(null);
                  void onDone?.(data.file.status);
                }
              },
            });
          },
          fileId: id,
        };
      });
    },
    [getFile],
  );
  const stopPollingFile = useCallback(() => {
    setPoll(null);
  }, []);

  return {
    startPollingFile,
    stopPollingFile,
    polling: !!poll,
  };
};
