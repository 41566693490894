import { use, useCallback, useEffect, useState } from 'react';
import { CurrentUserContext } from '../contexts/current-user-context.tsx';
import { InputDataContext } from '../contexts/InputDataContext.tsx';
import { usePropsContext } from '../contexts/props-context.ts';
import type { MixerContextData } from '../util/mixer/context-data.ts';
import { buildContextData } from '../util/mixer/context-data.ts';
import { useInterval } from './useInterval.ts';

export const useMixerContextData = (): MixerContextData => {
  const { user } = use(CurrentUserContext);
  const { data: inputData } = use(InputDataContext);
  const propsContext = usePropsContext();
  const [contextData, setContextData] = useState<MixerContextData>(() =>
    buildContextData({
      user,
      inputData: inputData.visible,
      props: propsContext,
    }),
  );
  const refreshContextData = useCallback(() => {
    setContextData(
      buildContextData({
        user,
        inputData: inputData.visible,
        props: propsContext,
      }),
    );
  }, [user, inputData.visible, propsContext]);
  useInterval(refreshContextData, 30_000);
  useEffect(() => {
    setContextData(
      buildContextData({
        user,
        inputData: inputData.visible,
        props: propsContext,
      }),
    );
  }, [user, inputData.visible, propsContext]);

  return contextData;
};
