import type { PublicSiteContextFragment } from '@wirechunk/lib/shared-queries/public-site-context-fragment.generated.ts';
import { createContext, use } from 'react';

export type SiteContext = Omit<PublicSiteContextFragment, '__typename'>;

// @ts-expect-error -- We always provide a SiteContext at the root of the app.
export const SiteContext = createContext<SiteContext>(null);

export const SiteContextProvider = SiteContext;

export const useSiteContext = (): SiteContext => use(SiteContext);
