import type { FunctionComponent } from 'react';
import { useSiteContext } from '../contexts/SiteContext/SiteContext.tsx';
import { toolbarLogoStyle } from './DashboardLayout/toolbar/toolbar.tsx';

// Display the logo from SiteContext with the standard toolbar style.
// Falls back to the site name if no logo URL is set.
export const SiteLogoStandardStyle: FunctionComponent = () => {
  const { name, logoUrl } = useSiteContext();

  return logoUrl ? (
    <img src={logoUrl} alt={name} style={toolbarLogoStyle} />
  ) : (
    <div className="font-medium text-lg">{name}</div>
  );
};
