import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { SiteLogoComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.tsx';

export const SiteLogo: FunctionComponent<SiteLogoComponent> = (props) => {
  const { name, logoUrl } = useSiteContext();

  return logoUrl ? (
    <img className={componentClassName(props)} src={logoUrl} alt={props.alt || name} />
  ) : (
    props.fallbackSiteName && <div className={componentClassName(props)}>{name}</div>
  );
};
