import * as React from 'react';
import { clsx } from 'clsx';

import { BaseButton } from './_internal/base-button.js';
import type { ComponentRef, Ref } from 'react';

type ButtonElement = ComponentRef<typeof BaseButton>;
interface ButtonProps extends React.ComponentPropsWithoutRef<typeof BaseButton> {}
const Button = ({
  ref: forwardedRef,
  className,
  ...props
}: ButtonProps & {
  ref?: Ref<ButtonElement>;
}) => <BaseButton {...props} ref={forwardedRef} className={clsx('rt-Button', className)} />;
Button.displayName = 'Button';

export { Button };
export type { ButtonProps };
