import type { Component, Components, ComponentType } from './components.ts';

export const componentTypesWithChildren = [
  'ActiveOrgSiteGuard',
  'ActiveOrgSitePageGuard',
  'AgreementsGuard',
  'Box',
  'CollapsiblePanel',
  'ConditionalLogic',
  'Dashboard',
  'DataInputTable',
  'FormatProps',
  'Link',
  'Padding',
  'PhoneLink',
  'Popover',
  'ProvideProps',
  'RadioGroupInput',
  'RadioGroupInputButton',
  'SignInGuard',
  'Stage',
  'SubscriptionTagGuard',
  'VerifiedEmailAddressGuard',
  'WidthContainer',
] as const satisfies ComponentType[];

const componentTypesWithChildrenOrLabel = [
  'RadioGroupInputButton',
] as const satisfies ComponentType[];

const componentTypesWithDefaultBooleanValue = [
  'BooleanInput',
  'CompletableVideoInput',
] as const satisfies ComponentType[];

const componentTypesWithDefaultStringValue = [
  'TextareaInput',
  'TextInput',
  'RadioGroupInput',
] as const satisfies ComponentType[];

const componentTypesWithElementId = ['Box', 'Padding'] as const satisfies ComponentType[];

const componentTypesWithLabel = [
  'BooleanInput',
  'Button',
  'CompletableVideoInput',
  'DataInputTable',
  'DateInput',
  'DropdownInput',
  'LessonNotesField',
  'MultipleChoiceInput',
  'MultiSelectInput',
  'NumberInput',
  'TextInput',
  'TextareaInput',
  'ViewPlanStagesButton',
] as const satisfies ComponentType[];

const componentTypesWithName = [
  'BooleanInput',
  'Button',
  'CompletableVideoInput',
  'DataInputTable',
  'DateInput',
  'DropdownInput',
  'MultipleChoiceInput',
  'MultiSelectInput',
  'NumberInput',
  'RadioGroupInput',
  'StageFileInput',
  'TextareaInput',
  'TextInput',
] as const satisfies ComponentType[];

const componentTypesWithMaxLength = [
  'TextInput',
  'TextareaInput',
] as const satisfies ComponentType[];

const componentTypesWithRequired = [
  'BooleanInput',
  'CompletableVideoInput',
  'DateInput',
  'DropdownInput',
  'NumberInput',
  'RadioGroupInput',
  'TextInput',
  'TextareaInput',
] as const satisfies ComponentType[];

const componentTypesWithSlot = [
  'ConditionalLogic',
  'SubscriptionTagGuard',
] as const satisfies ComponentType[];

const componentTypesWithStringValue = [
  'BooleanInput',
  'RadioGroupInputButton',
] as const satisfies ComponentType[];

const componentTypesWithTag = ['Box'] as const satisfies ComponentType[];

const componentTypesWithoutWrapper = [
  'ConditionalLogic',
  'CurrentYear',
  'Dashboard',
  'Design',
  'FormatProps',
  'FormConfirmationMessage',
  'FormStep',
  'Page',
  'PlainText',
  'ProvideProps',
  'RemoteComponent',
  'SignInGuard',
  'SitePrivacyPolicy',
  'SiteTermsOfUse',
  'Text',
] as const satisfies ComponentType[];

const componentTypesWithCompletableState = [
  'CompletableVideoInput',
  'MultipleChoiceInput',
  'MultiSelectInput',
] as const satisfies ComponentType[];

export const componentTypesWithConditionalStyles = [
  'RadioGroupInputButton',
] as const satisfies ComponentType[];

export type ComponentTypeWithChildren = (typeof componentTypesWithChildren)[number];

export type ComponentWithChildren = Components[ComponentTypeWithChildren];

export const isComponentWithChildren = (component: Component): component is ComponentWithChildren =>
  componentTypesWithChildren.includes(component.type as never);

export type ComponentTypeWithChildrenOrLabel = (typeof componentTypesWithChildrenOrLabel)[number];

export type ComponentWithChildrenOrLabel = Components[ComponentTypeWithChildrenOrLabel];

export const isComponentWithChildrenOrLabel = (
  component: Component,
): component is ComponentWithChildrenOrLabel =>
  componentTypesWithChildrenOrLabel.includes(component.type as never);

export const componentChildrenEnabled = (
  component: ComponentWithChildren | ComponentWithChildrenOrLabel,
): boolean => {
  switch (component.type) {
    case 'Stage':
      return !!component.enableWrappedStageBody;
    default:
      if (isComponentWithChildrenOrLabel(component)) {
        return component.childrenOrLabel === 'children';
      }
      return true;
  }
};

export type ComponentTypeWithConditionalStyles =
  (typeof componentTypesWithConditionalStyles)[number];

export type ComponentWithConditionalStyles = Components[ComponentTypeWithConditionalStyles];

export const isComponentWithConditionalStyles = (
  component: Component,
): component is ComponentWithConditionalStyles =>
  componentTypesWithConditionalStyles.includes(component.type as never);

export type ComponentTypeWithDefaultBooleanValue =
  (typeof componentTypesWithDefaultBooleanValue)[number];

export type ComponentWithDefaultBooleanValue = Components[ComponentTypeWithDefaultBooleanValue];

export const isComponentWithDefaultBooleanValue = (
  component: Component,
): component is ComponentWithDefaultBooleanValue =>
  componentTypesWithDefaultBooleanValue.includes(component.type as never);

export type ComponentTypeWithDefaultStringValue =
  (typeof componentTypesWithDefaultStringValue)[number];

export type ComponentWithDefaultStringValue = Components[ComponentTypeWithDefaultStringValue];

export const isComponentWithDefaultStringValue = (
  component: Component,
): component is ComponentWithDefaultStringValue =>
  componentTypesWithDefaultStringValue.includes(component.type as never);

export type ComponentTypeWithElementId = (typeof componentTypesWithElementId)[number];

export type ComponentWithElementId = Components[ComponentTypeWithElementId];

export const isComponentWithElementId = (
  component: Component,
): component is ComponentWithElementId =>
  componentTypesWithElementId.includes(component.type as never);

export type ComponentTypeWithLabel = (typeof componentTypesWithLabel)[number];

export type ComponentWithLabel = Components[ComponentTypeWithLabel];

export const isComponentWithLabel = (component: Component): component is ComponentWithLabel =>
  componentTypesWithLabel.includes(component.type as never);

export type ComponentTypeWithMaxLength = (typeof componentTypesWithMaxLength)[number];

export type ComponentWithMaxLength = Components[ComponentTypeWithMaxLength];

export const isComponentWithMaxLength = (
  component: Component,
): component is ComponentWithMaxLength =>
  componentTypesWithMaxLength.includes(component.type as never);

export type ComponentTypeWithName = (typeof componentTypesWithName)[number];

export type ComponentWithName = Components[ComponentTypeWithName];

export const isComponentWithName = (component: Component): component is ComponentWithName =>
  componentTypesWithName.includes(component.type as never);

export type ComponentTypeWithRequired = (typeof componentTypesWithRequired)[number];

export type ComponentWithRequired = Components[ComponentTypeWithRequired];

export const isComponentWithRequired = (component: Component): component is ComponentWithRequired =>
  componentTypesWithRequired.includes(component.type as never);

export type ComponentTypeWithSlot = (typeof componentTypesWithSlot)[number];

export type ComponentWithSlot = Components[ComponentTypeWithSlot];

export const isComponentWithSlot = (component: Component): component is ComponentWithSlot =>
  componentTypesWithSlot.includes(component.type as never);

export type ComponentTypeWithTag = (typeof componentTypesWithTag)[number];

export type ComponentWithTag = Components[ComponentTypeWithTag];

export const isComponentWithTag = (component: Component): component is ComponentWithTag =>
  componentTypesWithTag.includes(component.type as never);

export type ComponentTypeWithStringValue = (typeof componentTypesWithStringValue)[number];

export type ComponentWithStringValue = Components[ComponentTypeWithStringValue];

export const isComponentWithStringValue = (
  component: Component,
): component is ComponentWithStringValue =>
  componentTypesWithStringValue.includes(component.type as never);

// TODO: There should be a way to have a custom description for each component type.
// export const componetTypeWithStringValueDescription = {
//   BooleanInput: 'The provided value will be used as the value for the input when the box is checked. If not provided, the value will be true or false.',
// };

export type ComponentTypeWithoutWrapper = (typeof componentTypesWithoutWrapper)[number];

export type ComponentWithoutWrapper = Components[ComponentTypeWithoutWrapper];

export const isComponentWithoutWrapper = (
  component: Component,
): component is ComponentWithoutWrapper =>
  componentTypesWithoutWrapper.includes(component.type as never);

export type ComponentTypeWithCompletableState = (typeof componentTypesWithCompletableState)[number];

export type ComponentWithCompletableState = Components[ComponentTypeWithCompletableState];

export const isComponentWithCompletableState = (
  component: Component,
): component is ComponentWithCompletableState =>
  componentTypesWithCompletableState.includes(component.type as never);

// An input component is defined as a component that can have a name.
export type InputComponent = Components[ComponentTypeWithName];

export const isInputComponent = (component: Component): component is InputComponent =>
  isComponentWithName(component);
