import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { ButtonComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { ButtonIconPosition, MixerEventType } from '@wirechunk/lib/mixer/types/components.ts';
import { Button as PrimeReactButton } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { useFormContext } from '../../contexts/FormContext/form-context.tsx';
import { useResetInputDataContext } from '../../contexts/InputDataContext.tsx';
import { hrefIsExternal } from '../../util/links.ts';
import type { MixerEventDetailForType } from '../../util/mixer/events.ts';
import { mixerQualifiedEventType } from '../../util/mixer/events.ts';
import { useComponentLabel } from '../mixer-hocs/use-component-label.tsx';

export const Button: FunctionComponent<ButtonComponent> = (props) => {
  const formContext = useFormContext();
  const navigate = useNavigate();
  const resetInputDataContext = useResetInputDataContext();
  const label = useComponentLabel(props);

  let type: 'button' | 'submit' | 'reset' = 'button';
  if (props.onClick?.type === 'submitForm') {
    type = 'submit';
  } else if (props.onClick?.type === 'resetForm') {
    type = 'reset';
  }

  const submittingForm = !!formContext?.submitting;

  return (
    <PrimeReactButton
      className={componentClassName(props)}
      type={type}
      label={label || props.text || ''}
      icon={props.icon || undefined}
      iconPos={props.iconPosition || ButtonIconPosition.Left}
      disabled={submittingForm}
      onClick={() => {
        switch (props.onClick?.type) {
          case 'submitForm':
            // Do the default form action.
            break;
          case 'resetForm':
            resetInputDataContext();
            break;
          case 'goBack':
            void navigate(-1);
            break;
          case 'goBackFormStep':
            formContext?.goBackFormStep();
            break;
          case 'navigate':
            if (props.onClick.url) {
              if (props.onClick.openInNewTab) {
                window.open(props.onClick.url, '_blank');
              } else if (hrefIsExternal(props.onClick.url)) {
                window.open(props.onClick.url);
              } else {
                void navigate(props.onClick.url);
              }
            }
            break;
          default:
            break;
        }
        const mixerEvent = new CustomEvent<MixerEventDetailForType<MixerEventType.Click>>(
          mixerQualifiedEventType(MixerEventType.Click),
          {
            detail: {
              type: MixerEventType.Click,
              component: props,
            },
          },
        );
        document.dispatchEvent(mixerEvent);
      }}
    />
  );
};
