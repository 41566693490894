import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { TextareaInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { stringOrDefaultEmpty } from '@wirechunk/lib/strings.ts';
import { clsx } from 'clsx';
import { isNumber } from 'lodash-es';
import { InputTextarea } from 'primereact/inputtextarea';
import type { ChangeEventHandler, FunctionComponent } from 'react';
import { useCallback } from 'react';
import { useInputDataContext } from '../../contexts/InputDataContext.tsx';
import { useInputId } from '../../hooks/use-input-id.ts';
import { InputNotice, NoticeSeverity } from '../InputNotice/InputNotice.tsx';
import { MixerLabel } from '../mixer-common/mixer-label.tsx';
import { withValidInputComponent } from '../mixer-hocs/with-valid-input-component.tsx';

const GuardedTextareaInput: FunctionComponent<ValidInputComponent<TextareaInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const inputId = useInputId(props);
  const onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (evt) => {
      setValue(props, evt.target.value);
    },
    [setValue, props],
  );

  const validationError = getValidationError(props);

  // Allow converting a number field to a text field.
  const rawValue = getValue(props);
  const inputValue = isNumber(rawValue) ? rawValue.toString() : stringOrDefaultEmpty(rawValue);

  return (
    <div className={componentClassName(props)}>
      <MixerLabel label={props.label} inputId={inputId} />
      <InputTextarea
        id={inputId}
        className={clsx('w-full', validationError && 'p-invalid')}
        placeholder={props.placeholder || undefined}
        maxLength={props.maxLength || undefined}
        value={inputValue}
        onChange={onChange}
      />
      {validationError && (
        <InputNotice severity={NoticeSeverity.Error}>{validationError}</InputNotice>
      )}
    </div>
  );
};

export const TextareaInput = withValidInputComponent<TextareaInputComponent>(GuardedTextareaInput);
