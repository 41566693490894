'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import { Slot } from '@radix-ui/react-slot';

import { Text } from './text.js';
import { calloutRootPropDefs } from './callout.props.js';
import { extractProps } from '../helpers/extract-props.js';
import { mapResponsiveProp, mapCalloutSizeToTextSize } from '../helpers/map-prop-values.js';
import { marginPropDefs } from '../props/margin.props.js';

import type { MarginProps } from '../props/margin.props.js';
import type {
  ComponentPropsWithout,
  RemovedProps,
  ComponentPropsAs,
} from '../helpers/component-props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

type CalloutRootOwnProps = GetPropDefTypes<typeof calloutRootPropDefs>;

type CalloutContextValue = { size?: CalloutRootOwnProps['size'] };
const CalloutContext = React.createContext<CalloutContextValue>({});

type CalloutRootElement = ComponentRef<'div'>;
interface CalloutRootProps
  extends ComponentPropsWithout<'div', RemovedProps>,
    MarginProps,
    CalloutRootOwnProps {}
const CalloutRoot = ({
  ref: forwardedRef,
  ...props
}: CalloutRootProps & {
  ref?: Ref<CalloutRootElement>;
}) => {
  const { size = calloutRootPropDefs.size.default } = props;
  const { asChild, children, className, color, ...rootProps } = extractProps(
    props,
    calloutRootPropDefs,
    marginPropDefs,
  );
  const Comp = asChild ? Slot : 'div';
  return (
    <Comp
      data-accent-color={color}
      {...rootProps}
      className={clsx('rt-CalloutRoot', className)}
      ref={forwardedRef}
    >
      <CalloutContext value={React.useMemo(() => ({ size }), [size])}>{children}</CalloutContext>
    </Comp>
  );
};
CalloutRoot.displayName = 'Callout.Root';

type CalloutIconElement = ComponentRef<'div'>;
interface CalloutIconProps extends ComponentPropsWithout<'div', RemovedProps> {}
const CalloutIcon = ({
  ref: forwardedRef,
  className,
  ...props
}: CalloutIconProps & {
  ref?: Ref<CalloutIconElement>;
}) => {
  return <div {...props} className={clsx('rt-CalloutIcon', className)} ref={forwardedRef} />;
};
CalloutIcon.displayName = 'Callout.Icon';

type CalloutTextElement = ComponentRef<'p'>;
type CalloutTextProps = ComponentPropsAs<typeof Text, 'p'>;
const CalloutText = ({
  ref: forwardedRef,
  className,
  ...props
}: CalloutTextProps & {
  ref?: Ref<CalloutTextElement>;
}) => {
  const { size } = React.use(CalloutContext);
  return (
    <Text
      as="p"
      size={mapResponsiveProp(size, mapCalloutSizeToTextSize)}
      {...props}
      asChild={false}
      ref={forwardedRef}
      className={clsx('rt-CalloutText', className)}
    />
  );
};
CalloutText.displayName = 'Callout.Text';

export { CalloutRoot as Root, CalloutIcon as Icon, CalloutText as Text };
export type {
  CalloutRootProps as RootProps,
  CalloutIconProps as IconProps,
  CalloutTextProps as TextProps,
};
