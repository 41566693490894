import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import { createContext, use } from 'react';

export type SequenceStageContext = {
  sequence: {
    id: string;
    stageLabel: string;
  };
  stage: {
    id: string;
    name: string;
    components: Component[];
  };
  sequenceUser: {
    id: string;
    previousStageBlueprintId?: string | null | undefined;
    nextStageBlueprintId?: string | null | undefined;
  };
  sequenceStageUser: {
    id: string;
    userId: string;
    date: string;
    completed: boolean;
  };
};

const Context = createContext<SequenceStageContext | null>(null);

export const SequenceStageContextProvider = Context.Provider;

export const useSequenceStageContext = (): SequenceStageContext | null => use(Context);
