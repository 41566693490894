import { useMutation } from '@wirechunk/apollo-client';
import { AnalyticsContext } from '@wirechunk/extension-toolkit/web/analytics-context';
import { SvgRadioButtonUnchecked } from '@wirechunk/material-symbols-react-400/20/outlined/radio-button-unchecked.tsx';
import { SvgTaskAlt } from '@wirechunk/material-symbols-react-400/20/outlined/task-alt.tsx';
import { Button, DropdownMenu, IconButton } from '@wirechunk/ui';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import type { FunctionComponent } from 'react';
import { use } from 'react';
import type { RegularCurrentUser } from '../../../contexts/current-user-context.tsx';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import { SiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import type { Entry } from '../../../hooks/useScoreMyCallEntries/useScoreMyCallEntries.ts';
import { useScoreMyCallEntries } from '../../../hooks/useScoreMyCallEntries/useScoreMyCallEntries.ts';
import { Play } from '../../icons/Play.tsx';
import { ScoreMyCallResultsDialog } from '../../ScoreMyCallResultsDialog/ScoreMyCallResultsDialog.tsx';
import { EditScoreMyCallEntryStatusDocument } from './mutations.generated.ts';
import { ScoreMyCallEntryStatus } from '#api';

const isReviewed = (row: Entry) => row.status === ScoreMyCallEntryStatus.Reviewed;

export const ScoreMyCallDetails: FunctionComponent<RegularCurrentUser> = ({ user }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const dialog = useDialog();
  // Here we get platformId from the SiteContext, not from the PlatformContext, because this component
  // can be rendered in a customer site. The current user will belong to the same platform as the site.
  const { platformId } = use(SiteContext);
  const analyticsContext = use(AnalyticsContext);
  const { entries, isLoading } = useScoreMyCallEntries({
    platformId,
    onError,
  });
  const [editScoreMyCallEntryStatus] = useMutation(EditScoreMyCallEntryStatusDocument, {
    onError,
  });

  const hasEditOrgPermission = user.role === 'OrganizationOwner';

  return (
    <div>
      <ErrorMessage />
      <DataTable value={entries} loading={isLoading} tableClassName="font-size-base">
        {hasEditOrgPermission && (
          <Column
            bodyClassName="w-2rem pl-2 pr-1"
            body={(row: Entry) => (
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <IconButton type="button" variant="ghost" color="gray">
                    {isReviewed(row) ? (
                      <SvgTaskAlt className="fill-green-9" />
                    ) : (
                      <SvgRadioButtonUnchecked />
                    )}
                  </IconButton>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <DropdownMenu.Item
                    onSelect={() => {
                      void editScoreMyCallEntryStatus({
                        variables: {
                          id: row.id,
                          status: isReviewed(row)
                            ? ScoreMyCallEntryStatus.NotReviewed
                            : ScoreMyCallEntryStatus.Reviewed,
                        },
                      });
                    }}
                  >
                    {isReviewed(row) ? 'Mark as not reviewed' : 'Mark as reviewed'}
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            )}
          />
        )}
        {hasEditOrgPermission && (
          <Column
            header="Team member"
            body={(row: Entry) => (
              <div>
                <div>{row.user.displayName}</div>
              </div>
            )}
          />
        )}
        <Column
          header="Prospect’s name"
          body={(row: Entry) =>
            row.prospectName ? (
              <div>{row.prospectName}</div>
            ) : (
              <div className="text-color-muted">Not provided</div>
            )
          }
        />
        <Column
          header="Date"
          body={(row: Entry) => <div>{row.createdAtDate.toLocaleDateString()}</div>}
        />
        <Column
          header="Score"
          body={(row: Entry) => `${row.questionsPassed}/${row.questionsTotal}`}
        />
        <Column
          header=""
          align="right"
          body={(row: Entry) => (
            <div className="flex gap-3 justify-content-end">
              <Button
                variant="soft"
                onClick={() => {
                  analyticsContext.track('Score My Call results viewed');
                  dialog({
                    content: <ScoreMyCallResultsDialog entry={row} />,
                    props: {
                      className: 'dialog-width-xl',
                      header: `${row.user.displayName}: Call ${
                        row.prospectName ? `with ${row.prospectName} ` : ''
                      }on ${row.createdAtDate.toLocaleDateString()}`,
                    },
                  });
                }}
              >
                <Play width="9.6" height="10.8" pathClassName="fill-primary" /> Review
              </Button>
            </div>
          )}
        />
      </DataTable>
    </div>
  );
};
