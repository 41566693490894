import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '../util-types.js';

export type PublicSiteLayoutForPathQueryVariables = Types.Exact<{
  siteId: Types.Scalars['String']['input'];
  path: Types.Scalars['String']['input'];
}>;

export type PublicSiteLayoutForPathQuery = {
  publicSite: {
    __typename: 'PublicSite';
    id: string;
    layoutForPath?: {
      __typename: 'Layout';
      id: string;
      pathPrefix: string;
      components: string;
    } | null;
  };
};

export const PublicSiteLayoutForPathDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PublicSiteLayoutForPath' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'layoutForPath' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'path' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pathPrefix' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicSiteLayoutForPathQuery, PublicSiteLayoutForPathQueryVariables>;
