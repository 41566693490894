'use client';

import { clsx } from 'clsx';
import { createContextScope } from '@radix-ui/react-context';

import * as CheckboxGroupPrimitive from './_internal/checkbox-group.primitive.js';
import { createCheckboxGroupScope } from './_internal/checkbox-group.primitive.js';
import { checkboxGroupRootPropDefs } from './checkbox-group.props.js';
import { ThickCheckIcon } from './icons.js';
import { Text } from './text.js';
import { extractProps } from '../helpers/extract-props.js';
import { marginPropDefs } from '../props/margin.props.js';

import type { Scope } from '@radix-ui/react-context';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { MarginProps } from '../props/margin.props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

const CHECKBOX_GROUP_NAME = 'CheckboxGroup';

type ScopedProps<P> = P & { __scopeCheckboxGroup?: Scope };
const [createCheckboxGroupContext] = createContextScope(CHECKBOX_GROUP_NAME, [
  createCheckboxGroupScope,
]);
const useCheckboxGroupScope = createCheckboxGroupScope();

type CheckboxGroupRootOwnProps = GetPropDefTypes<typeof checkboxGroupRootPropDefs>;
type CheckboxGroupContextValue = CheckboxGroupRootOwnProps;

const [CheckboxGroupProvider, useCheckboxGroupContext] =
  createCheckboxGroupContext<CheckboxGroupContextValue>(CHECKBOX_GROUP_NAME);

type CheckboxGroupRootElement = ComponentRef<typeof CheckboxGroupPrimitive.Root>;
interface CheckboxGroupRootProps
  extends ComponentPropsWithout<
      typeof CheckboxGroupPrimitive.Root,
      'asChild' | 'color' | 'defaultChecked'
    >,
    MarginProps,
    CheckboxGroupRootOwnProps {}
const CheckboxGroupRoot = ({
  ref: forwardedRef,
  color = checkboxGroupRootPropDefs.color.default,
  highContrast = checkboxGroupRootPropDefs.highContrast.default,
  size = checkboxGroupRootPropDefs.size.default,
  variant = checkboxGroupRootPropDefs.variant.default,
  ...props
}: CheckboxGroupRootProps & {
  ref?: Ref<CheckboxGroupRootElement>;
}) => {
  const { __scopeCheckboxGroup, className, ...rootProps } = extractProps(
    props as ScopedProps<CheckboxGroupRootProps>,
    marginPropDefs,
  );
  const checkboxGroupScope = useCheckboxGroupScope(__scopeCheckboxGroup);
  return (
    <CheckboxGroupProvider
      scope={__scopeCheckboxGroup}
      color={color}
      size={size}
      highContrast={highContrast}
      variant={variant}
    >
      <CheckboxGroupPrimitive.Root
        {...checkboxGroupScope}
        {...rootProps}
        ref={forwardedRef}
        className={clsx('rt-CheckboxGroupRoot', className)}
      />
    </CheckboxGroupProvider>
  );
};
CheckboxGroupRoot.displayName = 'CheckboxGroup.Root';

type CheckboxGroupItemElement = ComponentRef<typeof CheckboxGroupPrimitive.Item>;
interface CheckboxGroupItemProps
  extends ComponentPropsWithout<typeof CheckboxGroupPrimitive.Item, RemovedProps>,
    MarginProps {}
const CheckboxGroupItem = ({
  ref: forwardedRef,
  ..._props
}: CheckboxGroupItemProps & {
  ref?: Ref<CheckboxGroupItemElement>;
}) => {
  const { __scopeCheckboxGroup, children, className, style, ...props } =
    _props as ScopedProps<CheckboxGroupItemProps>;
  const { size } = useCheckboxGroupContext('CheckboxGroupItem', __scopeCheckboxGroup);

  // Render `<Text as="label">` if children are provided, otherwise render
  // the solo checkbox to allow building out your custom layouts with it.
  if (children) {
    return (
      <Text
        as="label"
        size={size}
        className={clsx('rt-CheckboxGroupItem', className)}
        style={style}
      >
        <CheckboxGroupItemCheckbox
          __scopeCheckboxGroup={__scopeCheckboxGroup}
          {...props}
          ref={forwardedRef}
        />
        {children && <span className="rt-CheckboxGroupItemInner">{children}</span>}
      </Text>
    );
  }

  return (
    <CheckboxGroupItemCheckbox
      __scopeCheckboxGroup={__scopeCheckboxGroup}
      {...props}
      ref={forwardedRef}
      className={className}
      style={style}
    />
  );
};
CheckboxGroupItem.displayName = 'CheckboxGroup.Item';

type CheckboxGroupItemCheckboxElement = ComponentRef<typeof CheckboxGroupPrimitive.Item>;
interface CheckboxGroupItemCheckboxProps
  extends ComponentPropsWithout<typeof CheckboxGroupPrimitive.Item, RemovedProps> {}
const CheckboxGroupItemCheckbox = ({
  ref: forwardedRef,
  __scopeCheckboxGroup,
  ...props
}: ScopedProps<CheckboxGroupItemCheckboxProps> & {
  ref?: Ref<CheckboxGroupItemCheckboxElement>;
}) => {
  const context = useCheckboxGroupContext('CheckboxGroupItemCheckbox', __scopeCheckboxGroup);
  const checkboxGroupScope = useCheckboxGroupScope(__scopeCheckboxGroup);
  const { color, className } = extractProps(
    { ...props, ...context },
    checkboxGroupRootPropDefs,
    marginPropDefs,
  );
  return (
    <CheckboxGroupPrimitive.Item
      {...checkboxGroupScope}
      data-accent-color={color}
      {...props}
      ref={forwardedRef}
      className={clsx('rt-reset', 'rt-BaseCheckboxRoot', 'rt-CheckboxGroupItemCheckbox', className)}
    >
      <CheckboxGroupPrimitive.Indicator
        {...checkboxGroupScope}
        asChild
        className="rt-BaseCheckboxIndicator"
      >
        <ThickCheckIcon />
      </CheckboxGroupPrimitive.Indicator>
    </CheckboxGroupPrimitive.Item>
  );
};
CheckboxGroupItemCheckbox.displayName = 'CheckboxGroup.ItemCheckbox';

export { CheckboxGroupRoot as Root, CheckboxGroupItem as Item };
export type { CheckboxGroupRootProps as RootProps, CheckboxGroupItemProps as ItemProps };
