import { noop } from 'lodash-es';
import type {
  ConfirmDialogOptions,
  ConfirmDialogProps as PrimeReactConfirmDialogProps,
} from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import type { DialogProps } from 'primereact/dialog';
import { Dialog } from 'primereact/dialog';
import type { FunctionComponent, ReactNode } from 'react';
import { createContext, use, useCallback, useState } from 'react';

export const defaultDialogProps: Omit<DialogProps, 'content' | 'onHide'> = {
  visible: true,
  closeOnEscape: false,
  draggable: false,
  resizable: false,
  focusOnShow: false,
  blockScroll: true,
  className: 'dialog-width-md',
};

export type ConfirmDialogProps = {
  // Default: 'Confirmation'
  header?: string;
  className?: string;
  acceptLabel?: string;
  rejectLabel?: string;
  acceptClassName?: string;
  rejectClassName?: string;
  onAccept: () => void;
  onReject?: () => void;
};

type RegularDialogConfig = {
  content: ReactNode;
  onBeforeHide?: () => void;
  props?: Omit<DialogProps, 'content' | 'onHide'>;
};

type ConfirmDialogConfig = {
  confirm: ReactNode | ((options: ConfirmDialogOptions) => ReactNode);
  props: ConfirmDialogProps;
};

export type DialogConfig = RegularDialogConfig | ConfirmDialogConfig;

export type DialogContext = (
  // Display a dialog with the specified content or hide the dialog (with null).
  config: DialogConfig | null,
) => void;

export const DialogContext = createContext<DialogContext>(noop);

type DialogProviderProps = {
  children: ReactNode;
};

export const DialogProvider: FunctionComponent<DialogProviderProps> = ({ children }) => {
  const [dialog, setDialog] = useState<ReactNode>(null);
  const [confirmDialog, setConfirmDialog] = useState<Omit<PrimeReactConfirmDialogProps, 'content'>>(
    {
      visible: false,
    },
  );

  const showDialog = useCallback((config: DialogConfig | null) => {
    if (!config) {
      setDialog(null);
      setConfirmDialog({ visible: false });
      return;
    }

    if ('confirm' in config) {
      setConfirmDialog({
        visible: true,
        closable: false,
        closeOnEscape: false,
        draggable: false,
        resizable: false,
        focusOnShow: false,
        blockScroll: true,
        className: config.props.className || 'dialog-width-md',
        header: config.props.header || 'Confirmation',
        message: config.confirm,
        acceptLabel: config.props.acceptLabel,
        rejectLabel: config.props.rejectLabel,
        acceptClassName: config.props.acceptClassName,
        rejectClassName: config.props.rejectClassName,
        accept: () => {
          setConfirmDialog({ visible: false });
          config.props.onAccept();
        },
        reject: () => {
          setConfirmDialog({ visible: false });
          config.props.onReject?.();
        },
      });
    } else {
      setDialog(
        <Dialog
          {...defaultDialogProps}
          {...config.props}
          visible
          onHide={() => {
            config.onBeforeHide?.();
            setDialog(null);
          }}
        >
          {config.content}
        </Dialog>,
      );
    }
  }, []);

  return (
    <DialogContext value={showDialog}>
      {children}
      {dialog}
      <ConfirmDialog {...confirmDialog} />
    </DialogContext>
  );
};

export const useDialog = (): DialogContext => use(DialogContext);
