import * as React from 'react';
import { clsx } from 'clsx';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';

import { hoverCardContentPropDefs } from './hover-card.props.js';
import { extractProps } from '../helpers/extract-props.js';
import { requireReactElement } from '../helpers/require-react-element.js';

import type { HoverCardContentOwnProps } from './hover-card.props.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { ComponentRef, Ref } from 'react';

interface HoverCardRootProps
  extends React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Root> {}
const HoverCardRoot: React.FC<HoverCardRootProps> = (props) => (
  <HoverCardPrimitive.Root closeDelay={150} openDelay={200} {...props} />
);
HoverCardRoot.displayName = 'HoverCard.Root';

type HoverCardTriggerElement = ComponentRef<typeof HoverCardPrimitive.Trigger>;
interface HoverCardTriggerProps
  extends ComponentPropsWithout<typeof HoverCardPrimitive.Trigger, RemovedProps> {}
const HoverCardTrigger = ({
  ref: forwardedRef,
  children,
  className,
  ...props
}: HoverCardTriggerProps & {
  ref?: Ref<HoverCardTriggerElement>;
}) => (
  <HoverCardPrimitive.Trigger
    ref={forwardedRef}
    className={clsx('rt-HoverCardTrigger', className)}
    {...props}
    asChild
  >
    {requireReactElement(children)}
  </HoverCardPrimitive.Trigger>
);
HoverCardTrigger.displayName = 'HoverCard.Trigger';

type HoverCardContentElement = ComponentRef<typeof HoverCardPrimitive.Content>;
interface HoverCardContentProps
  extends ComponentPropsWithout<typeof HoverCardPrimitive.Content, RemovedProps>,
    HoverCardContentOwnProps {
  container?: React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Portal>['container'];
}
const HoverCardContent = ({
  ref: forwardedRef,
  ...props
}: HoverCardContentProps & {
  ref?: Ref<HoverCardContentElement>;
}) => {
  const { className, forceMount, container, ...contentProps } = extractProps(
    props,
    hoverCardContentPropDefs,
  );
  return (
    <HoverCardPrimitive.Portal container={container} forceMount={forceMount}>
      <HoverCardPrimitive.Content
        align="start"
        sideOffset={8}
        collisionPadding={10}
        {...contentProps}
        ref={forwardedRef}
        className={clsx('rt-PopperContent', 'rt-HoverCardContent', className)}
      />
    </HoverCardPrimitive.Portal>
  );
};
HoverCardContent.displayName = 'HoverCard.Content';

export { HoverCardRoot as Root, HoverCardTrigger as Trigger, HoverCardContent as Content };
export type {
  HoverCardRootProps as RootProps,
  HoverCardTriggerProps as TriggerProps,
  HoverCardContentProps as ContentProps,
};
