import type {
  StringValueSource,
  PlainTextComponent,
} from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useStringValue } from '../mixer-hocs/use-string-value.tsx';

// TODO: Consider if this is too hacky and we should implement PlainText's own string value source, or if
// we should migrate all DataSourceSpec to have the direct variant to have a fixed "value" property.
const transformText = (component: PlainTextComponent): StringValueSource | null | undefined => {
  if (component.text?.type === DataSource.Direct) {
    return {
      type: DataSource.Direct,
      value: component.text.text,
    };
  }
  return component.text;
};

export const PlainText: FunctionComponent<PlainTextComponent> = (props) => {
  return useStringValue({
    value: transformText(props),
  });
};
