import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { RadioGroupInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { stringOrDefaultEmpty } from '@wirechunk/lib/strings.ts';
import { RadioCards, RadioGroup } from '@wirechunk/ui';
import type { FunctionComponent } from 'react';
import { useMemo, useCallback } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';
import { InputValidationErrorMessageProvider } from '../input-validation-error-message/input-validation-error-message-context.ts';
import type { RadioGroupInputContext } from './radio-group-input-context.ts';
import { RadioGroupInputContextProvider } from './radio-group-input-context.ts';

const GuardedRadioGroupInput: FunctionComponent<ValidInputComponent<RadioGroupInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const onValueChange = useCallback<NonNullable<RadioCards.RootProps['onValueChange']>>(
    (value) => {
      setValue(props, value);
    },
    [props, setValue],
  );
  const displayType = props.displayType ?? 'indicators';
  const radioGroupInputContextValue = useMemo<RadioGroupInputContext>(
    () => ({
      displayType,
    }),
    [displayType],
  );

  // Always coalesce null and undefined to a string to keep this a controlled component.
  const value = stringOrDefaultEmpty(getValue(props));

  if (displayType === 'indicators') {
    return (
      <RadioGroup.Root
        className={componentClassName(props)}
        name={props.name}
        value={value}
        onValueChange={onValueChange}
      >
        <RadioGroupInputContextProvider value={radioGroupInputContextValue}>
          <InputValidationErrorMessageProvider value={getValidationError(props)}>
            <RenderMixerChildren>{props.children}</RenderMixerChildren>
          </InputValidationErrorMessageProvider>
        </RadioGroupInputContextProvider>
      </RadioGroup.Root>
    );
  }

  return (
    <RadioCards.Root
      className={componentClassName(props)}
      name={props.name}
      value={value}
      onValueChange={onValueChange}
    >
      <RadioGroupInputContextProvider value={radioGroupInputContextValue}>
        <InputValidationErrorMessageProvider value={getValidationError(props)}>
          <RenderMixerChildren>{props.children}</RenderMixerChildren>
        </InputValidationErrorMessageProvider>
      </RadioGroupInputContextProvider>
    </RadioCards.Root>
  );
};

export const RadioGroupInput =
  withValidInputComponent<RadioGroupInputComponent>(GuardedRadioGroupInput);
