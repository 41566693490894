export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A timestamp in ISO 8601 format */
  Date: { input: string | Date; output: string; }
};

export type ActiveCampaignApi = {
  __typename?: 'ActiveCampaignApi';
  tag: ActiveCampaignTag;
  tags: ActiveCampaignApiTagsList;
};

export type ActiveCampaignApiTagArgs = {
  id: Scalars['String']['input'];
};

export type ActiveCampaignApiTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ActiveCampaignApiTagsList = ListResult & {
  __typename?: 'ActiveCampaignApiTagsList';
  limit: Scalars['Int']['output'];
  tags: Array<ActiveCampaignTag>;
  totalCount: Scalars['Int']['output'];
};

export type ActiveCampaignTag = {
  __typename?: 'ActiveCampaignTag';
  id: Scalars['String']['output'];
  subscriberCount: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
};

export type ActiveCampaignTagsSyncConfig = {
  __typename?: 'ActiveCampaignTagsSyncConfig';
  activeCampaignTagId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  subscriptionPlanIds: Array<Scalars['String']['output']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

/**
 * An admin user is a subset of a User. This type is used when the host platform does not have a site and orgs
 * like normal platforms and instead the admin dashboard is displayed on a configured domain where admin users
 * can sign in (in this case features that require a site and orgs are not available).
 */
export type AdminUser = {
  __typename?: 'AdminUser';
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  platformAdminUsers: Array<PlatformAdminUser>;
};

export type Agreement = {
  __typename?: 'Agreement';
  content?: Maybe<Delta>;
  id: Scalars['String']['output'];
  publishTimestamp: Scalars['Date']['output'];
  text?: Maybe<Scalars['String']['output']>;
  type: AgreementType;
};

export const AgreementType = {
  PrivacyPolicy: 'PrivacyPolicy',
  TermsOfUse: 'TermsOfUse'
} as const;

export type AgreementType = typeof AgreementType[keyof typeof AgreementType];
export type AgreementsList = {
  __typename?: 'AgreementsList';
  agreements: Array<Agreement>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ApiToken = {
  __typename?: 'ApiToken';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  invalidatedAt?: Maybe<Scalars['Date']['output']>;
  name: Scalars['String']['output'];
};

export type ApiTokensList = ListResult & {
  __typename?: 'ApiTokensList';
  apiTokens: Array<ApiToken>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ApplyCustomPromoCodeResult = ApplyCustomPromoCodeSuccessResult | GenericInternalError;

export type ApplyCustomPromoCodeSuccessResult = {
  __typename?: 'ApplyCustomPromoCodeSuccessResult';
  promoCode: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  successMessage?: Maybe<Delta>;
  valid: Scalars['Boolean']['output'];
};

export type ArchiveComponentResult = ArchiveComponentSuccessResult | GenericUserError;

export type ArchiveComponentSuccessResult = {
  __typename?: 'ArchiveComponentSuccessResult';
  component: Component;
};

export type AuthorizationError = Error & {
  __typename?: 'AuthorizationError';
  message: Scalars['String']['output'];
};

export type BodyStylesUpdate = {
  /** A JSON object. */
  value: Scalars['String']['input'];
};

export type BooleanUpdate = {
  value: Scalars['Boolean']['input'];
};

export type BuilderComponent = BuiltInComponent | Component | RemoteComponent;

export type BuilderComponentsList = ListResult & {
  __typename?: 'BuilderComponentsList';
  builderComponents: Array<BuilderComponent>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type BuiltInComponent = {
  __typename?: 'BuiltInComponent';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Component = {
  __typename?: 'Component';
  archived: Scalars['Boolean']['output'];
  components: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewProps?: Maybe<Scalars['String']['output']>;
  propsSetupComponents?: Maybe<Scalars['String']['output']>;
};

export type ComponentsList = ListResult & {
  __typename?: 'ComponentsList';
  components: Array<Component>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ComponentsUpdate = {
  value: Scalars['String']['input'];
};

export const ConfirmationAction = {
  /** Display only a message. */
  Message: 'Message',
  /** Display a message and keep the form as is. */
  MessageKeepForm: 'MessageKeepForm',
  /** Redirect to a URL. */
  Redirect: 'Redirect'
} as const;

export type ConfirmationAction = typeof ConfirmationAction[keyof typeof ConfirmationAction];
export type Course = {
  __typename?: 'Course';
  archived: Scalars['Boolean']['output'];
  courseAndLessonLocationVersions: CourseAndLessonLocationVersionsList;
  emptyNotesMessage?: Maybe<Scalars['String']['output']>;
  enableNotes: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lessonCompletionMode: LessonCompletionMode;
  lessons: LessonsList;
  maxDepth: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  versions: CourseVersionsList;
};

export type CourseCourseAndLessonLocationVersionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CourseLessonsArgs = {
  deep?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<PublishStatus>>;
};

export type CourseVersionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CourseAndLessonLocationVersionsList = ListResult & {
  __typename?: 'CourseAndLessonLocationVersionsList';
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  versions: Array<CourseVersionOrLessonVersion>;
};

export type CourseVersion = {
  __typename?: 'CourseVersion';
  archived?: Maybe<Scalars['Boolean']['output']>;
  byUser: UserProfile;
  changes: Array<CourseVersionChange>;
  createdAt: Scalars['Date']['output'];
  emptyNotesMessage?: Maybe<Scalars['String']['output']>;
  enableNotes?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  lessonCompletionMode?: Maybe<LessonCompletionMode>;
  maxDepth?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export const CourseVersionChange = {
  Archived: 'Archived',
  EmptyNotesMessage: 'EmptyNotesMessage',
  EnableNotes: 'EnableNotes',
  LessonCompletionMode: 'LessonCompletionMode',
  MaxDepth: 'MaxDepth',
  Title: 'Title'
} as const;

export type CourseVersionChange = typeof CourseVersionChange[keyof typeof CourseVersionChange];
export type CourseVersionOrLessonVersion = CourseVersion | LessonVersion;

export type CourseVersionsList = ListResult & {
  __typename?: 'CourseVersionsList';
  courseVersions: Array<CourseVersion>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CoursesList = ListResult & {
  __typename?: 'CoursesList';
  courses: Array<Course>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CreateAdminUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  permissionNames?: InputMaybe<Array<PermissionName>>;
  platformId: Scalars['String']['input'];
};

export type CreateAdminUserResult = CreateAdminUserSuccessResult | GenericUserError;

export type CreateAdminUserSuccessResult = {
  __typename?: 'CreateAdminUserSuccessResult';
  platformAdminUser: PlatformAdminUser;
};

export type CreateApiTokenInput = {
  name: Scalars['String']['input'];
};

export type CreateApiTokenResult = CreateApiTokenSuccessResult | GenericUserError;

export type CreateApiTokenSuccessResult = {
  __typename?: 'CreateApiTokenSuccessResult';
  apiToken: ApiToken;
  /** A JWT. */
  token: Scalars['String']['output'];
};

export type CreateComponentInput = {
  components?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type CreateComponentResult = CreateComponentSuccessResult | GenericUserError;

export type CreateComponentSuccessResult = {
  __typename?: 'CreateComponentSuccessResult';
  component: Component;
};

export type CreateCourseInput = {
  platformId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateCourseResult = CreateCourseSuccessResult | GenericUserError;

export type CreateCourseSuccessResult = {
  __typename?: 'CreateCourseSuccessResult';
  course: Course;
};

export type CreateExtensionInput = {
  /** A JSON object. */
  config?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type CreateExtensionResult = CreateExtensionSuccessResult | GenericInternalError | GenericUserError;

export type CreateExtensionSuccessResult = {
  __typename?: 'CreateExtensionSuccessResult';
  extension: Extension;
};

export type CreateExtensionVersionInput = {
  /** Automatically deploy the version as the extension’s current version after it is built. */
  autoDeploy?: Scalars['Boolean']['input'];
  /** A JSON object. */
  config?: InputMaybe<Scalars['String']['input']>;
  enableDb: Scalars['Boolean']['input'];
  enableServer: Scalars['Boolean']['input'];
  extensionId: Scalars['String']['input'];
  /** The extension name is required only so that we can validate that a user is not mistakenly trying to change the name. */
  extensionName: Scalars['String']['input'];
  versionName: Scalars['String']['input'];
};

export type CreateExtensionVersionResult = AuthorizationError | CreateExtensionVersionSuccessResult | GenericInternalError | GenericUserError;

export type CreateExtensionVersionSuccessResult = {
  __typename?: 'CreateExtensionVersionSuccessResult';
  extension: Extension;
  extensionVersion: ExtensionVersion;
  /** The URL to PUT an archive file to with the extension’s source code. */
  signedUrl: Scalars['String']['output'];
};

export type CreateLayoutInput = {
  components: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pathPrefix: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type CreateLayoutResult = CreateLayoutSuccessResult | GenericUserError;

export type CreateLayoutSuccessResult = {
  __typename?: 'CreateLayoutSuccessResult';
  layout: Layout;
};

export type CreateLayoutTemplateInput = {
  components: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pathPrefix: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type CreateLayoutTemplateResult = AuthorizationError | CreateLayoutTemplateSuccessResult | GenericUserError;

export type CreateLayoutTemplateSuccessResult = {
  __typename?: 'CreateLayoutTemplateSuccessResult';
  layoutTemplate: LayoutTemplate;
};

export type CreateLessonInput = {
  courseId: Scalars['String']['input'];
  parentLessonId?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateLessonResult = CreateLessonSuccessResult | GenericUserError;

export type CreateLessonSuccessResult = {
  __typename?: 'CreateLessonSuccessResult';
  lesson: Lesson;
};

export type CreateLessonUserInput = {
  lessonId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateLessonUserResult = CreateLessonUserSuccessResult | GenericUserError;

export type CreateLessonUserSuccessResult = {
  __typename?: 'CreateLessonUserSuccessResult';
  lesson: Lesson;
  lessonUser: LessonUser;
};

export type CreateProductResult = CreateProductSuccessResult | GenericUserError;

export type CreateProductSuccessResult = {
  __typename?: 'CreateProductSuccessResult';
  product: SubscriptionPlan;
};

export type CreateSequenceInput = {
  loopStages: Scalars['Boolean']['input'];
  platformId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateSequenceResult = CreateSequenceSuccessResult | GenericUserError;

export type CreateSequenceSuccessResult = {
  __typename?: 'CreateSequenceSuccessResult';
  sequence: Sequence;
};

export type CreateSiteInput = {
  customFields?: InputMaybe<Array<KeyValueInput>>;
  /** If domain is not provided, a domain will have to be generated by an extension. */
  domain?: InputMaybe<Scalars['String']['input']>;
  googleTagManagerId?: InputMaybe<Scalars['String']['input']>;
  logoIconUrl?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  metaPixelId?: InputMaybe<Scalars['String']['input']>;
  mixpanelToken?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
  siteTemplateId?: InputMaybe<Scalars['String']['input']>;
  stripePublishableKey?: InputMaybe<Scalars['String']['input']>;
  zendeskChatKey?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSiteResult = AuthorizationError | CreateSiteSuccessResult | GenericInternalError | GenericUserError;

export type CreateSiteSuccessResult = {
  __typename?: 'CreateSiteSuccessResult';
  site: Site;
};

export type CreateSiteTemplateInput = {
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type CreateSiteTemplateLayoutTemplatesResult = CreateSiteTemplateLayoutTemplatesSuccessResult | GenericUserError;

export type CreateSiteTemplateLayoutTemplatesSuccessResult = {
  __typename?: 'CreateSiteTemplateLayoutTemplatesSuccessResult';
  siteTemplate: SiteTemplate;
};

export type CreateSiteTemplatePageTemplatesResult = CreateSiteTemplatePageTemplatesSuccessResult | GenericUserError;

export type CreateSiteTemplatePageTemplatesSuccessResult = {
  __typename?: 'CreateSiteTemplatePageTemplatesSuccessResult';
  siteTemplate: SiteTemplate;
};

export type CreateSiteTemplateResult = CreateSiteTemplateSuccessResult | GenericUserError;

export type CreateSiteTemplateSuccessResult = {
  __typename?: 'CreateSiteTemplateSuccessResult';
  siteTemplate: SiteTemplate;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  expiresAt?: InputMaybe<Scalars['Date']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  /**
   * Provide orgId when the new user is being added to an existing org. Otherwise a new org is automatically created and the user is set as the primary.
   * Must not be provided (or can be the requesting user’s own org ID) when adding a peer org member.
   */
  orgId?: InputMaybe<Scalars['String']['input']>;
  platformId: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
};

export type CreateUserResult = AuthorizationError | CreateUserSuccessResult | GenericInternalError | GenericUserError;

export type CreateUserSuccessResult = {
  __typename?: 'CreateUserSuccessResult';
  user: User;
};

export type CustomField = {
  __typename?: 'CustomField';
  /** A JSON value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  deployStage: CustomFieldDeployStage;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  indexed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencesObject?: Maybe<CustomTableObject>;
  required: Scalars['Boolean']['output'];
  requiredValid: Scalars['Boolean']['output'];
  type: CustomFieldType;
  unique: Scalars['Boolean']['output'];
};

export const CustomFieldDeployStage = {
  Create: 'Create',
  Live: 'Live',
  Remove: 'Remove'
} as const;

export type CustomFieldDeployStage = typeof CustomFieldDeployStage[keyof typeof CustomFieldDeployStage];
export type CustomFieldInput = {
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  indexed: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  referencesObject?: InputMaybe<CustomTableObject>;
  required: Scalars['Boolean']['input'];
  type: CustomFieldType;
  unique: Scalars['Boolean']['input'];
};

export const CustomFieldType = {
  Boolean: 'Boolean',
  CustomFieldId: 'CustomFieldId',
  /** A plain date. */
  Date: 'Date',
  /** A plain date and a plain time combined. */
  DateTime: 'DateTime',
  Float: 'Float',
  FormId: 'FormId',
  FormTemplateId: 'FormTemplateId',
  Instant: 'Instant',
  Int: 'Int',
  MixerComponents: 'MixerComponents',
  OrgId: 'OrgId',
  PageId: 'PageId',
  PageTemplateId: 'PageTemplateId',
  ProductId: 'ProductId',
  RichText: 'RichText',
  SiteId: 'SiteId',
  String: 'String',
  SubscriptionId: 'SubscriptionId',
  UserId: 'UserId'
} as const;

export type CustomFieldType = typeof CustomFieldType[keyof typeof CustomFieldType];
export type CustomFieldValue = {
  __typename?: 'CustomFieldValue';
  customField: CustomField;
  id: Scalars['String']['output'];
  /** A JSON value, possibly null. */
  value: Scalars['String']['output'];
};

export type CustomFieldValuesList = ListResult & {
  __typename?: 'CustomFieldValuesList';
  customFieldValues: Array<CustomFieldValue>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomFieldVersionsList = ListResult & {
  __typename?: 'CustomFieldVersionsList';
  customFields: Array<CustomField>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomFieldsList = ListResult & {
  __typename?: 'CustomFieldsList';
  customFields: Array<CustomField>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomTable = {
  __typename?: 'CustomTable';
  editorFormVersions: CustomTableEditorFormVersionsList;
  fields: CustomFieldsList;
  id: Scalars['String']['output'];
  object?: Maybe<CustomTableObject>;
  /** The table's versions, sorted descending by createdAt. */
  versions: CustomTableVersionsList;
};

export type CustomTableFieldsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomTableVersionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomTableEditorFormVersion = {
  __typename?: 'CustomTableEditorFormVersion';
  components: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
};

export type CustomTableEditorFormVersionsList = ListResult & {
  __typename?: 'CustomTableEditorFormVersionsList';
  customTableEditorFormVersions: Array<CustomTableEditorFormVersion>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export const CustomTableObject = {
  Form: 'Form',
  FormTemplate: 'FormTemplate',
  Org: 'Org',
  Page: 'Page',
  PageTemplate: 'PageTemplate',
  Product: 'Product',
  Site: 'Site',
  Subscription: 'Subscription',
  User: 'User'
} as const;

export type CustomTableObject = typeof CustomTableObject[keyof typeof CustomTableObject];
export type CustomTableVersion = {
  __typename?: 'CustomTableVersion';
  byUser: UserProfile;
  createdAt: Scalars['Date']['output'];
  deployCanceledAt?: Maybe<Scalars['Date']['output']>;
  deployPublishedAt?: Maybe<Scalars['Date']['output']>;
  deployStage: CustomTableVersionDeployStage;
  fields: CustomTableVersionFieldsList;
  id: Scalars['String']['output'];
};

export type CustomTableVersionFieldsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export const CustomTableVersionDeployStage = {
  Backfill: 'Backfill',
  Create: 'Create',
  Publish: 'Publish',
  Validate: 'Validate'
} as const;

export type CustomTableVersionDeployStage = typeof CustomTableVersionDeployStage[keyof typeof CustomTableVersionDeployStage];
export type CustomTableVersionField = {
  __typename?: 'CustomTableVersionField';
  /** A JSON value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  indexed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencesObject?: Maybe<CustomTableObject>;
  required: Scalars['Boolean']['output'];
  type: CustomFieldType;
  unique: Scalars['Boolean']['output'];
};

export type CustomTableVersionFieldsList = ListResult & {
  __typename?: 'CustomTableVersionFieldsList';
  customTableVersionFields: Array<CustomTableVersionField>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomTableVersionsList = ListResult & {
  __typename?: 'CustomTableVersionsList';
  customTableVersions: Array<CustomTableVersion>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomTablesList = ListResult & {
  __typename?: 'CustomTablesList';
  customTables: Array<CustomTable>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type DeleteExtensionVersionResult = AuthorizationError | DeleteExtensionVersionSuccessResult | GenericUserError;

export type DeleteExtensionVersionSuccessResult = {
  __typename?: 'DeleteExtensionVersionSuccessResult';
  extension: Extension;
};

export type DeleteSiteTemplateLayoutTemplatesResult = DeleteSiteTemplateLayoutTemplatesSuccessResult | GenericUserError;

export type DeleteSiteTemplateLayoutTemplatesSuccessResult = {
  __typename?: 'DeleteSiteTemplateLayoutTemplatesSuccessResult';
  siteTemplate: SiteTemplate;
};

export type DeleteSiteTemplatePageTemplatesResult = DeleteSiteTemplatePageTemplatesSuccessResult | GenericUserError;

export type DeleteSiteTemplatePageTemplatesSuccessResult = {
  __typename?: 'DeleteSiteTemplatePageTemplatesSuccessResult';
  siteTemplate: SiteTemplate;
};

export type Delta = {
  __typename?: 'Delta';
  /** A JSON object */
  delta: Scalars['String']['output'];
};

export type Document = {
  __typename?: 'Document';
  currentVersion?: Maybe<File>;
  downloadPrompt?: Maybe<DocumentDownloadPrompt>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  productItem?: Maybe<Scalars['String']['output']>;
  roles: Array<Scalars['String']['output']>;
  stamp: Scalars['Boolean']['output'];
  status: PublishStatus;
  /** All of the document's versions. Accessible only to users with PermissionName.View on the document's platform. */
  versions: Array<File>;
};

export type DocumentDownloadPrompt = {
  __typename?: 'DocumentDownloadPrompt';
  acceptLabel: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  prompt: Delta;
  promptHeader: Scalars['String']['output'];
};

export type DocumentsList = ListResult & {
  __typename?: 'DocumentsList';
  documents: Array<Document>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type DuplicateComponentInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type DuplicateComponentResult = DuplicateComponentSuccessResult | GenericInternalError | GenericUserError;

export type DuplicateComponentSuccessResult = {
  __typename?: 'DuplicateComponentSuccessResult';
  component: Component;
};

export type DuplicateCourseInput = {
  id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type DuplicateCourseResult = DuplicateCourseSuccessResult | GenericUserError;

export type DuplicateCourseSuccessResult = {
  __typename?: 'DuplicateCourseSuccessResult';
  course: Course;
};

export type DuplicateLessonInput = {
  id: Scalars['String']['input'];
  parentLessonId?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type DuplicateLessonResult = DuplicateLessonSuccessResult | GenericUserError;

export type DuplicateLessonSuccessResult = {
  __typename?: 'DuplicateLessonSuccessResult';
  lesson: Lesson;
};

export type DuplicateSequenceResult = DuplicateSequenceSuccessResult | GenericUserError;

export type DuplicateSequenceSuccessResult = {
  __typename?: 'DuplicateSequenceSuccessResult';
  sequence: Sequence;
};

export type EditComponentInput = {
  components?: InputMaybe<OptionalComponentsUpdate>;
  id: Scalars['String']['input'];
  name?: InputMaybe<OptionalStringUpdate>;
  previewProps?: InputMaybe<OptionalExpressionDataUpdate>;
  propsSetupComponents?: InputMaybe<OptionalComponentsUpdate>;
};

export type EditComponentResult = EditComponentSuccessResult | GenericUserError;

export type EditComponentSuccessResult = {
  __typename?: 'EditComponentSuccessResult';
  component: Component;
};

export type EditCourseInput = {
  archived?: InputMaybe<BooleanUpdate>;
  emptyNotesMessage?: InputMaybe<OptionalStringUpdate>;
  enableNotes?: InputMaybe<BooleanUpdate>;
  id: Scalars['String']['input'];
  lessonCompletionMode?: InputMaybe<LessonCompletionModeUpdate>;
  maxDepth?: InputMaybe<IntUpdate>;
  title?: InputMaybe<StringUpdate>;
};

export type EditCourseResult = EditCourseSuccessResult | GenericUserError;

export type EditCourseSuccessResult = {
  __typename?: 'EditCourseSuccessResult';
  course: Course;
};

export type EditCustomTableInput = {
  /**
   * When there is already a version of the table, this field must reference the latest version of
   * the table to ensure that the client is aware of the latest schema. This way the client will not
   * accidentally overwrite changes made asynchronously.
   */
  consistencyKeyCurrentTableVersionId?: InputMaybe<Scalars['String']['input']>;
  fields: Array<CustomFieldInput>;
  object: CustomTableObject;
  platformId: Scalars['String']['input'];
};

export type EditCustomTableResult = EditCustomTableSuccessResult | GenericUserError;

export type EditCustomTableSuccessResult = {
  __typename?: 'EditCustomTableSuccessResult';
  customTable: CustomTable;
};

export type EditExtensionInput = {
  /** A JSON object. */
  config?: InputMaybe<StringUpdate>;
  currentVersionId?: InputMaybe<StringUpdate>;
  enabled?: InputMaybe<BooleanUpdate>;
  id: Scalars['String']['input'];
};

export type EditExtensionResult = AuthorizationError | EditExtensionSuccessResult | GenericUserError;

export type EditExtensionSuccessResult = {
  __typename?: 'EditExtensionSuccessResult';
  extension: Extension;
};

export type EditFormStepPositionResult = AuthorizationError | EditFormStepPositionSuccessResult | GenericInternalError | GenericUserError;

export type EditFormStepPositionSuccessResult = {
  __typename?: 'EditFormStepPositionSuccessResult';
  formStep: FormStep;
};

export type EditFormTemplateEmailTemplateInput = {
  emailBodyTemplate?: InputMaybe<OptionalStringUpdate>;
  formTemplateId: Scalars['String']['input'];
  useEmailBodyTemplate: Scalars['Boolean']['input'];
};

export type EditFormTemplateEmailTemplateResult = EditFormTemplateEmailTemplateSuccessResult | GenericUserError;

export type EditFormTemplateEmailTemplateSuccessResult = {
  __typename?: 'EditFormTemplateEmailTemplateSuccessResult';
  formTemplate: FormTemplate;
};

export type EditFormTemplateFormattedDataTemplateInput = {
  formTemplateId: Scalars['String']['input'];
  formattedDataTemplate?: InputMaybe<OptionalStringUpdate>;
  useFormattedDataTemplate: Scalars['Boolean']['input'];
};

export type EditFormTemplateFormattedDataTemplateResult = EditFormTemplateFormattedDataTemplateSuccessResult | GenericUserError;

export type EditFormTemplateFormattedDataTemplateSuccessResult = {
  __typename?: 'EditFormTemplateFormattedDataTemplateSuccessResult';
  formTemplate: FormTemplate;
};

export type EditLayoutInput = {
  components?: InputMaybe<ComponentsUpdate>;
  id: Scalars['String']['input'];
  name?: InputMaybe<StringUpdate>;
  pathPrefix?: InputMaybe<StringUpdate>;
  status?: InputMaybe<PublishStatusUpdate>;
};

export type EditLayoutResult = EditLayoutSuccessResult | GenericUserError;

export type EditLayoutSuccessResult = {
  __typename?: 'EditLayoutSuccessResult';
  layout: Layout;
};

export type EditLayoutTemplateInput = {
  components?: InputMaybe<ComponentsUpdate>;
  id: Scalars['String']['input'];
  name?: InputMaybe<StringUpdate>;
  pathPrefix?: InputMaybe<StringUpdate>;
  status?: InputMaybe<PublishStatusUpdate>;
};

export type EditLayoutTemplateResult = AuthorizationError | EditLayoutTemplateSuccessResult | GenericUserError;

export type EditLayoutTemplateSuccessResult = {
  __typename?: 'EditLayoutTemplateSuccessResult';
  layoutTemplate: LayoutTemplate;
};

export type EditLessonInput = {
  completionSpec?: InputMaybe<OptionalStringUpdate>;
  components?: InputMaybe<ComponentsUpdate>;
  enableContent?: InputMaybe<BooleanUpdate>;
  id: Scalars['String']['input'];
  parentLessonId?: InputMaybe<OptionalStringUpdate>;
  position?: InputMaybe<StringUpdate>;
  status?: InputMaybe<PublishStatusUpdate>;
  title?: InputMaybe<StringUpdate>;
};

export type EditLessonResult = EditLessonSuccessResult | GenericUserError;

export type EditLessonSuccessResult = {
  __typename?: 'EditLessonSuccessResult';
  lesson: Lesson;
};

export type EditLessonUserInput = {
  completed?: InputMaybe<BooleanUpdate>;
  id: Scalars['String']['input'];
  notes?: InputMaybe<StringUpdate>;
  state?: InputMaybe<StringUpdate>;
};

export type EditLessonUserResult = EditLessonUserSuccessResult | GenericUserError;

export type EditLessonUserSuccessResult = {
  __typename?: 'EditLessonUserSuccessResult';
  /** LessonUsers that were automatically edited (i.e., the value of completedAt) as a result of this edit. */
  autoEditedLessonUsers: Array<LessonUser>;
  /** Lessons whose lessonUser was automatically edited (i.e., the value of completedAt) as a result of this edit. */
  autoEditedLessons: Array<Lesson>;
  lessonUser: LessonUser;
};

export type EditOrgPrimaryUserInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user to set as the primary in their org. */
  userId: Scalars['String']['input'];
};

export type EditOrgPrimaryUserResult = EditOrgPrimaryUserSuccessResult | GenericUserError;

export type EditOrgPrimaryUserSuccessResult = {
  __typename?: 'EditOrgPrimaryUserSuccessResult';
  org: Organization;
  previousPrimaryUser?: Maybe<User>;
};

export type EditPageInput = {
  bodyStyles?: InputMaybe<BodyStylesUpdate>;
  components?: InputMaybe<ComponentsUpdate>;
  id: Scalars['String']['input'];
  layoutId?: InputMaybe<OptionalStringUpdate>;
  metaDescription?: InputMaybe<OptionalStringUpdate>;
  metaImageUrl?: InputMaybe<OptionalStringUpdate>;
  metaRobots?: InputMaybe<StringListUpdate>;
  metaTitle?: InputMaybe<OptionalStringUpdate>;
  pageTemplateId?: InputMaybe<OptionalStringUpdate>;
  path?: InputMaybe<StringUpdate>;
  productItem?: InputMaybe<OptionalStringUpdate>;
  public?: InputMaybe<BooleanUpdate>;
  roles?: InputMaybe<StringListUpdate>;
  status?: InputMaybe<PublishStatusUpdate>;
  title?: InputMaybe<StringUpdate>;
};

export type EditPageResult = AuthorizationError | EditPageSuccessResult | GenericUserError;

export type EditPageSuccessResult = {
  __typename?: 'EditPageSuccessResult';
  page: Page;
};

export type EditPageTemplateInput = {
  bodyStyles?: InputMaybe<StringUpdate>;
  components?: InputMaybe<OptionalComponentsUpdate>;
  description?: InputMaybe<OptionalStringUpdate>;
  id: Scalars['String']['input'];
  metaDescription?: InputMaybe<OptionalStringUpdate>;
  metaImageUrl?: InputMaybe<OptionalStringUpdate>;
  metaRobots?: InputMaybe<StringListUpdate>;
  metaTitle?: InputMaybe<OptionalStringUpdate>;
  path?: InputMaybe<StringUpdate>;
  previewImageUrl?: InputMaybe<OptionalStringUpdate>;
  previewProps?: InputMaybe<OptionalExpressionDataUpdate>;
  propsSetupComponents?: InputMaybe<OptionalComponentsUpdate>;
  status?: InputMaybe<PublishStatusUpdate>;
  title?: InputMaybe<StringUpdate>;
};

export type EditPageTemplateResult = AuthorizationError | EditPageTemplateSuccessResult | GenericUserError;

export type EditPageTemplateSuccessResult = {
  __typename?: 'EditPageTemplateSuccessResult';
  pageTemplate: PageTemplate;
};

export type EditPlatformAdminUserInput = {
  active?: InputMaybe<BooleanUpdate>;
  id: Scalars['String']['input'];
  owner?: InputMaybe<BooleanUpdate>;
  permissionNames?: InputMaybe<PermissionNamesListUpdate>;
};

export type EditPlatformAdminUserResult = AuthorizationError | EditPlatformAdminUserSuccessResult | GenericUserError;

export type EditPlatformAdminUserSuccessResult = {
  __typename?: 'EditPlatformAdminUserSuccessResult';
  platformAdminUser: PlatformAdminUser;
};

export type EditPlatformInput = {
  emailReplyToAddress?: InputMaybe<OptionalStringUpdate>;
  emailSendFromAddress?: InputMaybe<StringUpdate>;
  id: Scalars['String']['input'];
  sendGridApiKey?: InputMaybe<OptionalStringUpdate>;
  stripeSecretKey?: InputMaybe<OptionalStringUpdate>;
  stripeWebhookSigningSecret?: InputMaybe<OptionalStringUpdate>;
};

export type EditPlatformResult = EditPlatformSuccessResult | GenericUserError;

export type EditPlatformSuccessResult = {
  __typename?: 'EditPlatformSuccessResult';
  platform: Platform;
};

export type EditProductResult = EditProductSuccessResult | GenericUserError;

export type EditProductSuccessResult = {
  __typename?: 'EditProductSuccessResult';
  product: SubscriptionPlan;
};

export type EditSequenceResult = EditSequenceSuccessResult | GenericUserError;

export type EditSequenceSuccessResult = {
  __typename?: 'EditSequenceSuccessResult';
  sequence: Sequence;
};

export type EditSequenceUserInput = {
  sequenceId: Scalars['String']['input'];
  stageId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type EditSequenceUserResult = EditSequenceUserSuccessResult | GenericUserError;

export type EditSequenceUserSuccessResult = {
  __typename?: 'EditSequenceUserSuccessResult';
  sequenceUser: SequenceUser;
};

export type EditSiteInput = {
  /** The custom fields to edit. An empty list has no effect. */
  customFields?: InputMaybe<Array<KeyValueInput>>;
  domain?: InputMaybe<StringUpdate>;
  googleTagManagerId?: InputMaybe<OptionalStringUpdate>;
  id: Scalars['String']['input'];
  logoIconUrl?: InputMaybe<OptionalStringUpdate>;
  logoUrl?: InputMaybe<OptionalStringUpdate>;
  metaPixelId?: InputMaybe<OptionalStringUpdate>;
  mixpanelToken?: InputMaybe<OptionalStringUpdate>;
  name?: InputMaybe<StringUpdate>;
  privacyPolicy?: InputMaybe<RichTextInput>;
  siteTemplateId?: InputMaybe<OptionalStringUpdate>;
  stripePublishableKey?: InputMaybe<OptionalStringUpdate>;
  termsOfUse?: InputMaybe<RichTextInput>;
  zendeskChatKey?: InputMaybe<OptionalStringUpdate>;
};

export type EditSiteResult = AuthorizationError | EditSiteSuccessResult | GenericInternalError | GenericUserError;

export type EditSiteSuccessResult = {
  __typename?: 'EditSiteSuccessResult';
  site: Site;
};

export type EditUserInput = {
  /** The custom fields to edit. An empty list has no effect. */
  customFields?: InputMaybe<Array<KeyValueInput>>;
  expiresAt?: InputMaybe<OptionalDateUpdate>;
  id: Scalars['String']['input'];
};

export type EditUserResult = EditUserSuccessResult | GenericUserError;

export type EditUserSuccessResult = {
  __typename?: 'EditUserSuccessResult';
  user: User;
};

export type Error = {
  /** A user-friendly error message. */
  message: Scalars['String']['output'];
};

export type Extension = {
  __typename?: 'Extension';
  /** A JSON object. This object will not have the current version's config merged in. */
  config: Scalars['String']['output'];
  currentVersion?: Maybe<ExtensionVersion>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  versions: ExtensionVersionsList;
};

export type ExtensionVersion = {
  __typename?: 'ExtensionVersion';
  componentsBuildStage: ExtensionVersionBuildStage;
  /**
   * A JSON object with optional additional configuration that is merged with the extension's config.
   * Properties in this object override the extension's config.
   */
  config?: Maybe<Scalars['String']['output']>;
  hooks: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  serverBuildStage: ExtensionVersionBuildStage;
  uploadStage: ExtensionVersionUploadStage;
  versionName: Scalars['String']['output'];
};

export const ExtensionVersionBuildStage = {
  Building: 'Building',
  Built: 'Built',
  Canceled: 'Canceled',
  Failed: 'Failed'
} as const;

export type ExtensionVersionBuildStage = typeof ExtensionVersionBuildStage[keyof typeof ExtensionVersionBuildStage];
export const ExtensionVersionUploadStage = {
  Canceled: 'Canceled',
  Uploaded: 'Uploaded',
  Uploading: 'Uploading'
} as const;

export type ExtensionVersionUploadStage = typeof ExtensionVersionUploadStage[keyof typeof ExtensionVersionUploadStage];
export type ExtensionVersionsList = ListResult & {
  __typename?: 'ExtensionVersionsList';
  extensionVersions: Array<ExtensionVersion>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ExtensionsList = ListResult & {
  __typename?: 'ExtensionsList';
  extensions: Array<Extension>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['Date']['output'];
  downloadName: Scalars['String']['output'];
  getUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  status: FileStatus;
};

export type FileGetUrlArgs = {
  download: Scalars['Boolean']['input'];
};

export const FileStatus = {
  Canceled: 'Canceled',
  Deleted: 'Deleted',
  Uploaded: 'Uploaded',
  Uploading: 'Uploading'
} as const;

export type FileStatus = typeof FileStatus[keyof typeof FileStatus];
export type FindOrCreateStripeSubscriptionActiveResult = {
  __typename?: 'FindOrCreateStripeSubscriptionActiveResult';
  id: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
};

export type FindOrCreateStripeSubscriptionErrorResult = {
  __typename?: 'FindOrCreateStripeSubscriptionErrorResult';
  message: Scalars['String']['output'];
};

export type FindOrCreateStripeSubscriptionIncompleteResult = {
  __typename?: 'FindOrCreateStripeSubscriptionIncompleteResult';
  id: Scalars['String']['output'];
  paymentIntentClientSecret: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
};

export type FindOrCreateStripeSubscriptionResult = FindOrCreateStripeSubscriptionActiveResult | FindOrCreateStripeSubscriptionErrorResult | FindOrCreateStripeSubscriptionIncompleteResult | FindOrCreateStripeSubscriptionUpdatedResult;

export type FindOrCreateStripeSubscriptionUpdatedResult = {
  __typename?: 'FindOrCreateStripeSubscriptionUpdatedResult';
  subscriptionPlanId: Scalars['String']['output'];
};

export type Form = {
  __typename?: 'Form';
  components: Scalars['String']['output'];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: Maybe<Scalars['String']['output']>;
  confirmationRedirectParameters: Array<FormConfirmationRedirectUrlParameter>;
  confirmationRedirectUrl?: Maybe<Scalars['String']['output']>;
  emailBodyTemplate?: Maybe<Scalars['String']['output']>;
  entriesTotalCount: Scalars['Int']['output'];
  formattedDataTemplate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  steps: Array<FormStep>;
  submissionActions: Array<FormSubmissionAction>;
  title: Scalars['String']['output'];
  useEmailBodyTemplate: Scalars['Boolean']['output'];
  useFormattedDataTemplate: Scalars['Boolean']['output'];
};

export type FormConfirmationRedirectUrlParameter = {
  __typename?: 'FormConfirmationRedirectUrlParameter';
  componentName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  parameter: Scalars['String']['output'];
};

export type FormConfirmationRedirectUrlParameterInput = {
  componentName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  parameter: Scalars['String']['input'];
};

export type FormEntriesList = ListResult & {
  __typename?: 'FormEntriesList';
  entries: Array<FormEntry>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type FormEntry = {
  __typename?: 'FormEntry';
  createdAt: Scalars['Date']['output'];
  formData: Scalars['String']['output'];
  formId: Scalars['String']['output'];
  formTitle: Scalars['String']['output'];
  id: Scalars['String']['output'];
  submissionPageUrl: Scalars['String']['output'];
  user?: Maybe<UserProfile>;
};

export type FormStep = {
  __typename?: 'FormStep';
  components: Scalars['String']['output'];
  edited: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** A lexicographical sorting key. */
  position: Scalars['String']['output'];
};

export type FormSubmissionAction = {
  __typename?: 'FormSubmissionAction';
  createdAt: Scalars['Date']['output'];
  emailAddress?: Maybe<Scalars['String']['output']>;
  emailSubject?: Maybe<Scalars['String']['output']>;
  formId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  type: FormSubmissionActionType;
  webhookUrl?: Maybe<Scalars['String']['output']>;
};

export const FormSubmissionActionType = {
  Email: 'Email',
  Webhook: 'Webhook'
} as const;

export type FormSubmissionActionType = typeof FormSubmissionActionType[keyof typeof FormSubmissionActionType];
export type FormTemplate = {
  __typename?: 'FormTemplate';
  components: Scalars['String']['output'];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: Maybe<Scalars['String']['output']>;
  confirmationRedirectParameters: Array<FormConfirmationRedirectUrlParameter>;
  confirmationRedirectUrl?: Maybe<Scalars['String']['output']>;
  emailBodyTemplate?: Maybe<Scalars['String']['output']>;
  formattedDataTemplate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: PublishStatus;
  steps: Array<FormTemplateStep>;
  title: Scalars['String']['output'];
  useEmailBodyTemplate: Scalars['Boolean']['output'];
  useFormattedDataTemplate: Scalars['Boolean']['output'];
};

export type FormTemplateStep = {
  __typename?: 'FormTemplateStep';
  components: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** A lexicographical sorting key. */
  position: Scalars['String']['output'];
};

export const GCloudCertificateMapEntryState = {
  Active: 'Active',
  Pending: 'Pending',
  Unknown: 'Unknown'
} as const;

export type GCloudCertificateMapEntryState = typeof GCloudCertificateMapEntryState[keyof typeof GCloudCertificateMapEntryState];
export const GCloudCertificateState = {
  Active: 'Active',
  Failed: 'Failed',
  Provisioning: 'Provisioning',
  Unknown: 'Unknown'
} as const;

export type GCloudCertificateState = typeof GCloudCertificateState[keyof typeof GCloudCertificateState];
export type GenericInternalError = Error & {
  __typename?: 'GenericInternalError';
  message: Scalars['String']['output'];
};

export type GenericUserError = Error & {
  __typename?: 'GenericUserError';
  message: Scalars['String']['output'];
};

export type HelpTicket = {
  __typename?: 'HelpTicket';
  createdAt: Scalars['Date']['output'];
  customDomain?: Maybe<Scalars['String']['output']>;
  customSiteBodyEndCode?: Maybe<Scalars['String']['output']>;
  customSiteBodyStartCode?: Maybe<Scalars['String']['output']>;
  customSiteHeaderCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: HelpTicketStatus;
  type: Scalars['String']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
  zendeskTicketId?: Maybe<Scalars['String']['output']>;
};

export const HelpTicketStatus = {
  Closed: 'Closed',
  Open: 'Open'
} as const;

export type HelpTicketStatus = typeof HelpTicketStatus[keyof typeof HelpTicketStatus];
export type HelpTicketsList = ListResult & {
  __typename?: 'HelpTicketsList';
  helpTickets: Array<HelpTicket>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** A filter for a field representing an object’s ID. */
export type IdFilter = {
  /**
   * If operator is Equal or NotEqual, value must be provided and include exactly one value.
   * If operator is In or NotIn, value must be provided and include at least one value.
   */
  operator: IdFilterOperator;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export const IdFilterOperator = {
  Equals: 'Equals',
  In: 'In',
  NotEquals: 'NotEquals',
  NotIn: 'NotIn'
} as const;

export type IdFilterOperator = typeof IdFilterOperator[keyof typeof IdFilterOperator];
export type InitialFormData = {
  __typename?: 'InitialFormData';
  /** A JSON object. */
  formData?: Maybe<Scalars['String']['output']>;
};

export type IntUpdate = {
  value: Scalars['Int']['input'];
};

export type InvalidateApiTokenResult = GenericUserError | InvalidateApiTokenSuccessResult;

export type InvalidateApiTokenSuccessResult = {
  __typename?: 'InvalidateApiTokenSuccessResult';
  apiToken: ApiToken;
};

export const InvoiceFrequency = {
  Annually: 'Annually',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  SemiAnnually: 'SemiAnnually'
} as const;

export type InvoiceFrequency = typeof InvoiceFrequency[keyof typeof InvoiceFrequency];
export type KeyValueInput = {
  key: Scalars['String']['input'];
  /** Any JSON value. */
  value: Scalars['String']['input'];
};

export type Layout = {
  __typename?: 'Layout';
  components: Scalars['String']['output'];
  id: Scalars['String']['output'];
  layoutTemplate?: Maybe<LayoutTemplate>;
  name: Scalars['String']['output'];
  pathPrefix: Scalars['String']['output'];
  status: PublishStatus;
};

export type LayoutTemplate = {
  __typename?: 'LayoutTemplate';
  components: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pathPrefix: Scalars['String']['output'];
  status: PublishStatus;
};

export type LayoutTemplatesList = ListResult & {
  __typename?: 'LayoutTemplatesList';
  layoutTemplates: Array<LayoutTemplate>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type LayoutsList = ListResult & {
  __typename?: 'LayoutsList';
  layouts: Array<Layout>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Lesson = {
  __typename?: 'Lesson';
  completionSpec?: Maybe<Scalars['String']['output']>;
  components: Scalars['String']['output'];
  /**
   * Whether to enable lesson content when the lesson has at least one published sub-lesson.
   * This field is ignored when a lesson does not have any published sub-lessons as content is automatically enabled.
   * Even with this field true, a lesson that has sub-lessons is completed if and only if all its sub-lessons are completed.
   */
  enableContent: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lessonUser?: Maybe<LessonUser>;
  parentLesson?: Maybe<Lesson>;
  parentLessonId?: Maybe<Scalars['String']['output']>;
  /** A lexicographical sorting key. */
  position: Scalars['String']['output'];
  status: PublishStatus;
  /** The direct sub-lessons of the lesson. */
  subLessons: LessonsList;
  title: Scalars['String']['output'];
  versions: LessonVersionsList;
};

export type LessonLessonUserArgs = {
  userId: Scalars['String']['input'];
};

export type LessonSubLessonsArgs = {
  status?: InputMaybe<Array<PublishStatus>>;
};

export type LessonVersionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** This enum defines how the lessons of a course get completed. */
export const LessonCompletionMode = {
  /** All completable input fields (those visible to the user) must be completed. */
  AllCompletableFields: 'AllCompletableFields',
  /** A custom boolean expression is set per lesson. */
  CustomExpression: 'CustomExpression',
  /** A user must manually mark a lesson as completed. */
  Manual: 'Manual'
} as const;

export type LessonCompletionMode = typeof LessonCompletionMode[keyof typeof LessonCompletionMode];
export type LessonCompletionModeUpdate = {
  value: LessonCompletionMode;
};

export type LessonUser = {
  __typename?: 'LessonUser';
  completedAt?: Maybe<Scalars['Date']['output']>;
  files: Array<File>;
  id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  /** A JSON object. */
  state?: Maybe<Scalars['String']['output']>;
};

export type LessonVersion = {
  __typename?: 'LessonVersion';
  afterLesson?: Maybe<Lesson>;
  byUser: UserProfile;
  changes: Array<LessonVersionChange>;
  completionSpec?: Maybe<Scalars['String']['output']>;
  components?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  enableContent?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  lesson: Lesson;
  parentLesson?: Maybe<Lesson>;
  status?: Maybe<PublishStatus>;
  title?: Maybe<Scalars['String']['output']>;
};

export const LessonVersionChange = {
  CompletionSpec: 'CompletionSpec',
  Components: 'Components',
  EnableContent: 'EnableContent',
  Location: 'Location',
  Status: 'Status',
  Title: 'Title'
} as const;

export type LessonVersionChange = typeof LessonVersionChange[keyof typeof LessonVersionChange];
export type LessonVersionsList = ListResult & {
  __typename?: 'LessonVersionsList';
  lessonVersions: Array<LessonVersion>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type LessonsList = ListResult & {
  __typename?: 'LessonsList';
  lessons: Array<Lesson>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ListResult = {
  limit: Scalars['Int']['output'];
  /** The total number of items in the list. */
  totalCount: Scalars['Int']['output'];
};

export type Me = AdminUser | User;

export type MoveUserToOrgResult = GenericUserError | MoveUserToOrgSuccessResult;

export type MoveUserToOrgSuccessResult = {
  __typename?: 'MoveUserToOrgSuccessResult';
  sourceOrg: Organization;
  targetOrg: Organization;
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveComponent: ArchiveComponentResult;
  changePassword: Scalars['Boolean']['output'];
  consentToAgreement: User;
  /**
   * Creates a platform admin user. If a user with the specified email address already exists, the user is added as an admin.
   * Requires the requesting user to be an owner of the platform.
   */
  createAdminUser: CreateAdminUserResult;
  createApiToken: CreateApiTokenResult;
  createComponent: CreateComponentResult;
  createCourse: CreateCourseResult;
  createDocument: Document;
  createDocumentDownloadPrompt: DocumentDownloadPrompt;
  createExtension: CreateExtensionResult;
  createExtensionVersion: CreateExtensionVersionResult;
  createForm: Form;
  createFormStep: FormStep;
  createFormSubmissionAction: FormSubmissionAction;
  createFormTemplate: FormTemplate;
  createFormTemplateStep: FormTemplateStep;
  createHelpTicket: HelpTicket;
  createLayout: CreateLayoutResult;
  createLayoutTemplate: CreateLayoutTemplateResult;
  createLesson: CreateLessonResult;
  createLessonUser: CreateLessonUserResult;
  createPage: Page;
  createPageFromTemplate: Page;
  createPageTemplate: PageTemplate;
  createProduct: CreateProductResult;
  createSequence: CreateSequenceResult;
  createSite: CreateSiteResult;
  createSiteTemplate: CreateSiteTemplateResult;
  createSiteTemplateLayoutTemplates: CreateSiteTemplateLayoutTemplatesResult;
  createSiteTemplatePageTemplates: CreateSiteTemplatePageTemplatesResult;
  createStage: Stage;
  createStageBlueprint: StageBlueprint;
  createSubscription: SubscriptionOrganization;
  createSubscriptionOrg: SubscriptionOrganization;
  createTlsCertificate: TlsCertificate;
  createTlsCertificateMapEntry: TlsCertificate;
  /**
   * To create a user in an org that belongs to one's platform, the requester must have PermissionName.CreateUser.
   * The request may specify the role (assuming the requester has PermissionName.EditUserRole) and status (assuming the
   * requester has PermissionName.EditUserStatus) for the new user
   *
   * When adding a user to one's own org, the requester must have the OrganizationOwner role.
   * TODO: Change that to be configurable.
   * Neither a role nor a status may be specified in this case.
   */
  createUser: User;
  /**
   * For platform admins, the CreateUser permission is required. To set the status to something other than the
   * default (Pending), the EditUserStatus permission is required. To set the role to something other than the
   * default, the EditUserRole permission is required.
   *
   * For non-admins, this mutation creates a peer org member, so orgId must not be provided or must be the requesting
   * user’s own org ID. The status field is ignored and the default is used.
   * Currently the requester needs to have the OrganizationOwner role, but that will become configurable.
   */
  createUser2: CreateUserResult;
  deleteExtensionVersion: DeleteExtensionVersionResult;
  deleteFormStep: Scalars['Boolean']['output'];
  deleteFormSubmissionAction: Scalars['Boolean']['output'];
  deleteFormTemplateStep: Scalars['Boolean']['output'];
  deleteSiteTemplateLayoutTemplates: DeleteSiteTemplateLayoutTemplatesResult;
  deleteSiteTemplatePageTemplates: DeleteSiteTemplatePageTemplatesResult;
  deleteStageBlueprint: Scalars['Boolean']['output'];
  deleteSubscriptionOrg: Scalars['Boolean']['output'];
  deleteSubscriptionPlan: Scalars['Boolean']['output'];
  deleteTlsCertificate: Scalars['Boolean']['output'];
  duplicateComponent: DuplicateComponentResult;
  duplicateCourse: DuplicateCourseResult;
  duplicateForm: Form;
  duplicateFormTemplate: FormTemplate;
  duplicateLesson: DuplicateLessonResult;
  duplicatePage: Page;
  duplicatePageTemplate: PageTemplate;
  duplicateSequence: DuplicateSequenceResult;
  duplicateStageBlueprint: StageBlueprint;
  editComponent: EditComponentResult;
  editCourse: EditCourseResult;
  /** Edit the custom table (i.e., custom fields) of a specific target object. */
  editCustomTable: EditCustomTableResult;
  editDocument: Document;
  editDocumentCurrentVersion: Document;
  editDocumentDownloadPrompt: DocumentDownloadPrompt;
  editExtension: EditExtensionResult;
  editFile: File;
  editForm: Form;
  editFormStep: FormStep;
  editFormStepPosition: EditFormStepPositionResult;
  editFormSubmissionAction: FormSubmissionAction;
  editFormTemplate: FormTemplate;
  editFormTemplateConfirmation: FormTemplate;
  editFormTemplateEmailTemplate: EditFormTemplateEmailTemplateResult;
  editFormTemplateFormattedDataTemplate: EditFormTemplateFormattedDataTemplateResult;
  editFormTemplateStep: FormTemplateStep;
  editFormTemplateStepPosition: FormTemplateStep;
  editHelpTicketStatus: HelpTicket;
  editLayout: EditLayoutResult;
  editLayoutTemplate: EditLayoutTemplateResult;
  editLesson: EditLessonResult;
  editLessonUser: EditLessonUserResult;
  editOrgPrimaryUser: EditOrgPrimaryUserResult;
  editOrganization: Organization;
  editPage: EditPageResult;
  editPage2: EditPageResult;
  editPageMetadata: Page;
  editPageProps: Page;
  editPageStatus: Page;
  editPageTemplate: EditPageTemplateResult;
  editPlatform: EditPlatformResult;
  editPlatformAdminUser: EditPlatformAdminUserResult;
  editPlatformOptions: Platform;
  editProduct: EditProductResult;
  editScoreMyCallEntryStatus?: Maybe<ScoreMyCallEntry>;
  editSequence: EditSequenceResult;
  editSequenceUser: EditSequenceUserResult;
  editSite: EditSiteResult;
  editStageBlueprintCompletionSpec: StageBlueprint;
  editStageBlueprintComponents: StageBlueprint;
  editStageBlueprintMetaData: StageBlueprint;
  editStageState: Scalars['Boolean']['output'];
  editSubscription: Sub;
  editSubscriptionOrg: SubscriptionOrganization;
  editUser: EditUserResult;
  editUserEmail: User;
  editUserName: User;
  editUserPlan: UserPlan;
  editUserRole: User;
  editUserStatus: User;
  findOrCreateStripeCustomer: StripeCustomer;
  findOrCreateStripeSubscription: FindOrCreateStripeSubscriptionResult;
  invalidateApiToken: InvalidateApiTokenResult;
  moveUserToOrg: MoveUserToOrgResult;
  refreshSession: Scalars['Boolean']['output'];
  requestPasswordReset: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  restoreCourseVersionChanges: RestoreCourseVersionChangesResult;
  restoreLessonVersionChanges: RestoreLessonVersionChangesResult;
  restorePageVersionChanges: RestorePageVersionChangesResult;
  sendVerificationEmail: Scalars['Boolean']['output'];
  signIn: User;
  signOut: Scalars['Boolean']['output'];
  splitUserToNewOrg: SplitUserToNewOrgResult;
  submitForm: SubmitFormResult;
  submitReview: Scalars['Boolean']['output'];
  submitScoreMyCallEntry: SubmitScoreMyCallEntryResult;
  syncFormTemplateToForms: Scalars['Boolean']['output'];
  /** Sync a layout template to the layouts that were created from the template. Only draft and published layouts are synced. */
  syncLayoutTemplateToLayouts: SyncLayoutTemplateToLayoutsResult;
  /** Sync a page template to the pages that were created from the template. Only draft and published pages are synced. */
  syncPageTemplateToPages: SyncPageTemplateToPagesResult;
  uploadFile: UploadFileResult;
  verifyEmailAddress: User;
};

export type MutationArchiveComponentArgs = {
  id: Scalars['String']['input'];
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type MutationConsentToAgreementArgs = {
  agreementId: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationCreateAdminUserArgs = {
  input: CreateAdminUserInput;
};

export type MutationCreateApiTokenArgs = {
  input: CreateApiTokenInput;
};

export type MutationCreateComponentArgs = {
  input: CreateComponentInput;
};

export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};

export type MutationCreateDocumentArgs = {
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type MutationCreateDocumentDownloadPromptArgs = {
  acceptLabel: Scalars['String']['input'];
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
  prompt: RichTextInput;
  promptHeader: Scalars['String']['input'];
};

export type MutationCreateExtensionArgs = {
  input: CreateExtensionInput;
};

export type MutationCreateExtensionVersionArgs = {
  input: CreateExtensionVersionInput;
};

export type MutationCreateFormArgs = {
  siteId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreateFormStepArgs = {
  formId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationCreateFormSubmissionActionArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  formId: Scalars['String']['input'];
  type: FormSubmissionActionType;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateFormTemplateArgs = {
  platformId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreateFormTemplateStepArgs = {
  formTemplateId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationCreateHelpTicketArgs = {
  customDomain?: InputMaybe<Scalars['String']['input']>;
  customSiteBodyEndCode?: InputMaybe<Scalars['String']['input']>;
  customSiteBodyStartCode?: InputMaybe<Scalars['String']['input']>;
  customSiteHeaderCode?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type MutationCreateLayoutArgs = {
  input: CreateLayoutInput;
};

export type MutationCreateLayoutTemplateArgs = {
  input: CreateLayoutTemplateInput;
};

export type MutationCreateLessonArgs = {
  input: CreateLessonInput;
};

export type MutationCreateLessonUserArgs = {
  input: CreateLessonUserInput;
};

export type MutationCreatePageArgs = {
  path: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreatePageFromTemplateArgs = {
  props?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};

export type MutationCreatePageTemplateArgs = {
  path: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreateProductArgs = {
  alternativeStripePriceIds: Array<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  durationDays?: InputMaybe<Scalars['Int']['input']>;
  endAt?: InputMaybe<Scalars['Date']['input']>;
  invoiceFrequency?: InputMaybe<InvoiceFrequency>;
  items: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
  price: Scalars['String']['input'];
  promoCodes: Array<PromoCodeInput>;
  stripePriceId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateSequenceArgs = {
  input: CreateSequenceInput;
};

export type MutationCreateSiteArgs = {
  input: CreateSiteInput;
};

export type MutationCreateSiteTemplateArgs = {
  input: CreateSiteTemplateInput;
};

export type MutationCreateSiteTemplateLayoutTemplatesArgs = {
  input: SiteTemplateLayoutTemplatesInput;
};

export type MutationCreateSiteTemplatePageTemplatesArgs = {
  input: SiteTemplatePageTemplatesInput;
};

export type MutationCreateStageArgs = {
  date: Scalars['String']['input'];
  stageBlueprintId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
  userTimeZone: Scalars['String']['input'];
};

export type MutationCreateStageBlueprintArgs = {
  name: Scalars['String']['input'];
  planId: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

export type MutationCreateSubscriptionArgs = {
  notes: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  startAt: Scalars['Date']['input'];
  status: SubscriptionStatus;
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlanId: Scalars['String']['input'];
};

export type MutationCreateSubscriptionOrgArgs = {
  orgId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
  subscriptionOwner: Scalars['Boolean']['input'];
};

export type MutationCreateTlsCertificateArgs = {
  siteId: Scalars['String']['input'];
};

export type MutationCreateTlsCertificateMapEntryArgs = {
  certificateName: Scalars['String']['input'];
  domain: Scalars['String']['input'];
};

export type MutationCreateUserArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  platformId: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
};

export type MutationCreateUser2Args = {
  input: CreateUserInput;
};

export type MutationDeleteExtensionVersionArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFormStepArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFormSubmissionActionArgs = {
  submissionActionId: Scalars['String']['input'];
};

export type MutationDeleteFormTemplateStepArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteSiteTemplateLayoutTemplatesArgs = {
  input: SiteTemplateLayoutTemplatesInput;
};

export type MutationDeleteSiteTemplatePageTemplatesArgs = {
  input: SiteTemplatePageTemplatesInput;
};

export type MutationDeleteStageBlueprintArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteSubscriptionOrgArgs = {
  id: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type MutationDeleteSubscriptionPlanArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteTlsCertificateArgs = {
  certificateName: Scalars['String']['input'];
};

export type MutationDuplicateComponentArgs = {
  input: DuplicateComponentInput;
};

export type MutationDuplicateCourseArgs = {
  input: DuplicateCourseInput;
};

export type MutationDuplicateFormArgs = {
  id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationDuplicateFormTemplateArgs = {
  templateId: Scalars['String']['input'];
};

export type MutationDuplicateLessonArgs = {
  input: DuplicateLessonInput;
};

export type MutationDuplicatePageArgs = {
  pageId: Scalars['String']['input'];
};

export type MutationDuplicatePageTemplateArgs = {
  id: Scalars['String']['input'];
};

export type MutationDuplicateSequenceArgs = {
  id: Scalars['String']['input'];
};

export type MutationDuplicateStageBlueprintArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

export type MutationEditComponentArgs = {
  input: EditComponentInput;
};

export type MutationEditCourseArgs = {
  input: EditCourseInput;
};

export type MutationEditCustomTableArgs = {
  input: EditCustomTableInput;
};

export type MutationEditDocumentArgs = {
  downloadPromptId?: InputMaybe<Scalars['String']['input']>;
  featureTag?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productItem?: InputMaybe<OptionalStringUpdate>;
  roles: Array<Scalars['String']['input']>;
  stamp: Scalars['Boolean']['input'];
  status: PublishStatus;
};

export type MutationEditDocumentCurrentVersionArgs = {
  fileId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditDocumentDownloadPromptArgs = {
  acceptLabel: Scalars['String']['input'];
  default: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  prompt: RichTextInput;
  promptHeader: Scalars['String']['input'];
};

export type MutationEditExtensionArgs = {
  input: EditExtensionInput;
};

export type MutationEditFileArgs = {
  downloadName: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditFormArgs = {
  components: Scalars['String']['input'];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: InputMaybe<Scalars['String']['input']>;
  confirmationRedirectUrl?: InputMaybe<Scalars['String']['input']>;
  confirmationRedirectUrlParameters: Array<FormConfirmationRedirectUrlParameterInput>;
  id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationEditFormStepArgs = {
  components: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationEditFormStepPositionArgs = {
  id: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationEditFormSubmissionActionArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  submissionActionId: Scalars['String']['input'];
  type: FormSubmissionActionType;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditFormTemplateArgs = {
  components: Scalars['String']['input'];
  id: Scalars['String']['input'];
  status: PublishStatus;
  title: Scalars['String']['input'];
};

export type MutationEditFormTemplateConfirmationArgs = {
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: InputMaybe<Scalars['String']['input']>;
  confirmationRedirectUrl?: InputMaybe<Scalars['String']['input']>;
  confirmationRedirectUrlParameters: Array<FormConfirmationRedirectUrlParameterInput>;
  id: Scalars['String']['input'];
};

export type MutationEditFormTemplateEmailTemplateArgs = {
  input: EditFormTemplateEmailTemplateInput;
};

export type MutationEditFormTemplateFormattedDataTemplateArgs = {
  input: EditFormTemplateFormattedDataTemplateInput;
};

export type MutationEditFormTemplateStepArgs = {
  components: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationEditFormTemplateStepPositionArgs = {
  id: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationEditHelpTicketStatusArgs = {
  id: Scalars['String']['input'];
  status: HelpTicketStatus;
};

export type MutationEditLayoutArgs = {
  input: EditLayoutInput;
};

export type MutationEditLayoutTemplateArgs = {
  input: EditLayoutTemplateInput;
};

export type MutationEditLessonArgs = {
  input: EditLessonInput;
};

export type MutationEditLessonUserArgs = {
  input: EditLessonUserInput;
};

export type MutationEditOrgPrimaryUserArgs = {
  input: EditOrgPrimaryUserInput;
};

export type MutationEditOrganizationArgs = {
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type MutationEditPageArgs = {
  input: EditPageInput;
};

export type MutationEditPage2Args = {
  input: EditPageInput;
};

export type MutationEditPageMetadataArgs = {
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  pageId: Scalars['String']['input'];
  path: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationEditPagePropsArgs = {
  pageId: Scalars['String']['input'];
  props: Scalars['String']['input'];
};

export type MutationEditPageStatusArgs = {
  pageId: Scalars['String']['input'];
  status: PublishStatus;
};

export type MutationEditPageTemplateArgs = {
  input: EditPageTemplateInput;
};

export type MutationEditPlatformArgs = {
  input: EditPlatformInput;
};

export type MutationEditPlatformAdminUserArgs = {
  input: EditPlatformAdminUserInput;
};

export type MutationEditPlatformOptionsArgs = {
  options: Array<KeyValueInput>;
  platformId: Scalars['String']['input'];
};

export type MutationEditProductArgs = {
  alternativeStripePriceIds: Array<Scalars['String']['input']>;
  description?: InputMaybe<OptionalStringUpdate>;
  displayName?: InputMaybe<OptionalStringUpdate>;
  durationDays?: InputMaybe<Scalars['Int']['input']>;
  endAt?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  invoiceFrequency?: InputMaybe<InvoiceFrequency>;
  items?: InputMaybe<StringListUpdate>;
  name: Scalars['String']['input'];
  price: Scalars['String']['input'];
  promoCodes: Array<PromoCodeInput>;
  stripePriceId?: InputMaybe<OptionalStringUpdate>;
};

export type MutationEditScoreMyCallEntryStatusArgs = {
  id: Scalars['String']['input'];
  status: ScoreMyCallEntryStatus;
};

export type MutationEditSequenceArgs = {
  id: Scalars['String']['input'];
  loopStages: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type MutationEditSequenceUserArgs = {
  input: EditSequenceUserInput;
};

export type MutationEditSiteArgs = {
  input: EditSiteInput;
};

export type MutationEditStageBlueprintCompletionSpecArgs = {
  completionSpec: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditStageBlueprintComponentsArgs = {
  components: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditStageBlueprintMetaDataArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  restoreDeleted: Scalars['Boolean']['input'];
};

export type MutationEditStageStateArgs = {
  id: Scalars['String']['input'];
  property: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MutationEditSubscriptionArgs = {
  endAt?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  invoiceFrequency?: InputMaybe<InvoiceFrequency>;
  notes: Scalars['String']['input'];
  price: Scalars['String']['input'];
  startAt: Scalars['Date']['input'];
  status: SubscriptionStatus;
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditSubscriptionOrgArgs = {
  id: Scalars['String']['input'];
  subscriptionOwner?: InputMaybe<BooleanUpdate>;
};

export type MutationEditUserArgs = {
  input: EditUserInput;
};

export type MutationEditUserEmailArgs = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditUserNameArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type MutationEditUserPlanArgs = {
  sequenceId: Scalars['String']['input'];
  stageBlueprintId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationEditUserRoleArgs = {
  role: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationEditUserStatusArgs = {
  status: UserStatus;
  userId: Scalars['String']['input'];
};

export type MutationFindOrCreateStripeCustomerArgs = {
  address?: InputMaybe<AddressInput>;
};

export type MutationFindOrCreateStripeSubscriptionArgs = {
  address: AddressInput;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
  promoCodeType?: InputMaybe<PromoCodeType>;
  subscriptionPlanId: Scalars['String']['input'];
};

export type MutationInvalidateApiTokenArgs = {
  id: Scalars['String']['input'];
};

export type MutationMoveUserToOrgArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  key: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationRestoreCourseVersionChangesArgs = {
  input: RestoreCourseVersionChangesInput;
};

export type MutationRestoreLessonVersionChangesArgs = {
  input: RestoreLessonVersionChangesInput;
};

export type MutationRestorePageVersionChangesArgs = {
  input: RestorePageVersionChangesInput;
};

export type MutationSignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationSplitUserToNewOrgArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type MutationSubmitFormArgs = {
  formData: Scalars['String']['input'];
  formId: Scalars['String']['input'];
  pageUrl: Scalars['String']['input'];
  sessionId?: InputMaybe<Scalars['String']['input']>;
  stepId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSubmitReviewArgs = {
  review: Scalars['String']['input'];
  starRating: Scalars['Int']['input'];
  submitterName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSubmitScoreMyCallEntryArgs = {
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  formData: Scalars['String']['input'];
  prospectName: Scalars['String']['input'];
  shareRecording: Scalars['Boolean']['input'];
};

export type MutationSyncFormTemplateToFormsArgs = {
  formTemplateId: Scalars['String']['input'];
};

export type MutationSyncLayoutTemplateToLayoutsArgs = {
  input: SyncLayoutTemplateToLayoutsInput;
};

export type MutationSyncPageTemplateToPagesArgs = {
  input: SyncPageTemplateToPagesInput;
};

export type MutationUploadFileArgs = {
  input: UploadFileInput;
};

export type MutationVerifyEmailAddressArgs = {
  key: Scalars['String']['input'];
};

/**
 * An input type for optionally updating an array of components. Although the interface is the same as that
 * of OptionalStringUpdate, semantically this is different and is parsed differently.
 */
export type OptionalComponentsUpdate = {
  /** The new value. Must be a valid JSON array if set. A null value is taken as clearing the field. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type OptionalDateUpdate = {
  value?: InputMaybe<Scalars['Date']['input']>;
};

/**
 * An input type for optionally updating an expression data object. Although the interface is the same as that
 * of OptionalStringUpdate, semantically this is different and is parsed differently.
 */
export type OptionalExpressionDataUpdate = {
  /** The new value. Must be a valid JSON object if set. A null value is taken as clearing the field. */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** A filter for an optional field representing an object’s ID. */
export type OptionalIdFilter = {
  /**
   * If operator is Exists or NotExists, value is ignored and must not be provided.
   * If operator is Equal or NotEqual, value must be provided and include exactly one value.
   * If operator is In or NotIn, value must be provided and include at least one value.
   */
  operator: OptionalIdFilterOperator;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export const OptionalIdFilterOperator = {
  Equals: 'Equals',
  Exists: 'Exists',
  In: 'In',
  NotEquals: 'NotEquals',
  NotExists: 'NotExists',
  NotIn: 'NotIn'
} as const;

export type OptionalIdFilterOperator = typeof OptionalIdFilterOperator[keyof typeof OptionalIdFilterOperator];
/**
 * A filter for an optional string field. Either exists or filter must be provided but not both.
 * A value exists if it is not null. An empty string is considered to exist.
 * Providing a filter assumes exists is true.
 */
export type OptionalStringFilter = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<StringFilter>;
};

/**
 * A container for an input field representing an optional (i.e., nullable) string that may optionally
 * be updated. If an object of this type is provided, it represents either a new value or clears the field.
 */
export type OptionalStringUpdate = {
  /** The new value. If this is null or not provided, it is taken as clearing the field. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String']['output'];
  members: Array<User>;
  membersCount: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  primaryUser?: Maybe<User>;
  site?: Maybe<Site>;
  subscriptionOrganizations: Array<SubscriptionOrganization>;
};

export type OrganizationMembersArgs = {
  status?: InputMaybe<Array<UserStatus>>;
};

/** A subtype of Org to limit the exposed fields. */
export type OrganizationProfile = {
  __typename?: 'OrganizationProfile';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  primaryUser?: Maybe<UserProfile>;
};

export type OrganizationsList = ListResult & {
  __typename?: 'OrganizationsList';
  limit: Scalars['Int']['output'];
  organizations: Array<Organization>;
  totalCount: Scalars['Int']['output'];
};

export type Page = {
  __typename?: 'Page';
  bodyStyles: Scalars['String']['output'];
  components: Scalars['String']['output'];
  /**
   * The fields that have been edited since the last time the page was synced with its template.
   * This field is set for every page, even if a page does not come from a page template.
   */
  editsToPageTemplate: Array<PageTemplateSyncedField>;
  id: Scalars['String']['output'];
  layout?: Maybe<Layout>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaRobots: Array<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  pageTemplate?: Maybe<PageTemplate>;
  pageTemplateId?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  productItem?: Maybe<Scalars['String']['output']>;
  props?: Maybe<Scalars['String']['output']>;
  public: Scalars['Boolean']['output'];
  /** Either the same as layout if a layout is set, or the automatically matching layout based on path. */
  resolvedLayout?: Maybe<Layout>;
  roles: Array<Scalars['String']['output']>;
  status: PublishStatus;
  title: Scalars['String']['output'];
  versions: PageVersionsList;
};

export type PageVersionsArgs = {
  byUserId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type PageTemplate = {
  __typename?: 'PageTemplate';
  activatedAsPages: Array<Page>;
  bodyStyles: Scalars['String']['output'];
  components: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metaTitle?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  previewImageUrl?: Maybe<Scalars['String']['output']>;
  previewProps?: Maybe<Scalars['String']['output']>;
  propsSetupComponents?: Maybe<Scalars['String']['output']>;
  status: PublishStatus;
  title: Scalars['String']['output'];
};

export type PageTemplateActivatedAsPagesArgs = {
  siteId: Scalars['String']['input'];
};

/** A field of a page that is synced from page templates. A subset of PageVersionChange. */
export const PageTemplateSyncedField = {
  BodyStyles: 'BodyStyles',
  Components: 'Components',
  MetaDescription: 'MetaDescription',
  MetaImageUrl: 'MetaImageUrl',
  MetaRobots: 'MetaRobots',
  MetaTitle: 'MetaTitle',
  Path: 'Path',
  Title: 'Title'
} as const;

export type PageTemplateSyncedField = typeof PageTemplateSyncedField[keyof typeof PageTemplateSyncedField];
export type PageTemplatesList = ListResult & {
  __typename?: 'PageTemplatesList';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  pageTemplates: Array<PageTemplate>;
  totalCount: Scalars['Int']['output'];
};

export type PageVersion = {
  __typename?: 'PageVersion';
  bodyStyles?: Maybe<Scalars['String']['output']>;
  byUser: UserProfile;
  changes: Array<PageVersionChange>;
  components?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  layout?: Maybe<Layout>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaImageUrl?: Maybe<Scalars['String']['output']>;
  metaRobots?: Maybe<Array<Scalars['String']['output']>>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  pageTemplate?: Maybe<PageTemplate>;
  path?: Maybe<Scalars['String']['output']>;
  productItem?: Maybe<Scalars['String']['output']>;
  props?: Maybe<Scalars['String']['output']>;
  public?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<PublishStatus>;
  title?: Maybe<Scalars['String']['output']>;
};

export const PageVersionChange = {
  BodyStyles: 'BodyStyles',
  Components: 'Components',
  CustomFields: 'CustomFields',
  LayoutId: 'LayoutId',
  MetaDescription: 'MetaDescription',
  MetaImageUrl: 'MetaImageUrl',
  MetaRobots: 'MetaRobots',
  MetaTitle: 'MetaTitle',
  PageTemplateId: 'PageTemplateId',
  Path: 'Path',
  ProductItem: 'ProductItem',
  Props: 'Props',
  Public: 'Public',
  Roles: 'Roles',
  Status: 'Status',
  Title: 'Title'
} as const;

export type PageVersionChange = typeof PageVersionChange[keyof typeof PageVersionChange];
export type PageVersionsList = ListResult & {
  __typename?: 'PageVersionsList';
  limit: Scalars['Int']['output'];
  pageVersions: Array<PageVersion>;
  totalCount: Scalars['Int']['output'];
};

export type PagesList = ListResult & {
  __typename?: 'PagesList';
  limit: Scalars['Int']['output'];
  pages: Array<Page>;
  totalCount: Scalars['Int']['output'];
};

export type PasswordResetInfo = {
  __typename?: 'PasswordResetInfo';
  name: Scalars['String']['output'];
};

export const PasswordStatus = {
  MigratedWP: 'MigratedWP',
  None: 'None',
  Ok: 'Ok',
  Temporary: 'Temporary'
} as const;

export type PasswordStatus = typeof PasswordStatus[keyof typeof PasswordStatus];
/** Temporary for the rename. */
export const PermissionName = {
  /** Create (i.e., add) extensions. */
  CreateExtension: 'CreateExtension',
  /** Create extension versions for existing extensions. This includes deploying new code. */
  CreateExtensionVersion: 'CreateExtensionVersion',
  /** Create sites. */
  CreateSite: 'CreateSite',
  /** Create page and form templates. */
  CreateTemplate: 'CreateTemplate',
  /** Create a user in any org. */
  CreateUser: 'CreateUser',
  /** Edit or manage everything else not covered by other permissions. */
  Edit: 'Edit',
  /** Edit, including creating and deleting, any component. */
  EditComponent: 'EditComponent',
  /** Edit, including creating and deleting, any course. */
  EditCourse: 'EditCourse',
  /** Edit, including creating and deleting, any custom component. */
  EditCustomComponent: 'EditCustomComponent',
  /** Edit, including creating and deleting, any custom field. */
  EditCustomField: 'EditCustomField',
  /** Edit any customer site, including its pages and forms, but not necessarily domain. */
  EditCustomerSite: 'EditCustomerSite',
  /** Edit any extension. This does not include creating extension versions (i.e., deploying new code). */
  EditExtension: 'EditExtension',
  /** Edit any help ticket's status. */
  EditHelpTicketStatus: 'EditHelpTicketStatus',
  /** Edit any platform site, including its pages and forms, but not necessarily domain. */
  EditPlatformSite: 'EditPlatformSite',
  /** Edit, including creating and deleting, any sequence. */
  EditSequence: 'EditSequence',
  /** Edit any user's position in a sequence. */
  EditSequenceUser: 'EditSequenceUser',
  /** Edit any site's settings, pages, forms, and layouts. */
  EditSite: 'EditSite',
  /** Edit any site's domain. */
  EditSiteDomain: 'EditSiteDomain',
  /** Edit any site's TLS certificate, including creating and deleting certificates. Does not including editing TLS certificates. */
  EditSiteTlsCertificate: 'EditSiteTlsCertificate',
  /** Edit any subscription. */
  EditSubscription: 'EditSubscription',
  /** Edit any page and form template. */
  EditTemplate: 'EditTemplate',
  /** Edit any user's email address. */
  EditUserEmail: 'EditUserEmail',
  /** Edit which org any user is in and whether a user is an org owner. */
  EditUserOrg: 'EditUserOrg',
  /** Edit any user's first and last name. */
  EditUserProfile: 'EditUserProfile',
  /** Edit any user's role. */
  EditUserRole: 'EditUserRole',
  /** Edit any user's status. */
  EditUserStatus: 'EditUserStatus',
  /** Sync any form template to forms. */
  SyncFormTemplateToForms: 'SyncFormTemplateToForms',
  /** Sync any page template to pages. */
  SyncPageTemplateToPages: 'SyncPageTemplateToPages',
  /** View anything except for sites. */
  View: 'View',
  /** View any course. */
  ViewCourse: 'ViewCourse',
  /** View any extension. */
  ViewExtension: 'ViewExtension',
  /** View any site, including pages, forms, and layouts, and components. */
  ViewSite: 'ViewSite',
  /** View any page or form template. */
  ViewTemplate: 'ViewTemplate'
} as const;

export type PermissionName = typeof PermissionName[keyof typeof PermissionName];
export type PermissionNamesListUpdate = {
  value: Array<PermissionName>;
};

export type Platform = {
  __typename?: 'Platform';
  activeCampaignApi?: Maybe<ActiveCampaignApi>;
  activeCampaignApiToken?: Maybe<Scalars['String']['output']>;
  activeCampaignApiUrl?: Maybe<Scalars['String']['output']>;
  activeCampaignTagsSyncConfig?: Maybe<Array<ActiveCampaignTagsSyncConfig>>;
  admins: PlatformAdminUsersList;
  defaultFormFormattedDataTemplate: Scalars['String']['output'];
  defaultFormNotificationEmailBodyTemplate: Scalars['String']['output'];
  emailReplyToAddress?: Maybe<Scalars['String']['output']>;
  emailSendFromAddress: Scalars['String']['output'];
  enableScoreMyCall: Scalars['Boolean']['output'];
  handle: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mainSiteId?: Maybe<Scalars['String']['output']>;
  mixpanelToken?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sendGridApiKey?: Maybe<Scalars['String']['output']>;
  stripeSecretKey?: Maybe<Scalars['String']['output']>;
  stripeWebhookSigningSecret?: Maybe<Scalars['String']['output']>;
  webhookHelpTicketCreatedUrl?: Maybe<Scalars['String']['output']>;
  webhookOrganizationPeerUserCreatedUrl?: Maybe<Scalars['String']['output']>;
  webhookRegisterUserSecret?: Maybe<Scalars['String']['output']>;
  webhookSubscriptionActivatedUrl?: Maybe<Scalars['String']['output']>;
  zendeskApiToken?: Maybe<Scalars['String']['output']>;
  zendeskHelpTicketTags?: Maybe<Array<Scalars['String']['output']>>;
  zendeskSubdomain?: Maybe<Scalars['String']['output']>;
  zendeskUserAuthJwtKey?: Maybe<Scalars['String']['output']>;
  zendeskUserAuthJwtKeyId?: Maybe<Scalars['String']['output']>;
  zendeskUsername?: Maybe<Scalars['String']['output']>;
  zendeskWebhookSigningSecret?: Maybe<Scalars['String']['output']>;
};

/** An object linking a platform and an admin user. */
export type PlatformAdminUser = {
  __typename?: 'PlatformAdminUser';
  active: Scalars['Boolean']['output'];
  adminUser: AdminUser;
  id: Scalars['String']['output'];
  owner: Scalars['Boolean']['output'];
  permissions: Array<PlatformAdminUserPermission>;
  platform: Platform;
};

export type PlatformAdminUserPermission = {
  __typename?: 'PlatformAdminUserPermission';
  id: Scalars['String']['output'];
  permission: PermissionName;
};

export type PlatformAdminUsersList = ListResult & {
  __typename?: 'PlatformAdminUsersList';
  limit: Scalars['Int']['output'];
  platformAdminUsers: Array<PlatformAdminUser>;
  totalCount: Scalars['Int']['output'];
};

export type PreviewStripeCoupon = {
  __typename?: 'PreviewStripeCoupon';
  amountOff?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  duration: StripeCouponDuration;
  /** The number of months. Only applies of duration is repeating. */
  durationMonths?: Maybe<Scalars['Int']['output']>;
  /** The percentage off the price of the plan (0-100). */
  percentOff?: Maybe<Scalars['Int']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type PreviewStripePromoCode = {
  __typename?: 'PreviewStripePromoCode';
  coupon: PreviewStripeCoupon;
  valid: Scalars['Boolean']['output'];
};

export type PreviewStripeUpcomingInvoiceErrorResult = {
  __typename?: 'PreviewStripeUpcomingInvoiceErrorResult';
  message: Scalars['String']['output'];
};

export type PreviewStripeUpcomingInvoiceResult = PreviewStripeUpcomingInvoiceErrorResult | PreviewStripeUpcomingInvoiceSuccessResult;

export type PreviewStripeUpcomingInvoiceSuccessResult = {
  __typename?: 'PreviewStripeUpcomingInvoiceSuccessResult';
  currency: Scalars['String']['output'];
  promoCode?: Maybe<PreviewStripePromoCode>;
  subscriptionPlanId: Scalars['String']['output'];
  /** Total of the invoice before any invoice-level discount or exclusive tax is applied. Formatted in the invoice's currency. */
  subtotal: Scalars['String']['output'];
  /** Total after discounts and taxes. Formatted in the invoice's currency. */
  total: Scalars['String']['output'];
};

export type ProductItemOccurrences = {
  __typename?: 'ProductItemOccurrences';
  documents: DocumentsList;
  pages: PagesList;
};

export type ProductItemOccurrencesDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductItemOccurrencesPagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductItemPicklist = ListResult & {
  __typename?: 'ProductItemPicklist';
  limit: Scalars['Int']['output'];
  productItems: Array<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type ProductsList = ListResult & {
  __typename?: 'ProductsList';
  limit: Scalars['Int']['output'];
  products: Array<SubscriptionPlan>;
  totalCount: Scalars['Int']['output'];
};

export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  successMessage?: Maybe<Delta>;
};

export type PromoCodeInput = {
  code: Scalars['String']['input'];
  id: Scalars['String']['input'];
  successMessage?: InputMaybe<RichTextInput>;
};

export type PromoCodePicklist = ListResult & {
  __typename?: 'PromoCodePicklist';
  limit: Scalars['Int']['output'];
  promoCodes: Array<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export const PromoCodeType = {
  Custom: 'Custom',
  Stripe: 'Stripe'
} as const;

export type PromoCodeType = typeof PromoCodeType[keyof typeof PromoCodeType];
export type PublicSite = {
  __typename?: 'PublicSite';
  addressCity?: Maybe<Scalars['String']['output']>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  addressZip?: Maybe<Scalars['String']['output']>;
  agencyPrimaryPhone?: Maybe<Scalars['String']['output']>;
  agentFirstName?: Maybe<Scalars['String']['output']>;
  agentLastName?: Maybe<Scalars['String']['output']>;
  agentPhotoUrl?: Maybe<Scalars['String']['output']>;
  custom: CustomFieldValuesList;
  domain: Scalars['String']['output'];
  form: PublicSiteForm;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latestAgreement?: Maybe<Agreement>;
  /** The layout with the longest pathPrefix that matches the specified path. */
  layoutForPath?: Maybe<Layout>;
  logoIconUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** Get a published page by path. */
  page?: Maybe<PublicSitePage>;
  /** Get published pages, optionally filtering by a path prefix. */
  pages: PublicSitePagesList;
  platformId: Scalars['String']['output'];
  useCustomPrivacyPolicy: Scalars['Boolean']['output'];
  useCustomTermsOfUse: Scalars['Boolean']['output'];
};

export type PublicSiteFormArgs = {
  id: Scalars['String']['input'];
};

export type PublicSiteLatestAgreementArgs = {
  type: AgreementType;
};

export type PublicSiteLayoutForPathArgs = {
  path: Scalars['String']['input'];
};

export type PublicSitePageArgs = {
  path: Scalars['String']['input'];
};

export type PublicSitePagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pathPrefix?: InputMaybe<Scalars['String']['input']>;
};

export type PublicSiteForm = {
  __typename?: 'PublicSiteForm';
  components: Scalars['String']['output'];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: Maybe<Scalars['String']['output']>;
  confirmationRedirectParameters: Array<FormConfirmationRedirectUrlParameter>;
  confirmationRedirectUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Only the enabled steps. */
  steps: Array<FormStep>;
};

export type PublicSitePage = {
  __typename?: 'PublicSitePage';
  bodyStyles: Scalars['String']['output'];
  components: Scalars['String']['output'];
  id: Scalars['String']['output'];
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaRobots: Array<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  props?: Maybe<Scalars['String']['output']>;
  resolvedLayout?: Maybe<Layout>;
  title: Scalars['String']['output'];
};

export type PublicSitePagesList = ListResult & {
  __typename?: 'PublicSitePagesList';
  limit: Scalars['Int']['output'];
  pages: Array<PublicSitePage>;
  totalCount: Scalars['Int']['output'];
};

export const PublishStatus = {
  /** TODO: Replace Deleted with Archived. */
  Deleted: 'Deleted',
  Draft: 'Draft',
  Published: 'Published'
} as const;

export type PublishStatus = typeof PublishStatus[keyof typeof PublishStatus];
export type PublishStatusUpdate = {
  value: PublishStatus;
};

export type Query = {
  __typename?: 'Query';
  apiTokens: ApiTokensList;
  applyCustomPromoCode: ApplyCustomPromoCodeResult;
  /**
   * Get active components that can be used for the visual builder on a platform. Results include built-in,
   * custom, and remote extension-provided components.
   */
  builderComponents: BuilderComponentsList;
  component: Component;
  components: ComponentsList;
  course: Course;
  courses: CoursesList;
  customTables: CustomTablesList;
  document: Document;
  documentDownloadPrompts: Array<DocumentDownloadPrompt>;
  documents: Array<Document>;
  extensions: ExtensionsList;
  file: File;
  form: Form;
  formEntries: FormEntriesList;
  formTemplate: FormTemplate;
  formTemplates: Array<FormTemplate>;
  forms: Array<Form>;
  /**
   * Get help tickets. For a user without the View permission on the specified platform, only the user’s own
   * help tickets are returned (and platformId needs to be the user’s own platform ID).
   */
  helpTickets: HelpTicketsList;
  helpTickets2: HelpTicketsList;
  /**
   * Get an object with the form data with which to initialize the specified form. The result factors
   * in the signed in user, if any. The request needs to be sent to the API endpoint of the site on
   * which the form is being shown.
   */
  initialFormData: InitialFormData;
  layout: Layout;
  layoutTemplate: LayoutTemplate;
  layouts: LayoutsList;
  lesson: Lesson;
  liveCustomFields: Array<CustomField>;
  me?: Maybe<User>;
  me2?: Maybe<Me>;
  multiOrgView: Array<Organization>;
  organization: Organization;
  organizations: OrganizationsList;
  page: Page;
  /** Get a page template. For unauthenticated requests, a template is returned only if it's published. */
  pageTemplate: PageTemplate;
  pageTemplates: PageTemplatesList;
  pageTemplates2: PageTemplatesList;
  passwordResetInfo: PasswordResetInfo;
  platform: Platform;
  previewStripeUpcomingInvoice: PreviewStripeUpcomingInvoiceResult;
  productItemOccurrences: ProductItemOccurrences;
  productItemPicklist: ProductItemPicklist;
  products: ProductsList;
  promoCodePicklist: PromoCodePicklist;
  publicSite: PublicSite;
  remoteComponent: RemoteComponent;
  reviews: ReviewsList;
  scoreMyCallEntries: Array<ScoreMyCallEntry>;
  scoreMyCallEntryFileUrl: Scalars['String']['output'];
  sequence: Sequence;
  sequences: SequencesList;
  site: Site;
  siteTemplate: SiteTemplate;
  siteTemplates: SiteTemplatesList;
  sites: SitesList;
  stageBlueprint: StageBlueprint;
  subscription: Sub;
  subscriptionAuditLogs: SubscriptionAuditLogsList;
  /**
   * Get the subscription checkout options for the specified products. The options are sorted in the same order
   * as the input IDs.
   */
  subscriptionCheckoutOptions: Array<SubscriptionCheckoutOption>;
  subscriptionPlan: SubscriptionPlan;
  subscriptions: SubscriptionsList;
  user: User;
  userAuditLogs: UserAuditLogsList;
  users: UsersList;
};

export type QueryApplyCustomPromoCodeArgs = {
  promoCode: Scalars['String']['input'];
  subscriptionPlanId: Scalars['String']['input'];
};

export type QueryBuilderComponentsArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryComponentArgs = {
  id: Scalars['String']['input'];
};

export type QueryComponentsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
};

export type QueryCourseArgs = {
  id: Scalars['String']['input'];
};

export type QueryCoursesArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryCustomTablesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  object?: InputMaybe<CustomTableObject>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
};

export type QueryDocumentArgs = {
  id: Scalars['String']['input'];
};

export type QueryDocumentDownloadPromptsArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryDocumentsArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryExtensionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
};

export type QueryFileArgs = {
  id: Scalars['String']['input'];
};

export type QueryFormArgs = {
  id: Scalars['String']['input'];
};

export type QueryFormEntriesArgs = {
  formId?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryFormTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryFormTemplatesArgs = {
  platformId: Scalars['String']['input'];
  status?: InputMaybe<PublishStatus>;
};

export type QueryFormsArgs = {
  siteId: Scalars['String']['input'];
};

export type QueryHelpTicketsArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
};

export type QueryHelpTickets2Args = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
};

export type QueryInitialFormDataArgs = {
  formId: Scalars['String']['input'];
  pageId?: InputMaybe<Scalars['String']['input']>;
  pageUrl: Scalars['String']['input'];
};

export type QueryLayoutArgs = {
  id: Scalars['String']['input'];
};

export type QueryLayoutTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryLayoutsArgs = {
  siteId: Scalars['String']['input'];
};

export type QueryLessonArgs = {
  id: Scalars['String']['input'];
};

export type QueryLiveCustomFieldsArgs = {
  object: CustomTableObject;
  platformId: Scalars['String']['input'];
};

export type QueryMultiOrgViewArgs = {
  organizationId: Scalars['String']['input'];
};

export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};

export type QueryOrganizationsArgs = {
  id?: InputMaybe<IdFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPageArgs = {
  id: Scalars['String']['input'];
};

export type QueryPageTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryPageTemplatesArgs = {
  id?: InputMaybe<IdFilter>;
  platformId: Scalars['String']['input'];
  siteTemplateId?: InputMaybe<StringListFilter>;
  status?: InputMaybe<Array<PublishStatus>>;
};

export type QueryPageTemplates2Args = {
  id?: InputMaybe<IdFilter>;
  platformId: Scalars['String']['input'];
  siteTemplateId?: InputMaybe<StringListFilter>;
  status?: InputMaybe<Array<PublishStatus>>;
};

export type QueryPasswordResetInfoArgs = {
  key: Scalars['String']['input'];
};

export type QueryPlatformArgs = {
  id: Scalars['String']['input'];
};

export type QueryPreviewStripeUpcomingInvoiceArgs = {
  promoCode?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlanId: Scalars['String']['input'];
};

export type QueryProductItemOccurrencesArgs = {
  item: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type QueryProductItemPicklistArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
};

export type QueryProductsArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
};

export type QueryPromoCodePicklistArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryPublicSiteArgs = {
  id: Scalars['String']['input'];
};

export type QueryRemoteComponentArgs = {
  componentName: Scalars['String']['input'];
  extensionName: Scalars['String']['input'];
};

export type QueryReviewsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
};

export type QueryScoreMyCallEntriesArgs = {
  platformId: Scalars['String']['input'];
  shareRecording?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryScoreMyCallEntryFileUrlArgs = {
  download?: InputMaybe<Scalars['Boolean']['input']>;
  entryId: Scalars['String']['input'];
};

export type QuerySequenceArgs = {
  id: Scalars['String']['input'];
};

export type QuerySequencesArgs = {
  platformId: Scalars['String']['input'];
};

export type QuerySiteArgs = {
  id: Scalars['String']['input'];
};

export type QuerySiteTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QuerySiteTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
};

export type QuerySitesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  siteTemplateId?: InputMaybe<OptionalIdFilter>;
};

export type QueryStageBlueprintArgs = {
  id: Scalars['String']['input'];
};

export type QuerySubscriptionArgs = {
  id: Scalars['String']['input'];
};

export type QuerySubscriptionAuditLogsArgs = {
  actionByUserId?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySubscriptionCheckoutOptionsArgs = {
  subscriptionPlanIds: Array<Scalars['String']['input']>;
};

export type QuerySubscriptionPlanArgs = {
  id: Scalars['String']['input'];
};

export type QuerySubscriptionsArgs = {
  endAtFrom?: InputMaybe<Scalars['Date']['input']>;
  endAtTo?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  platformId: Scalars['String']['input'];
  promoCode?: InputMaybe<OptionalStringFilter>;
  status?: InputMaybe<SubscriptionStatus>;
};

export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

export type QueryUserAuditLogsArgs = {
  actionByUserId?: InputMaybe<Scalars['String']['input']>;
  affectedUserId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
};

export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
};

export type RemoteComponent = {
  __typename?: 'RemoteComponent';
  extensionName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stylesheetUrls: Array<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type RestoreCourseVersionChangesInput = {
  changes: Array<CourseVersionChange>;
  courseVersionId: Scalars['String']['input'];
};

export type RestoreCourseVersionChangesResult = AuthorizationError | GenericInternalError | GenericUserError | RestoreCourseVersionChangesSuccessResult;

export type RestoreCourseVersionChangesSuccessResult = {
  __typename?: 'RestoreCourseVersionChangesSuccessResult';
  course: Course;
};

export type RestoreLessonVersionChangesInput = {
  changes: Array<LessonVersionChange>;
  lessonVersionId: Scalars['String']['input'];
};

export type RestoreLessonVersionChangesResult = AuthorizationError | GenericInternalError | GenericUserError | RestoreLessonVersionChangesSuccessResult;

export type RestoreLessonVersionChangesSuccessResult = {
  __typename?: 'RestoreLessonVersionChangesSuccessResult';
  lesson: Lesson;
};

export type RestorePageVersionChangesInput = {
  changes: Array<PageVersionChange>;
  pageVersionId: Scalars['String']['input'];
};

export type RestorePageVersionChangesResult = AuthorizationError | GenericInternalError | GenericUserError | RestorePageVersionChangesSuccessResult;

export type RestorePageVersionChangesSuccessResult = {
  __typename?: 'RestorePageVersionChangesSuccessResult';
  page: Page;
};

export type Review = {
  __typename?: 'Review';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  review?: Maybe<Scalars['String']['output']>;
  starRating: Scalars['Int']['output'];
  submitterName?: Maybe<Scalars['String']['output']>;
};

export type ReviewsList = ListResult & {
  __typename?: 'ReviewsList';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  reviews: Array<Review>;
  totalCount: Scalars['Int']['output'];
};

export type RichTextInput = {
  /** A JSON object */
  delta: Scalars['String']['input'];
};

export type ScoreMyCallEntry = {
  __typename?: 'ScoreMyCallEntry';
  createdAt: Scalars['Date']['output'];
  formData: Scalars['String']['output'];
  id: Scalars['String']['output'];
  prospectName?: Maybe<Scalars['String']['output']>;
  shareRecording: Scalars['Boolean']['output'];
  status: ScoreMyCallEntryStatus;
  user: User;
  userId: Scalars['String']['output'];
};

export const ScoreMyCallEntryStatus = {
  NotReviewed: 'NotReviewed',
  Reviewed: 'Reviewed'
} as const;

export type ScoreMyCallEntryStatus = typeof ScoreMyCallEntryStatus[keyof typeof ScoreMyCallEntryStatus];
export type Sequence = {
  __typename?: 'Sequence';
  id: Scalars['String']['output'];
  loopStages: Scalars['Boolean']['output'];
  /** The sequence's stage blueprints. For users without the View permission, the status argument is ignored and only active stage blueprints are returned. */
  stageBlueprints: Array<StageBlueprint>;
  stageLabel: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SequenceStageBlueprintsArgs = {
  status?: InputMaybe<Array<StageBlueprintStatus>>;
};

export type SequenceUser = {
  __typename?: 'SequenceUser';
  id: Scalars['String']['output'];
  nextStageBlueprintId?: Maybe<Scalars['String']['output']>;
  previousStageBlueprintId?: Maybe<Scalars['String']['output']>;
  sequence: Sequence;
  stageBlueprint: StageBlueprint;
  userId: Scalars['String']['output'];
};

export type SequencesList = ListResult & {
  __typename?: 'SequencesList';
  limit: Scalars['Int']['output'];
  sequences: Array<Sequence>;
  totalCount: Scalars['Int']['output'];
};

export type Site = {
  __typename?: 'Site';
  addressCity?: Maybe<Scalars['String']['output']>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  addressZip?: Maybe<Scalars['String']['output']>;
  agencyPrimaryPhone?: Maybe<Scalars['String']['output']>;
  agentFirstName?: Maybe<Scalars['String']['output']>;
  agentLastName?: Maybe<Scalars['String']['output']>;
  agentPhotoUrl?: Maybe<Scalars['String']['output']>;
  agreements: AgreementsList;
  bodyEndCode?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  fontFamily: Scalars['String']['output'];
  globalCss?: Maybe<Scalars['String']['output']>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  googleTagManagerId?: Maybe<Scalars['String']['output']>;
  headEndCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inputFieldFontFamily: Scalars['String']['output'];
  logoIconUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  metaPixelId?: Maybe<Scalars['String']['output']>;
  mixpanelToken?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** The org that owns the site. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['String']['output']>;
  pages: PagesList;
  pages2: PagesList;
  platformId: Scalars['String']['output'];
  radixThemeColorName?: Maybe<Scalars['String']['output']>;
  siteTemplate?: Maybe<SiteTemplate>;
  siteTemplateId?: Maybe<Scalars['String']['output']>;
  stripePublishableKey?: Maybe<Scalars['String']['output']>;
  tlsCertificates: Array<TlsCertificate>;
  useCustomPrivacyPolicy: Scalars['Boolean']['output'];
  useCustomTermsOfUse: Scalars['Boolean']['output'];
  zendeskChatKey?: Maybe<Scalars['String']['output']>;
};

export type SitePagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageTemplateId?: InputMaybe<OptionalIdFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<PublishStatus>>;
};

export type SitePages2Args = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageTemplateId?: InputMaybe<OptionalIdFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<PublishStatus>>;
};

export type SiteTemplate = {
  __typename?: 'SiteTemplate';
  id: Scalars['String']['output'];
  layoutTemplates: LayoutTemplatesList;
  name: Scalars['String']['output'];
  pageTemplates: PageTemplatesList;
};

export type SiteTemplateLayoutTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteTemplatePageTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteTemplateLayoutTemplatesInput = {
  layoutTemplateIds: Array<Scalars['String']['input']>;
  siteTemplateId: Scalars['String']['input'];
};

export type SiteTemplatePageTemplatesInput = {
  pageTemplateIds: Array<Scalars['String']['input']>;
  siteTemplateId: Scalars['String']['input'];
};

export type SiteTemplatesList = ListResult & {
  __typename?: 'SiteTemplatesList';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  siteTemplates: Array<SiteTemplate>;
  totalCount: Scalars['Int']['output'];
};

export type SitesList = ListResult & {
  __typename?: 'SitesList';
  limit: Scalars['Int']['output'];
  sites: Array<Site>;
  totalCount: Scalars['Int']['output'];
};

export type SplitUserToNewOrgResult = GenericUserError | SplitUserToNewOrgSuccessResult;

export type SplitUserToNewOrgSuccessResult = {
  __typename?: 'SplitUserToNewOrgSuccessResult';
  user: User;
};

/** TODO: Replace with SequenceStageUser. */
export type Stage = {
  __typename?: 'Stage';
  date: Scalars['String']['output'];
  files: Array<File>;
  id: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  stageBlueprint: StageBlueprint;
  state: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type StageBlueprint = {
  __typename?: 'StageBlueprint';
  completedAt: Array<Scalars['Date']['output']>;
  completionSpec: Scalars['String']['output'];
  components: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  sequenceId: Scalars['String']['output'];
};

export type StageBlueprintCompletedAtArgs = {
  userId: Scalars['String']['input'];
};

export const StageBlueprintStatus = {
  Active: 'Active',
  Deleted: 'Deleted'
} as const;

export type StageBlueprintStatus = typeof StageBlueprintStatus[keyof typeof StageBlueprintStatus];
export type StringFilter = {
  operator: StringFilterOperator;
  value: Scalars['String']['input'];
};

export const StringFilterOperator = {
  Contains: 'Contains',
  Equals: 'Equals',
  NotContains: 'NotContains',
  NotEquals: 'NotEquals'
} as const;

export type StringFilterOperator = typeof StringFilterOperator[keyof typeof StringFilterOperator];
export type StringListFilter = {
  operator: StringListFilterOperator;
  /**
   * If operator is Empty or NotEmpty, value is ignored and must not be provided.
   * If operator is Contains or NotContains, value must be provided and include exactly one value.
   * If operator is ContainsAll, NotContainsAll, ContainsAny, or NotContainsAny, value must be provided and include at least one value.
   */
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export const StringListFilterOperator = {
  Contains: 'Contains',
  ContainsAll: 'ContainsAll',
  ContainsAny: 'ContainsAny',
  Empty: 'Empty',
  NotContains: 'NotContains',
  NotContainsAll: 'NotContainsAll',
  NotContainsAny: 'NotContainsAny',
  NotEmpty: 'NotEmpty'
} as const;

export type StringListFilterOperator = typeof StringListFilterOperator[keyof typeof StringListFilterOperator];
export type StringListUpdate = {
  value: Array<Scalars['String']['input']>;
};

/**
 * A container for an input field representing a required (i.e., non-null) string that may optionally
 * be updated. If an object of this type is provided, its value becomes the new value of the field.
 */
export type StringUpdate = {
  value: Scalars['String']['input'];
};

/** The supported coupon duration types. */
export const StripeCouponDuration = {
  Forever: 'Forever',
  Once: 'Once',
  Repeating: 'Repeating'
} as const;

export type StripeCouponDuration = typeof StripeCouponDuration[keyof typeof StripeCouponDuration];
export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  address?: Maybe<Address>;
  id: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type Sub = {
  __typename?: 'Sub';
  endAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  invoiceFrequency?: Maybe<InvoiceFrequency>;
  managerOrganization?: Maybe<OrganizationProfile>;
  notes: Array<SubscriptionNote>;
  plan: SubscriptionPlan;
  planId: Scalars['String']['output'];
  planProfile: SubscriptionPlanProfile;
  price: Scalars['String']['output'];
  promoCode?: Maybe<Scalars['String']['output']>;
  startAt: Scalars['Date']['output'];
  status: SubscriptionStatus;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  /**
   * The SubscriptionOrganizations connecting this subscription to the orgs that are included in it.
   * If the requesting user is not a member of the manager org, the list will include only the user's own org.
   */
  subscriptionOrganizations: Array<SubscriptionOrganization>;
};

export type SubmitFormResult = {
  __typename?: 'SubmitFormResult';
  sessionId: Scalars['String']['output'];
};

export type SubmitScoreMyCallEntryResult = {
  __typename?: 'SubmitScoreMyCallEntryResult';
  signedUploadUrl: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  stageUpdated?: Maybe<Stage>;
};

export type SubscriptionStageUpdatedArgs = {
  id: Scalars['String']['input'];
};

export type SubscriptionAuditLog = {
  __typename?: 'SubscriptionAuditLog';
  action: Scalars['String']['output'];
  actionByUser: UserProfile;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  subscription: Sub;
};

export type SubscriptionAuditLogsList = ListResult & {
  __typename?: 'SubscriptionAuditLogsList';
  limit: Scalars['Int']['output'];
  logs: Array<SubscriptionAuditLog>;
  totalCount: Scalars['Int']['output'];
};

/** A subset of Product fields, intentionally limited for public view. */
export type SubscriptionCheckoutOption = {
  __typename?: 'SubscriptionCheckoutOption';
  /** Either the displayName of the Product, if set, or the name as a fallback. */
  displayName: Scalars['String']['output'];
  /** The ID of a Product. */
  id: Scalars['String']['output'];
  invoiceFrequency?: Maybe<InvoiceFrequency>;
  price: Scalars['String']['output'];
};

export type SubscriptionNote = {
  __typename?: 'SubscriptionNote';
  byUser: UserProfile;
  byUserId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  notes: Scalars['String']['output'];
};

export type SubscriptionOrganization = {
  __typename?: 'SubscriptionOrganization';
  id: Scalars['String']['output'];
  org: Organization;
  subscription: Sub;
  subscriptionId: Scalars['String']['output'];
  subscriptionOwner: Scalars['Boolean']['output'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  alternativeStripePriceIds: Array<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  durationDays?: Maybe<Scalars['Int']['output']>;
  endAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  /** The invoice frequency for this plan. This will be null for plans that have a price of 0. */
  invoiceFrequency?: Maybe<InvoiceFrequency>;
  items: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['String']['output'];
  promoCodes: Array<PromoCode>;
  stripePriceId?: Maybe<Scalars['String']['output']>;
};

/** This type limits the type of data that can be queried from a SubscriptionPlan. */
export type SubscriptionPlanProfile = {
  __typename?: 'SubscriptionPlanProfile';
  displayName?: Maybe<Scalars['String']['output']>;
  /** The ID of a SubscriptionPlan. */
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export const SubscriptionStatus = {
  Active: 'Active',
  Canceled: 'Canceled',
  Incomplete: 'Incomplete',
  IncompleteExpired: 'IncompleteExpired',
  PastDue: 'PastDue',
  Paused: 'Paused',
  Replaced: 'Replaced',
  Trialing: 'Trialing',
  Unpaid: 'Unpaid'
} as const;

export type SubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus];
export type SubscriptionsList = ListResult & {
  __typename?: 'SubscriptionsList';
  limit: Scalars['Int']['output'];
  subscriptions: Array<Sub>;
  totalCount: Scalars['Int']['output'];
};

export type SyncLayoutTemplateToLayoutsInput = {
  layoutId: Scalars['String']['input'];
  layoutTemplateId: Scalars['String']['input'];
  /**
   * Whether to sync the layout’s path prefix to the template’s path prefix. If true and there is another
   * layout on the site with the template’s path prefix and is not deleted, the request will be rejected.
   */
  syncPathPrefix?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SyncLayoutTemplateToLayoutsResult = AuthorizationError | GenericUserError | SyncLayoutTemplateToLayoutsSuccessResult;

export type SyncLayoutTemplateToLayoutsSuccessResult = {
  __typename?: 'SyncLayoutTemplateToLayoutsSuccessResult';
  syncedLayouts: LayoutsList;
};

export type SyncPageTemplateToPagesInput = {
  /** The fields to sync. If not provided, all fields are synced. Providing an empty list is not valid. */
  fields?: InputMaybe<Array<PageTemplateSyncedField>>;
  /**
   * By default, only fields that have not been edited since the page was created or last synced are updated.
   * Any field that is listed in forceSync is updated regardless of whether it has been edited.
   * If Path is included but a given page’s site already has another published or draft page with the template’s
   * path, the page’s path will be updated, and the page will be included in the duplicatePathNotSyncedPages list.
   */
  forceSync?: InputMaybe<Array<PageTemplateSyncedField>>;
  /** Must be provided if and only if selection is PageIds. */
  pageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  pageTemplateId: Scalars['String']['input'];
  /** Which pages to sync. */
  selection: SyncPageTemplateToPagesSelection;
};

export type SyncPageTemplateToPagesResult = AuthorizationError | GenericUserError | SyncPageTemplateToPagesSuccessResult;

export const SyncPageTemplateToPagesSelection = {
  All: 'All',
  PageIds: 'PageIds'
} as const;

export type SyncPageTemplateToPagesSelection = typeof SyncPageTemplateToPagesSelection[keyof typeof SyncPageTemplateToPagesSelection];
export type SyncPageTemplateToPagesSuccessResult = {
  __typename?: 'SyncPageTemplateToPagesSuccessResult';
  /** The pages that were not synced because the page’s site already has a page with the template’s path. Applies only if syncPaths is true. */
  duplicatePathNotSyncedPages: PagesList;
  /** Error messages for pages that could not be synced due to an internal or misconfiguration issue. */
  errorMessages: Array<Scalars['String']['output']>;
  /** Pages that did not have any changes to apply. */
  noChangePages: PagesList;
  syncedPages: PagesList;
};

/**
 * An abstraction for a TLS certificate for a particular domain. The actual certificate may cover other domains and
 * may be used by multiple certificate map entries.
 */
export type TlsCertificate = {
  __typename?: 'TlsCertificate';
  domain: Scalars['String']['output'];
  /** A unique string derived from the certificate's name and this domain. */
  id: Scalars['String']['output'];
  /** The state of the associated certificate map entry. This will be null if no map entry is found. */
  mapEntryState?: Maybe<GCloudCertificateMapEntryState>;
  /** The full certificate name as returned by the underlying certificate provider. */
  name: Scalars['String']['output'];
  provisioningIssueDetails?: Maybe<Scalars['String']['output']>;
  state: GCloudCertificateState;
};

export type UploadFileInput = {
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  purpose: UploadFilePurpose;
};

/** One and only of the fields must be set. */
export type UploadFilePurpose = {
  customField?: InputMaybe<UploadFilePurposeCustomField>;
  document?: InputMaybe<UploadFilePurposeDocument>;
  sequenceStageUser?: InputMaybe<UploadFilePurposeSequenceStageUser>;
  siteLogo?: InputMaybe<UploadFilePurposeSiteLogo>;
};

export type UploadFilePurposeCustomField = {
  customFieldName: Scalars['String']['input'];
  /** The type of entity that the custom field is set on. */
  object: CustomTableObject;
  /** The ID of the target object (e.g., a Site's ID). */
  parentId: Scalars['String']['input'];
};

export type UploadFilePurposeDocument = {
  documentId: Scalars['String']['input'];
  /** If true, the file will be set as the current version on the document upon being uploaded. */
  setAsCurrentVersion: Scalars['Boolean']['input'];
};

export type UploadFilePurposeSequenceStageUser = {
  sequenceStageUserId: Scalars['String']['input'];
  stateProperty: Scalars['String']['input'];
};

export type UploadFilePurposeSiteLogo = {
  siteId: Scalars['String']['input'];
};

export type UploadFileResult = AuthorizationError | GenericInternalError | GenericUserError | UploadFileSuccessResult;

export type UploadFileSuccessResult = {
  __typename?: 'UploadFileSuccessResult';
  fileId: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  /** The latest agreement types on the current site (based on request host) to which the user has given consent. */
  currentAgreementsSigned: Array<AgreementType>;
  custom: CustomFieldValuesList;
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  org: Organization;
  orgId: Scalars['String']['output'];
  orgPrimary: Scalars['Boolean']['output'];
  passwordExpiresAt?: Maybe<Scalars['Date']['output']>;
  passwordStatus: PasswordStatus;
  /**
   * Find or create this user's UserPlan for the specified Plan. Any user can access this field for themselves with the
   * ID of a Plan owned by the platform they belong to. A user can also access this field with the ID of a Plan owned by
   * a platform on which they have PermissionName.EditSequenceUser.
   */
  plan: UserPlan;
  planProgress: Array<UserPlanProgress>;
  plans: Array<UserPlan>;
  /** This user’s platforms where the user is an admin. */
  platformAdminUsers: Array<PlatformAdminUser>;
  /** All product items from all of the active subscriptions of the user's org. */
  productItems: Array<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  /**
   * Find or create this user's SequenceUser for the specified sequence. Any user can access this field for themselves with
   * the ID of a sequence owned by the platform the user belongs to. A user can also access this field for another user if
   * the requesting user has the EditSequenceUser permission on the platform that the queried user belongs to.
   */
  sequenceUser: SequenceUser;
  status: UserStatus;
  userCourseProgresses: UserCourseProgressesList;
  zendeskChatToken?: Maybe<Scalars['String']['output']>;
};

export type UserPlanArgs = {
  planId: Scalars['String']['input'];
};

export type UserPlanProgressArgs = {
  contentPlanIds: Array<Scalars['String']['input']>;
};

export type UserSequenceUserArgs = {
  sequenceId: Scalars['String']['input'];
};

export type UserUserCourseProgressesArgs = {
  courseIds: Array<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAuditLog = {
  __typename?: 'UserAuditLog';
  action: Scalars['String']['output'];
  actionByUser: UserProfile;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  user: UserProfile;
};

export type UserAuditLogsList = ListResult & {
  __typename?: 'UserAuditLogsList';
  limit: Scalars['Int']['output'];
  logs: Array<UserAuditLog>;
  totalCount: Scalars['Int']['output'];
};

/**
 * This API is intentionally limited so that we do not expose an entire course or lesson, since any user can
 * request this data for themselves regardless of their subscriptions or permissions.
 */
export type UserCourseProgress = {
  __typename?: 'UserCourseProgress';
  courseId: Scalars['String']['output'];
  courseTitle: Scalars['String']['output'];
  lessons: UserCourseProgressLessonsList;
  userId: Scalars['String']['output'];
};

/** This API is intentionally limited. */
export type UserCourseProgressLesson = {
  __typename?: 'UserCourseProgressLesson';
  /** When the user completed the lesson. */
  completedAt?: Maybe<Scalars['Date']['output']>;
  /** A Lesson ID. */
  id: Scalars['String']['output'];
  /** When the user started the lesson. */
  startedAt?: Maybe<Scalars['Date']['output']>;
  subLessons: UserCourseProgressLessonsList;
  title: Scalars['String']['output'];
};

export type UserCourseProgressLessonsList = ListResult & {
  __typename?: 'UserCourseProgressLessonsList';
  lessons: Array<UserCourseProgressLesson>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type UserCourseProgressesList = ListResult & {
  __typename?: 'UserCourseProgressesList';
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  userCourseProgresses: Array<UserCourseProgress>;
};

export type UserPlan = {
  __typename?: 'UserPlan';
  id: Scalars['String']['output'];
  nextStageBlueprintId?: Maybe<Scalars['String']['output']>;
  previousStageBlueprintId?: Maybe<Scalars['String']['output']>;
  sequence: Sequence;
  stageBlueprint: StageBlueprint;
  userId: Scalars['String']['output'];
};

export type UserPlanProgress = {
  __typename?: 'UserPlanProgress';
  completedStageBlueprintIds: Array<Scalars['String']['output']>;
  plan: Sequence;
  planId: Scalars['String']['output'];
  startedStageBlueprintIds: Array<Scalars['String']['output']>;
  totalStageBlueprints: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

/** A UserProfile is a minimal set of User fields. */
export type UserProfile = {
  __typename?: 'UserProfile';
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export const UserStatus = {
  Active: 'Active',
  Deactivated: 'Deactivated',
  Pending: 'Pending'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];
export type UsersList = ListResult & {
  __typename?: 'UsersList';
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  users: Array<User>;
};
