import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useSequenceStageContext } from '../sequence/stage/sequence-stage-context.ts';

export const StageName: FunctionComponent<Component> = (props) => {
  const stageContext = useSequenceStageContext();

  if (stageContext) {
    return <div className={componentClassName(props)}>{stageContext.stage.name}</div>;
  }

  return null;
};
