import { useQuery } from '@wirechunk/apollo-client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { DocumentComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { SvgDownload } from '@wirechunk/material-symbols-react-400/20/outlined/download.tsx';
import { Button } from '@wirechunk/ui';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import type { FunctionComponent } from 'react';
import { useErrorCollector } from '../../../contexts/error-collector-context.tsx';
import { useFileDownload } from '../../../hooks/useFileDownload.ts';
import { Spinner } from '../../spinner/spinner.tsx';
import { ViewDocumentDocument } from './queries.generated.ts';
import { useDocumentDownloadPromptDialog } from './use-document-download-prompt-dialog.tsx';
import { documentDownloadUrl } from './util.ts';

type DocumentBodyProps = Omit<DocumentComponent, 'documentId'> & {
  documentId: string;
};

const DocumentBody: FunctionComponent<DocumentBodyProps> = (props) => {
  const { onError } = useErrorCollector();
  const { data, loading } = useQuery(ViewDocumentDocument, {
    // TODO: Handle users who are not authorized to view a document better (e.g., with a missing product item or role).
    onError,
    variables: { id: props.documentId },
  });
  const { download, downloadingFileIds } = useFileDownload();
  const showPrompt = useDocumentDownloadPromptDialog(download);

  const doc = data?.document;
  const currentVersion = doc?.currentVersion;

  if (loading && !data) {
    return <Spinner py="3" />;
  }

  if (doc && currentVersion) {
    return (
      <div
        className={clsx(
          componentClassName(props),
          // Display this component's native styling only when a document is found.
          'flex-column gap-1 md:flex-row md:gap-2 p-3 border-1 border-round flex justify-content-between align-items-center',
        )}
      >
        <div className="flex align-items-center gap-3">
          <i className={PrimeIcons.FILE} />
          <span className="font-medium">{doc.name}</span>
        </div>
        <Button
          disabled={downloadingFileIds.includes(doc.id)}
          onClick={() => {
            if (doc.downloadPrompt) {
              showPrompt(
                doc.id,
                documentDownloadUrl(doc.id),
                currentVersion.downloadName,
                doc.downloadPrompt,
              );
            } else {
              download(doc.id, documentDownloadUrl(doc.id), currentVersion.downloadName);
            }
          }}
        >
          <SvgDownload /> Download
        </Button>
      </div>
    );
  }

  return null;
};

export const Document: FunctionComponent<DocumentComponent> = (props) => {
  const { documentId } = props;
  return documentId ? <DocumentBody {...props} documentId={documentId} /> : null;
};
