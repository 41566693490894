import { setUser as setSentryUser } from '@sentry/browser';
import { useQuery } from '@wirechunk/apollo-client';
import { MeDocument } from '@wirechunk/lib/shared-queries/me-query.generated.ts';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { apolloClient } from '../apollo-client.ts';
import { CurrentUserProvider } from '../contexts/current-user-context.tsx';
import {
  SessionStatus,
  useSessionStatusContext,
} from '../contexts/SessionStatusContext/session-status-context.tsx';
import { useErrorHandler } from '../hooks/useErrorHandler.tsx';
import { useRefreshSession } from '../hooks/useRefreshSession/useRefreshSession.ts';
import { triggerGoogleTagManagerEvent } from '../util/analyticsScripts.ts';
import { authenticateMessenger } from '../util/zendesk/zendesk.ts';

export const SessionUserProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { onErrorToast } = useErrorHandler();
  const { data: meData, loading } = useQuery(MeDocument, { onError: onErrorToast });
  const { setStatus } = useSessionStatusContext();
  useRefreshSession();

  const me = meData?.me2 || null;

  useEffect(() => {
    if (me) {
      authenticateMessenger(apolloClient);
      triggerGoogleTagManagerEvent({
        user_id: me.id,
      });
      triggerGoogleTagManagerEvent({
        event: 'user_authenticated',
      });
      setSentryUser({
        id: me.id,
        email: me.email,
      });
      setStatus(SessionStatus.Active);
    }
  }, [me, setStatus]);

  return (
    <CurrentUserProvider user={me} loadingUser={loading}>
      {children}
    </CurrentUserProvider>
  );
};
