import { captureException } from '@sentry/react';
import { AnalyticsContext } from '@wirechunk/extension-toolkit/web/analytics-context';
import type { Mixpanel } from 'mixpanel-browser';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { use, useEffect, useMemo } from 'react';
import { CurrentUserContext } from '../contexts/current-user-context.tsx';
import { environment } from '../env.ts';

const { mixpanelToken } = window.wirechunk;

// The Mixpanel script can be blocked by tracker blockers based on the file name.
// If the module fails to load, allow the app to be rendered anyway.
let mixpanelInstance: Mixpanel | null = null;
if (mixpanelToken) {
  const mixpanelInstanceName = 'site';
  try {
    const mb = await import('mixpanel-browser');
    mixpanelInstance = mb.default.init(
      mixpanelToken,
      {
        ...(environment === 'production' ? {} : { debug: true }),
      },
      mixpanelInstanceName,
    );
  } catch (error) {
    captureException(error);
  }
}

export const AnalyticsContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { user } = use(CurrentUserContext);

  const orgId = user?.__typename === 'User' ? user.orgId : null;
  const value = useMemo<AnalyticsContext>(
    () => ({
      track: (event, properties = {}, sendImmediately) => {
        mixpanelInstance?.track(
          event,
          {
            orgId,
            ...properties,
          },
          sendImmediately ? { send_immediately: true } : undefined,
        );
      },
      reset: () => mixpanelInstance?.reset(),
    }),
    [orgId],
  );

  const userId = user?.id;
  useEffect(() => {
    if (userId) {
      mixpanelInstance?.identify(userId);
    }
  }, [userId]);

  return <AnalyticsContext value={value}>{children}</AnalyticsContext>;
};
