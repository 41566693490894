import { captureException } from '@sentry/react';
import { useMutation } from '@wirechunk/apollo-client';
import { useCallback, useEffect, useRef } from 'react';
import { ignoredRequestCanceledError } from '../../apollo-client.ts';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import { usePollFileStatus } from '../usePollFileStatus/usePollFileStatus.ts';
import { UploadFileDocument } from './mutations.generated.ts';
import type { UploadFilePurpose } from '#api';

export type UploadFile = {
  // upload takes a File and the purpose field that will be passed to the uploadFile mutation to get a
  // signed URL, then uploads the file (a PUT request), and starts polling for the file's status.
  // While uploading or polling for the file's status, calling the upload function will not do anything.
  upload: (
    file: File,
    purpose: UploadFilePurpose,
    // onUploaded is called after the file is uploaded, at which point the File's status may still be Uploading.
    onUploaded?: (() => void) | null,
    // onDonePollingUploaded is called after the file is uploaded and the polling loop is done, which could
    // be because the file was uploaded successfully or that the upload timed out or was canceled.
    onDonePollingUploaded?: () => void | Promise<void>,
  ) => void;
  // True while getting a signed URL and while polling the file's status.
  uploading: boolean;
  cancel: () => void;
};

export const useUploadFile = (onError: ErrorHandler['onError']): UploadFile => {
  const [getUploadUrl, { loading: uploadFileLoading }] = useMutation(UploadFileDocument, {
    onError,
  });
  const abortController = useRef<AbortController | null>(null);
  const { startPollingFile, stopPollingFile, polling } = usePollFileStatus(onError);

  useEffect(() => {
    // On un-mount, cancel any pending fetches.
    return () => {
      abortController.current?.abort(ignoredRequestCanceledError);
    };
  }, []);

  const uploading = uploadFileLoading || polling;

  const upload = useCallback<UploadFile['upload']>(
    (file, purpose, onUploaded, onDonePollingUploaded) => {
      if (uploading) {
        return;
      }
      void getUploadUrl({
        variables: {
          input: {
            name: file.name,
            mimeType: file.type,
            purpose,
          },
        },
        onCompleted: (data) => {
          if (data.uploadFile.__typename === 'UploadFileSuccessResult') {
            const { fileId, signedUrl } = data.uploadFile;
            abortController.current?.abort(ignoredRequestCanceledError);
            const ac = new AbortController();
            abortController.current = ac;
            void (async () => {
              try {
                const res = await fetch(signedUrl, {
                  method: 'PUT',
                  body: file,
                  signal: ac.signal,
                  headers: {
                    'Content-Type': file.type,
                  },
                });
                if (res.ok) {
                  startPollingFile(fileId, onDonePollingUploaded);
                  // In this case, the polling continues until our async job marks the File as Uploaded.
                  onUploaded?.();
                } else {
                  onError(`Could not upload the file ${file.name}. Please try again in a moment.`);
                }
              } catch (error) {
                captureException(error);
                onError(`Could not upload the file ${file.name}. Please try again.`);
              }
            })();
          } else {
            onError(data.uploadFile);
          }
        },
      });
    },
    [startPollingFile, getUploadUrl, onError, uploading],
  );

  const cancel = useCallback(() => {
    abortController.current?.abort(ignoredRequestCanceledError);
    stopPollingFile();
  }, [stopPollingFile]);

  return {
    upload,
    uploading,
    cancel,
  };
};
