import { inputLabelClassName } from '@wirechunk/lib/mixer/styles.ts';
import type { Label as LabelSpec } from '@wirechunk/lib/mixer/types/components.ts';
import type { BoxProps } from '@wirechunk/ui';
import { Label } from '@wirechunk/ui';
import type { FunctionComponent } from 'react';
import { useComponentLabel } from '../mixer-hocs/use-component-label.tsx';

type MixerLabelProps = LabelSpec &
  BoxProps & {
    inputId: string;
    fallbackLabel?: string;
  };

// A label specifically meant as part of a built-in component.
export const MixerLabel: FunctionComponent<MixerLabelProps> = (props) => {
  const { label: _drop, inputId, fallbackLabel, ...boxProps } = props;
  const labelText = useComponentLabel(props) || fallbackLabel;
  if (labelText) {
    return (
      <Label htmlFor={inputId} className={inputLabelClassName} {...boxProps}>
        {labelText}
      </Label>
    );
  }
  return null;
};
