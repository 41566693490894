import { useMutation } from '@wirechunk/apollo-client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { ReviewComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { pluralize } from '@wirechunk/lib/pluralize.ts';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import { SvgStar } from '@wirechunk/material-symbols-react-400/20/outlined/star.tsx';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import type { FunctionComponent } from 'react';
import { use, Fragment, useState } from 'react';
import { PropsContext } from '../../../contexts/props-context.ts';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { SvgStarFilled } from '../../../icons/star-filled.tsx';
import { FormField } from '../../form-field/form-field.tsx';
import { SubmitReviewDocument } from './mutations.generated.ts';

const writeGoogleReviewUrl = (placeId: string) =>
  `https://search.google.com/local/writereview?placeid=${placeId}`;

export const defaultSelectStarsLabel = 'Your opinion matters to us! How would you rate us?';
export const defaultReviewFieldPlaceholder = 'Details of your experience...';

const defaultStarColor = '#80868b';
export const filledStarColor = '#fabb05';

const Star: FunctionComponent<{
  stars: number;
  filled: boolean;
  onClick: () => void;
}> = ({ stars, filled, onClick }) =>
  filled ? (
    <SvgStarFilled
      fill={filledStarColor}
      role="radio"
      onClick={onClick}
      width={40}
      height={40}
      aria-label={`${stars} ${pluralize(stars, 'star')}`}
    />
  ) : (
    <SvgStar
      fill={defaultStarColor}
      role="radio"
      onClick={onClick}
      width={40}
      height={40}
      aria-label={`${stars} ${pluralize(stars, 'star')}`}
    />
  );

export const Review: FunctionComponent<ReviewComponent> = (props) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const siteContext = useSiteContext();
  const [name, setName] = useState('');
  const [starRating, setStarRating] = useState(0);
  const [mode, setMode] = useState<
    'selectStars' | 'writeReview' | 'redirectingToGoogle' | 'submitted'
  >('selectStars');
  const [review, setReview] = useState('');
  const [submitReview, { loading: isSubmitting }] = useMutation(SubmitReviewDocument, {
    onError,
  });

  let googlePlaceId: string | null | undefined;
  if (props.googlePlace?.type === DataSource.Prop) {
    const propName = props.googlePlace.name;
    if (propName) {
      const propsContext = use(PropsContext);
      googlePlaceId = stringOrDefaultNull(propsContext[propName]);
    }
  } else {
    googlePlaceId = props.googlePlace?.googlePlaceId;
  }

  const onSelectStars = (rating: 1 | 2 | 3 | 4 | 5) => () => {
    setStarRating(rating);
    if (rating === 5 && googlePlaceId) {
      void submitReview({
        variables: {
          starRating: rating,
          submitterName: null,
          review: '',
        },
      });
      setMode('redirectingToGoogle');
      setTimeout(() => {
        window.location.replace(writeGoogleReviewUrl(googlePlaceId));
      }, 5_000);
      return;
    }
    if (mode === 'selectStars') {
      setMode('writeReview');
    }
  };

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <h1 className="font-medium mt-0 mb-3 text-center text-lg uppercase">
        Review {siteContext.name}
      </h1>
      {mode !== 'submitted' && (
        <Fragment>
          <p className="text-lg text-center mb-1">
            {props.selectStarsLabel || defaultSelectStarsLabel}
          </p>
          <div
            className="flex flex-row justify-content-center gap-1 py-3"
            role="radiogroup"
            aria-label="Select stars"
          >
            <Star stars={1} filled={starRating >= 1} onClick={onSelectStars(1)} />
            <Star stars={2} filled={starRating >= 2} onClick={onSelectStars(2)} />
            <Star stars={3} filled={starRating >= 3} onClick={onSelectStars(3)} />
            <Star stars={4} filled={starRating >= 4} onClick={onSelectStars(4)} />
            <Star stars={5} filled={starRating >= 5} onClick={onSelectStars(5)} />
          </div>
        </Fragment>
      )}
      {mode === 'writeReview' ? (
        <Fragment>
          <FormField>
            <InputText
              className="w-full"
              value={name}
              placeholder="Your name (optional)"
              maxLength={80}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </FormField>
          <InputTextarea
            value={review}
            className="w-full max-w-full"
            placeholder={props.reviewFieldPlaceholder || defaultReviewFieldPlaceholder}
            maxLength={5_000}
            onChange={(e) => {
              setReview(e.target.value);
            }}
          />
          <Button
            label="Submit"
            className="mt-3 font-bold"
            disabled={!starRating || !review || isSubmitting}
            onClick={() => {
              void submitReview({
                variables: {
                  starRating,
                  submitterName: name,
                  review,
                },
                onCompleted: () => {
                  setMode('submitted');
                },
              });
            }}
          />
        </Fragment>
      ) : mode === 'redirectingToGoogle' && googlePlaceId ? (
        <div className="text-center">
          <p className="text-lg">
            Thank you for the love! ❤️ You are being redirected to{' '}
            <span className="font-medium">Google</span>
            —please leave us a review there&hellip;
          </p>
          <a href={writeGoogleReviewUrl(googlePlaceId)}>Click here if you are not redirected</a>
        </div>
      ) : (
        mode === 'submitted' && (
          <p className="text-center my-4 text-lg">
            Thank you for your review. We are glad to do business with you.
          </p>
        )
      )}
    </div>
  );
};
