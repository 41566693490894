'use client';

import { clsx } from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { themePropDefs } from './theme.props.js';
import type { ThemeOwnProps } from './theme.props.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import { createContext, type Ref, use, useMemo } from 'react';

// A DirectionProvider and a TooltipPrimitiveProvider need to be mounted either immediately above or below the root theme context.
// Some components use the direction and tooltip contexts.
export { DirectionProvider } from '@radix-ui/react-direction';
export { Provider as TooltipPrimitiveProvider } from '@radix-ui/react-tooltip';

type ThemeAppearance = (typeof themePropDefs.appearance.values)[number];
type ThemeGrayColor = (typeof themePropDefs.grayColor.values)[number];
type ThemePanelBackground = (typeof themePropDefs.panelBackground.values)[number];
type ThemeRadius = (typeof themePropDefs.radius.values)[number];
type ThemeScaling = (typeof themePropDefs.scaling.values)[number];

export interface ThemeContext {
  appearance: ThemeAppearance;
  grayColor: ThemeGrayColor;
  panelBackground: ThemePanelBackground;
  radius: ThemeRadius;
  scaling: ThemeScaling;
}

export const ThemeContext = createContext<ThemeContext | undefined>(undefined);

export function useThemeContext() {
  const context = use(ThemeContext);
  if (context === undefined) {
    throw new Error('`useThemeContext` must be used within a `Theme`');
  }
  return context;
}

export interface ThemeProps
  extends ComponentPropsWithout<'div', RemovedProps | 'dir'>,
    ThemeOwnProps {
  ref?: Ref<HTMLDivElement>;
}

/**
 * Embed a theme within a component tree. This requires a root theme context to be provided higher up in the tree.
 */
export const Theme = ({ ref, ...props }: ThemeProps) => {
  const context = useThemeContext();
  const {
    asChild,
    hasBackground: hasBackgroundProp,
    //
    appearance = context.appearance,
    grayColor = context.grayColor,
    panelBackground = context.panelBackground,
    radius = context.radius,
    scaling = context.scaling,
    ...themeProps
  } = props;
  const Comp = asChild ? Slot : 'div';
  const hasBackground = hasBackgroundProp !== false;
  const value = useMemo<ThemeContext>(
    () => ({
      appearance,
      grayColor,
      panelBackground,
      radius,
      scaling,
    }),
    [appearance, grayColor, panelBackground, radius, scaling],
  );
  return (
    <ThemeContext value={value}>
      <Comp
        data-is-root-theme="false"
        data-gray-color={grayColor}
        data-has-background={hasBackground ? 'true' : 'false'}
        data-panel-background={panelBackground}
        data-radius={radius}
        data-scaling={scaling}
        {...themeProps}
        className={clsx(
          'radix-themes',
          {
            'light-theme': appearance === 'light',
            'dark-theme': appearance === 'dark',
          },
          themeProps.className,
        )}
        ref={ref}
      />
    </ThemeContext>
  );
};
