import type { FunctionComponent } from 'react';
import logo from './wirechunk-logo.svg';

const adminToolbarLogoStyle = {
  height: '36px',
  width: 'auto',
};

export const WirechunkLogo: FunctionComponent = () => (
  <img src={logo} alt="Wirechunk" style={adminToolbarLogoStyle} />
);
