import { noop } from 'lodash-es';
import type { ToastMessage } from 'primereact/toast';
import { Toast } from 'primereact/toast';
import type { FunctionComponent, ReactNode } from 'react';
import { createContext, Fragment, useCallback, use, useMemo, useRef } from 'react';

type ToastContext = {
  toastError: (message: string, heading?: string) => void;
  toastSuccess: (message: string, heading?: string) => void;
  toastWarn: (message: string, heading?: string) => void;
};

const ToastContext = createContext<ToastContext>({
  toastError: noop,
  toastSuccess: noop,
  toastWarn: noop,
});

type ToastProviderProps = {
  children: ReactNode;
};

export const ToastProvider: FunctionComponent<ToastProviderProps> = ({ children }) => {
  const ref = useRef<Toast>(null);
  const showToast = useCallback(
    (severity: ToastMessage['severity'], message: string, summary?: string) => {
      const toastMessage: ToastMessage = {
        severity,
        life: 6000,
        contentClassName: 'gap-1',
        content: (
          <div className="p-toast-message-text">
            {summary && <span className="p-toast-summary">{summary}</span>}
            <div className="p-toast-detail">{message}</div>
          </div>
        ),
      };
      if (ref.current) {
        ref.current.show(toastMessage);
      } else {
        setTimeout(() => {
          ref.current?.show(toastMessage);
        }, 0);
      }
    },
    [],
  );

  const value = useMemo<ToastContext>(
    () => ({
      toastError: (message, heading) => {
        showToast('error', message, heading);
      },
      toastSuccess: (message, heading) => {
        showToast('success', message, heading);
      },
      toastWarn: (message, heading) => {
        showToast('warn', message, heading);
      },
    }),
    [showToast],
  );

  return (
    <Fragment>
      <Toast ref={ref} />
      <ToastContext value={value}>{children}</ToastContext>
    </Fragment>
  );
};

export const useToast = (): ToastContext => use(ToastContext);
