import { useSuspenseQuery } from '@wirechunk/apollo-client';
import type { RemoteComponentComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { RemoteMixerComponent } from '../../remote-mixer-component.tsx';
import { RemoteComponentDocument } from './queries.generated.ts';

export const RemoteComponent: FunctionComponent<RemoteComponentComponent> = (props) => {
  const { data } = useSuspenseQuery(RemoteComponentDocument, {
    variables: {
      extensionName: props.extensionName,
      componentName: props.componentName,
    },
  });
  return (
    <Fragment>
      {data.remoteComponent.stylesheetUrls.map((url) => (
        <link key={url} href={url} rel="stylesheet" />
      ))}
      <RemoteMixerComponent url={data.remoteComponent.url} />
    </Fragment>
  );
};
