import { clsx } from 'clsx';
import type { FunctionComponent, ReactNode, Ref } from 'react';
import styles from './PaddedContent.module.css';

type PaddedContentProps = {
  // Defaults to false.
  noBorder?: boolean;
  className?: string;
  children: ReactNode;
  ref?: Ref<HTMLDivElement>;
};

export const PaddedContent: FunctionComponent<PaddedContentProps> = ({
  noBorder = false,
  className,
  ref,
  children,
}) => (
  <div
    className={clsx(styles.paddedContent, !noBorder && 'border-1 border-round', className)}
    ref={ref}
  >
    {children}
  </div>
);
