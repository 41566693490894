import type { TextProps } from '@wirechunk/ui';
import { Text } from '@wirechunk/ui';
import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren } from 'react';
import styles from './InputNotice.module.css';

export enum NoticeSeverity {
  Error,
}

type InputNoticeProps = PropsWithChildren<{
  className?: string | null;
  // The default style is normal text.
  severity?: NoticeSeverity;
}> &
  TextProps;

export const InputNotice: FunctionComponent<InputNoticeProps> = ({
  className,
  children,
  severity,
  ...props
}) => (
  <Text
    asChild
    size="2"
    {...props}
    className={clsx(
      'block',
      className,
      styles.inputNotice,
      severity === NoticeSeverity.Error ? 'text-color-error' : undefined,
    )}
  >
    <small>{children}</small>
  </Text>
);
