import type { FunctionComponent } from 'react';
import { Fragment } from 'react';

type NotFoundProps = {
  // Disables the page title. Default to false.
  noTitle?: boolean;
};

export const NotFound: FunctionComponent<NotFoundProps> = ({ noTitle }) => (
  <Fragment>
    {!noTitle && <title>Not Found</title>}
    <p className="font-bold m-2">
      No page was found here. It’s possible that you don’t have permission to view this page.
    </p>
  </Fragment>
);
