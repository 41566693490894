import { useLazyQuery, useMutation, useQuery } from '@wirechunk/apollo-client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { AgreementGuardComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { Text } from '@wirechunk/ui';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import type { FunctionComponent } from 'react';
import { use, Fragment, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../../contexts/current-user-context.tsx';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { FormField } from '../../form-field/form-field.tsx';
import { Label } from '../../label/label.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import styles from './agreements-guard.module.css';
import { ConsentToAgreementDocument } from './mutations.generated.ts';
import {
  MeCurrentAgreementsSignedDocument,
  SiteLatestAgreementDocument,
} from './queries.generated.ts';

export const AgreementsGuard: FunctionComponent<AgreementGuardComponent> = ({
  requiredAgreementTypes,
  children,
  ...props
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { user } = use(CurrentUserContext);
  const { data: meData, loading: meLoading } = useQuery(MeCurrentAgreementsSignedDocument);
  const site = useSiteContext();
  const [fetchLatestAgreement, { data: siteLatestAgreementData, loading: latestAgreementLoading }] =
    useLazyQuery(SiteLatestAgreementDocument, { onError });
  const [consentToAgreement, { loading: isSavingConsent }] = useMutation(
    ConsentToAgreementDocument,
    {
      onError,
    },
  );
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [userName, setUserName] = useState('');

  const currentAgreementsSigned =
    meData?.me2?.__typename === 'User' ? meData.me2.currentAgreementsSigned : [];

  const firstUnsignedType = requiredAgreementTypes?.find(
    (type) => !currentAgreementsSigned.includes(type),
  );

  useEffect(() => {
    if (firstUnsignedType) {
      void fetchLatestAgreement({
        variables: { siteId: site.id, type: firstUnsignedType },
      });
      setAgreeChecked(false);
      setUserName('');
    }
  }, [fetchLatestAgreement, firstUnsignedType, site.id]);

  if (
    !requiredAgreementTypes ||
    requiredAgreementTypes.every((type) => currentAgreementsSigned.includes(type))
  ) {
    return <RenderMixerChildren>{children}</RenderMixerChildren>;
  }

  const currentAgreementToSign = siteLatestAgreementData?.publicSite.latestAgreement;

  return (
    <div className={clsx(componentClassName(props), 'm-3 pt-4 pb-5 flex justify-content-center')}>
      <div className={styles.innerBody}>
        <ErrorMessage />
        {meLoading || latestAgreementLoading ? (
          <Spinner py="3" />
        ) : user ? (
          currentAgreementToSign && (
            <Fragment>
              <p className="text-lg">
                Hi, {user.displayName}. We have some quick legal stuff to get out of the way before
                proceeding&hellip;
              </p>
              <p>Please scroll down to the end to sign.</p>
              <div className="mb-4 text-left p-2 border-1 border-round-sm white-space-pre-line">
                {currentAgreementToSign.text}
              </div>
              <div className="flex gap-1 align-items-center">
                <Checkbox
                  inputId="agreement-consent"
                  checked={agreeChecked}
                  onChange={(e) => {
                    setAgreeChecked(!!e.checked);
                  }}
                />
                <Label htmlFor="agreement-consent" className="ml-2 font-medium">
                  I agree to the terms above.
                </Label>
              </div>
              <FormField mt="3">
                <Label htmlFor="agreement-consent-name">Your full legal name</Label>
                <InputText
                  id="agreement-consent-name"
                  className="w-full"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
              </FormField>
              <Button
                label="Sign"
                className="mt-3"
                disabled={!agreeChecked || !userName.trim() || isSavingConsent}
                onClick={() => {
                  void consentToAgreement({
                    variables: {
                      agreementId: currentAgreementToSign.id,
                      userName: userName.trim(),
                    },
                  });
                }}
              />
            </Fragment>
          )
        ) : (
          <Text>You need to be signed in to view this page.</Text>
        )}
      </div>
    </div>
  );
};
