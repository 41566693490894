import type { PublicSitePageQuery } from '@wirechunk/lib/shared-queries/public-site-page-query.generated.ts';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { createContext, useMemo } from 'react';
import { tryParseObject } from '../../util/json.ts';
import { PropsContext } from '../props-context.ts';

export enum ViewMode {
  // An actual page (live mode).
  Live,
  // A page template.
  Preview,
  // No page found (live mode).
  NotFound,
}

export type PageContext =
  | {
      viewMode: ViewMode.Live | ViewMode.Preview;
      title: string;
      // TODO: Make this field required.
      page?: NonNullable<PublicSitePageQuery['publicSite']['page']>;
    }
  | { viewMode: ViewMode.NotFound };

export const defaultPageContext: PageContext = Object.freeze({
  viewMode: ViewMode.Live,
  title: '',
});

export const PageContext = createContext<PageContext>(defaultPageContext);

type PageContextProviderProps = PropsWithChildren<{
  page: PublicSitePageQuery['publicSite']['page'];
}>;

export const PageContextProvider: FunctionComponent<PageContextProviderProps> = ({
  page,
  children,
}) => {
  const pageContext = useMemo<PageContext>(
    () =>
      page ? { viewMode: ViewMode.Live, title: page.title, page } : { viewMode: ViewMode.NotFound },
    [page],
  );
  const propsContext = useMemo<ContextData>(
    () => (page?.props ? (tryParseObject(page.props) as ContextData) : {}),
    [page],
  );

  return (
    <PageContext value={pageContext}>
      <PropsContext value={propsContext}>{children}</PropsContext>
    </PageContext>
  );
};
