import { captureException } from '@sentry/react';
import { renderStyles } from '@wirechunk/lib/mixer/styles.ts';
import { isStyles } from '@wirechunk/lib/mixer/utils.ts';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo } from 'react';
import { ParseAndRenderComponents } from './ParseAndRenderComponents.tsx';

type ParseAndRenderPageProps = {
  componentsJSON: string | undefined;
  bodyStylesJSON: string | undefined;
};

export const ParseAndRenderPage: FunctionComponent<ParseAndRenderPageProps> = ({
  componentsJSON,
  bodyStylesJSON,
}) => {
  const bodyCss = useMemo<string | null>(() => {
    if (bodyStylesJSON) {
      try {
        const bodyStyles: unknown = JSON.parse(bodyStylesJSON);
        if (isStyles(bodyStyles)) {
          return renderStyles('body', bodyStyles);
        }
      } catch (error) {
        captureException(error);
      }
    }
    return null;
  }, [bodyStylesJSON]);

  return (
    <Fragment>
      {bodyCss && <style>{bodyCss}</style>}
      <ParseAndRenderComponents componentsJSON={componentsJSON} />
    </Fragment>
  );
};
