import { previewPageTemplatePath } from '@wirechunk/lib/page-template-preview.ts';
import type { PublicSiteLayoutForPathQuery } from '@wirechunk/lib/shared-queries/public-site-layout-for-path-query.generated.ts';
import { PublicSiteLayoutForPathDocument } from '@wirechunk/lib/shared-queries/public-site-layout-for-path-query.generated.ts';
import type { PublicSitePageQuery } from '@wirechunk/lib/shared-queries/public-site-page-query.generated.ts';
import { PublicSitePageDocument } from '@wirechunk/lib/shared-queries/public-site-page-query.generated.ts';
import type { FunctionComponent } from 'react';
import { lazy } from 'react';
import { createBrowserRouter, useLoaderData } from 'react-router';
import { apolloClient } from './apollo-client.ts';
import { CurrentSiteProvider } from './components/current-site-provider.tsx';
import { NotFound } from './components/NotFound/NotFound.tsx';
import { PageWithMeta } from './components/Page/page-with-meta.tsx';
import { ParseAndRenderComponents } from './components/ParseAndRenderComponents.tsx';
import { RethrowErrorBoundary } from './components/rethrow-error-boundary.tsx';
import { DialogProvider } from './contexts/DialogContext/DialogContext.tsx';
import { PageContextProvider } from './contexts/PageContext/page-context.tsx';

const { admin, siteId } = window.wirechunk;

const PreviewPageTemplate = lazy(
  () => import('./components/PreviewPageTemplate/preview-page-template.tsx'),
);

type PageRouteData = {
  page: PublicSitePageQuery['publicSite']['page'];
  layout: PublicSiteLayoutForPathQuery['publicSite']['layoutForPath'];
};

const PageRoute: FunctionComponent = () => {
  const { page, layout } = useLoaderData<PageRouteData>();

  return (
    <CurrentSiteProvider>
      <PageContextProvider page={page}>
        <DialogProvider>
          {layout ? (
            <ParseAndRenderComponents
              // key is needed so that state is reset if an error bounder is hit.
              key={layout.id + (page?.id ?? '')}
              componentsJSON={layout.components}
            />
          ) : page ? (
            <PageWithMeta
              // key is needed so that state is reset if an error bounder is hit.
              key={page.id}
              page={page}
            />
          ) : (
            <NotFound />
          )}
        </DialogProvider>
      </PageContextProvider>
    </CurrentSiteProvider>
  );
};

// All the base paths included in the admin dashboard routes.
const adminPaths = [
  '/api-tokens',
  '/create-password',
  '/dashboard',
  '/profile',
  '/reset-password',
  '/verify-email-address',
];

let dashboardPatched = false;

// The Dialog provider needs to be rendered within the RouterProvider so that anything that's rendered within the
// dialog has access to the router context. Note that toasts do not have access to the router.
export const router = createBrowserRouter(
  [
    {
      path: previewPageTemplatePath,
      children: [
        {
          // Page template previews are unauthenticated because users are not authenticated under site domains.
          // TODO: Use a temporary auth token in the URL. Also support previewing unpublished templates.
          // TODO: This does not render layouts... should provide a PageContext and within it render layout.
          path: ':templateId',
          element: (
            <DialogProvider>
              <PreviewPageTemplate />
            </DialogProvider>
          ),
        },
      ],
      ErrorBoundary: RethrowErrorBoundary,
    },
    {
      path: '*',
      Component: PageRoute,
      loader: async ({ params }): Promise<PageRouteData> => {
        const path = params['*'] as string;
        // This is the only way to avoid the query when the page is in the cache.
        const cachedPageData = apolloClient.readQuery<PublicSitePageQuery>({
          query: PublicSitePageDocument,
          variables: { siteId, path },
        });
        if (cachedPageData) {
          return {
            layout: cachedPageData.publicSite.page
              ? cachedPageData.publicSite.page.resolvedLayout
              : apolloClient.readQuery<PublicSiteLayoutForPathQuery>({
                  query: PublicSiteLayoutForPathDocument,
                  variables: { siteId, path },
                })?.publicSite.layoutForPath,
            page: cachedPageData.publicSite.page,
          };
        }
        const { data: pageData } = await apolloClient.query({
          query: PublicSitePageDocument,
          variables: { siteId, path },
        });
        const { page } = pageData.publicSite;
        let layout = page?.resolvedLayout;
        if (!page) {
          const { data: layoutData } = await apolloClient.query({
            query: PublicSiteLayoutForPathDocument,
            variables: { siteId, path },
          });
          layout = layoutData.publicSite.layoutForPath;
        }
        return {
          layout,
          page,
        };
      },
      ErrorBoundary: RethrowErrorBoundary,
    },
  ],
  {
    patchRoutesOnNavigation: admin
      ? async ({ path, patch }) => {
          if (!dashboardPatched && adminPaths.some((adminPath) => path.startsWith(adminPath))) {
            const { adminRoutes } = await import('./routes/admin-dashboard.tsx');
            patch(null, adminRoutes);
            dashboardPatched = true;
          }
        }
      : undefined,
  },
);
