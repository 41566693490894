import { createContext, use } from 'react';

type InputValidationErrorMessageContext = string | null;

const context = createContext<InputValidationErrorMessageContext>(null);

export const InputValidationErrorMessageProvider = context.Provider;

export const useInputValidationErrorMessage = (): InputValidationErrorMessageContext =>
  use(context);
