import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { RadioGroupInputButtonComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { Flex, RadioCards, RadioGroup } from '@wirechunk/ui';
import type { FunctionComponent } from 'react';
import { useId } from 'react';
import { useConditionalStylesAttribute } from '../../../hooks/use-conditional-styles-attribute.ts';
import { useMixerContextData } from '../../../hooks/use-mixer-context-data.tsx';
import { MixerLabel } from '../../mixer-common/mixer-label.tsx';
import { useStringValue } from '../../mixer-hocs/use-string-value.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';
import { useRadioGroupInputContext } from '../radio-group-input/radio-group-input-context.ts';

export const RadioGroupInputButton: FunctionComponent<RadioGroupInputButtonComponent> = (props) => {
  const contextData = useMixerContextData();
  const radioGroupInputContext = useRadioGroupInputContext();
  const conditionalStylesAttr = useConditionalStylesAttribute(props, contextData);
  const id = useId();
  const value = useStringValue(props);

  if (!radioGroupInputContext || !value) {
    return null;
  }

  if (radioGroupInputContext.displayType === 'indicators') {
    return (
      <Flex gap="2" align="center" className={componentClassName(props)} {...conditionalStylesAttr}>
        <RadioGroup.Item id={id} value={value} />
        {props.childrenOrLabel === 'children' ? (
          <RenderMixerChildren>{props.children}</RenderMixerChildren>
        ) : (
          <MixerLabel label={props.label} inputId={id} mb="0" />
        )}
      </Flex>
    );
  }

  return (
    <RadioCards.Item {...conditionalStylesAttr} className={componentClassName(props)} value={value}>
      <RenderMixerChildren>{props.children}</RenderMixerChildren>
    </RadioCards.Item>
  );
};
