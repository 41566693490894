import { Box, type BoxProps } from '@wirechunk/ui';
import type { LayoutProps } from '@wirechunk/ui/props/layout.props.d.ts';
import type { MarginProps } from '@wirechunk/ui/props/margin.props.d.ts';
import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren } from 'react';
import styles from './form-field.module.css';

// Note className is added to the default class name.
type FormFieldProps = PropsWithChildren<
  LayoutProps & MarginProps & Pick<BoxProps, 'className' | 'display' | 'asChild'>
>;

export const FormField: FunctionComponent<FormFieldProps> = ({ children, className, ...props }) => (
  <Box {...props} className={clsx(styles.formField, className)}>
    {children}
  </Box>
);
