'use client';

import { clsx } from 'clsx';
import { createContextScope } from '@radix-ui/react-context';

import * as CheckboxGroupPrimitive from './_internal/checkbox-group.primitive.js';
import { createCheckboxGroupScope } from './_internal/checkbox-group.primitive.js';
import { checkboxCardsRootPropDefs } from './checkbox-cards.props.js';
import { baseCheckboxPropDefs } from './_internal/base-checkbox.props.js';
import { Grid } from './grid.js';
import { ThickCheckIcon } from './icons.js';
import { extractProps } from '../helpers/extract-props.js';
import { marginPropDefs } from '../props/margin.props.js';

import type { Scope } from '@radix-ui/react-context';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { MarginProps } from '../props/margin.props.js';
import type { Responsive, GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

const CHECKBOX_CARD_GROUP_NAME = 'CheckboxCards';

type ScopedProps<P> = P & { __scopeCheckboxCards?: Scope };
const [createCheckboxCardsContext] = createContextScope(CHECKBOX_CARD_GROUP_NAME, [
  createCheckboxGroupScope,
]);
const useCheckboxGroupScope = createCheckboxGroupScope();

type CheckboxCardsContextValue = {
  size?: Responsive<(typeof checkboxCardsRootPropDefs.size.values)[number]>;
  highContrast?: boolean;
};

const [CheckboxCardsProvider, useCheckboxCardsContext] =
  createCheckboxCardsContext<CheckboxCardsContextValue>(CHECKBOX_CARD_GROUP_NAME);

type CheckboxCardsRootElement = ComponentRef<typeof CheckboxGroupPrimitive.Root>;
type CheckboxCardsRootOwnProps = GetPropDefTypes<typeof checkboxCardsRootPropDefs>;
interface CheckboxCardsRootProps
  extends ComponentPropsWithout<
      typeof CheckboxGroupPrimitive.Root,
      'asChild' | 'color' | 'defaultChecked'
    >,
    MarginProps,
    CheckboxCardsRootOwnProps {}
const CheckboxCardsRoot = ({
  ref: forwardedRef,
  ...props
}: CheckboxCardsRootProps & {
  ref?: Ref<CheckboxCardsRootElement>;
}) => {
  const { __scopeCheckboxCards, className, color, ...rootProps } = extractProps(
    props as ScopedProps<CheckboxCardsRootProps>,
    checkboxCardsRootPropDefs,
    marginPropDefs,
  );
  const checkboxGroupScope = useCheckboxGroupScope(__scopeCheckboxCards);
  return (
    <CheckboxCardsProvider
      scope={__scopeCheckboxCards}
      size={props.size}
      highContrast={props.highContrast}
    >
      <Grid asChild>
        <CheckboxGroupPrimitive.Root
          {...checkboxGroupScope}
          data-accent-color={color}
          {...rootProps}
          ref={forwardedRef}
          className={clsx('rt-CheckboxCardsRoot', className)}
        />
      </Grid>
    </CheckboxCardsProvider>
  );
};
CheckboxCardsRoot.displayName = 'CheckboxCards.Root';

type CheckboxCardsItemElement = ComponentRef<typeof CheckboxGroupPrimitive.Item>;
interface CheckboxCardsItemProps
  extends ComponentPropsWithout<typeof CheckboxGroupPrimitive.Item, RemovedProps>,
    MarginProps {}
const CheckboxCardsItem = ({
  ref: forwardedRef,
  __scopeCheckboxCards,
  children,
  className,
  style,
  ...props
}: ScopedProps<CheckboxCardsItemProps> & {
  ref?: Ref<CheckboxCardsItemElement>;
}) => {
  const context = useCheckboxCardsContext('CheckboxCardsItem', __scopeCheckboxCards);
  const checkboxGroupScope = useCheckboxGroupScope(__scopeCheckboxCards);
  const { className: checkboxClassName } = extractProps(
    // Pass size / highContrast values from the context and static variant to generate styles
    { size: context?.size, variant: 'surface', highContrast: context?.highContrast },
    // Pass size & variant prop defs to allow it to be extracted
    baseCheckboxPropDefs,
  );
  return (
    <label className={clsx('rt-BaseCard', 'rt-CheckboxCardsItem', className)} style={style}>
      {children}
      <CheckboxGroupPrimitive.Item
        {...checkboxGroupScope}
        {...props}
        ref={forwardedRef}
        className={clsx(
          'rt-reset',
          'rt-BaseCheckboxRoot',
          'rt-CheckboxCardCheckbox',
          checkboxClassName,
        )}
      >
        <CheckboxGroupPrimitive.Indicator
          {...checkboxGroupScope}
          asChild
          className="rt-BaseCheckboxIndicator"
        >
          <ThickCheckIcon />
        </CheckboxGroupPrimitive.Indicator>
      </CheckboxGroupPrimitive.Item>
    </label>
  );
};
CheckboxCardsItem.displayName = 'CheckboxCards.Item';

export { CheckboxCardsRoot as Root, CheckboxCardsItem as Item };
export type { CheckboxCardsRootProps as RootProps, CheckboxCardsItemProps as ItemProps };
