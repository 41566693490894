import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { PhoneLinkComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { removeNonDigits, stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import type { FunctionComponent } from 'react';
import { use } from 'react';
import { PropsContext } from '../../contexts/props-context.ts';
import { RenderMixerChildren } from '../RenderMixerChildren.tsx';

export const PhoneLink: FunctionComponent<PhoneLinkComponent> = (props) => {
  let phone: string | null | undefined = null;
  if (props.phoneNumber?.type === DataSource.Direct) {
    phone = props.phoneNumber.phoneNumber && removeNonDigits(props.phoneNumber.phoneNumber);
  } else if (props.phoneNumber?.name) {
    const propsContext = use(PropsContext);
    const value = stringOrDefaultNull(propsContext[props.phoneNumber.name]);
    phone = value && removeNonDigits(value);
  }

  if (!phone) {
    return null;
  }

  return (
    <a className={componentClassName(props)} href={`tel:${phone}`}>
      <RenderMixerChildren>{props.children}</RenderMixerChildren>
    </a>
  );
};
