import * as React from 'react';
import { clsx } from 'clsx';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { tabsListPropDefs } from './tabs.props.js';
import { extractProps } from '../helpers/extract-props.js';
import { marginPropDefs } from '../props/margin.props.js';

import type { tabsContentPropDefs, tabsRootPropDefs } from './tabs.props.js';
import type { MarginProps } from '../props/margin.props.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

type TabsRootElement = ComponentRef<typeof TabsPrimitive.Root>;
type TabsRootOwnProps = GetPropDefTypes<typeof tabsRootPropDefs>;
interface TabsRootProps
  extends ComponentPropsWithout<typeof TabsPrimitive.Root, 'asChild' | 'color' | 'defaultChecked'>,
    MarginProps,
    TabsRootOwnProps {}
const TabsRoot = ({
  ref: forwardedRef,
  ...props
}: TabsRootProps & {
  ref?: Ref<TabsRootElement>;
}) => {
  const { className, ...rootProps } = extractProps(props, marginPropDefs);
  return (
    <TabsPrimitive.Root
      {...rootProps}
      ref={forwardedRef}
      className={clsx('rt-TabsRoot', className)}
    />
  );
};
TabsRoot.displayName = 'Tabs.Root';

type TabsListElement = ComponentRef<typeof TabsPrimitive.List>;
type TabsListOwnProps = GetPropDefTypes<typeof tabsListPropDefs>;
interface TabsListProps
  extends ComponentPropsWithout<typeof TabsPrimitive.List, RemovedProps>,
    MarginProps,
    TabsListOwnProps {}
const TabsList = ({
  ref: forwardedRef,
  ...props
}: TabsListProps & {
  ref?: Ref<TabsListElement>;
}) => {
  const { className, color, ...listProps } = extractProps(props, tabsListPropDefs, marginPropDefs);
  return (
    <TabsPrimitive.List
      data-accent-color={color}
      {...listProps}
      asChild={false}
      ref={forwardedRef}
      className={clsx('rt-BaseTabList', 'rt-TabsList', className)}
    />
  );
};
TabsList.displayName = 'Tabs.List';

type TabsTriggerElement = ComponentRef<typeof TabsPrimitive.Trigger>;
interface TabsTriggerProps
  extends ComponentPropsWithout<typeof TabsPrimitive.Trigger, RemovedProps> {}
const TabsTrigger = ({
  ref: forwardedRef,
  ...props
}: TabsTriggerProps & {
  ref?: Ref<TabsTriggerElement>;
}) => {
  const { className, children, ...triggerProps } = props;
  return (
    <TabsPrimitive.Trigger
      {...triggerProps}
      asChild={false}
      ref={forwardedRef}
      className={clsx('rt-reset', 'rt-BaseTabListTrigger', 'rt-TabsTrigger', className)}
    >
      <span className="rt-BaseTabListTriggerInner rt-TabsTriggerInner">{children}</span>
      <span className="rt-BaseTabListTriggerInnerHidden rt-TabsTriggerInnerHidden">{children}</span>
    </TabsPrimitive.Trigger>
  );
};
TabsTrigger.displayName = 'Tabs.Trigger';

type TabsContentElement = ComponentRef<typeof TabsPrimitive.Content>;
type TabsContentOwnProps = GetPropDefTypes<typeof tabsContentPropDefs>;
interface TabsContentProps
  extends ComponentPropsWithout<typeof TabsPrimitive.Content, RemovedProps>,
    MarginProps,
    TabsContentOwnProps {}
const TabsContent = ({
  ref: forwardedRef,
  ...props
}: TabsContentProps & {
  ref?: Ref<TabsContentElement>;
}) => {
  const { className, ...contentProps } = extractProps(props, marginPropDefs);
  return (
    <TabsPrimitive.Content
      {...contentProps}
      ref={forwardedRef}
      className={clsx('rt-TabsContent', className)}
    />
  );
};
TabsContent.displayName = 'Tabs.Content';

export { TabsRoot as Root, TabsList as List, TabsTrigger as Trigger, TabsContent as Content };
export type {
  TabsRootProps as RootProps,
  TabsListProps as ListProps,
  TabsTriggerProps as TriggerProps,
  TabsContentProps as ContentProps,
};
