import type { Delta as CustomFieldDelta } from '@wirechunk/schemas/custom-field/rich-text';
import { isPlainObject, isString } from 'lodash-es';
import type { TextContent } from './mixer/types/components.ts';
import type { RemapPropertiesToUnknown } from './util-types.ts';

export const emptyDeltaJSON = '{"ops":[]}';

// Returns whether value is an object that satisfies the Delta type from the GraphQL schema.
// In particular, this just checks that value is an object with a non-empty string delta property.
export const isTextContent = (value: unknown): value is TextContent =>
  isPlainObject(value) &&
  isString((value as RemapPropertiesToUnknown<TextContent>).delta) &&
  !!(value as TextContent).delta;

// Returns whether value is an object that satisfies the 'delta' property of the Delta type from
// the RichTextCustomField union type.
export const isDelta = (value: unknown): value is CustomFieldDelta['delta'] =>
  isPlainObject(value) &&
  Array.isArray((value as RemapPropertiesToUnknown<CustomFieldDelta['delta']>).ops);
