import { isNumber } from 'lodash-es';
import { isNonEmptyString } from './strings.ts';

export const genericErrorMessage = 'An unknown error occurred';

export const parseStatus = (err: unknown): number => {
  if (!err) {
    return 500;
  }
  if (isNumber(err)) {
    // Coalesce zero to 500.
    return err || 500;
  }
  if (isNumber((err as { status?: unknown }).status)) {
    // Coalesce zero to 500.
    return (err as { status: number }).status || 500;
  }
  if (isNumber((err as { statusCode?: unknown }).statusCode)) {
    // Coalesce zero to 500.
    return (err as { statusCode: number }).statusCode || 500;
  }
  return 500;
};

export const parseErrorMessage = (err: unknown): string => {
  if (!err) {
    return genericErrorMessage;
  }

  if (err instanceof Error && err.message) {
    return err.message;
  }

  if (isNonEmptyString((err as { message?: unknown }).message)) {
    return (err as { message: string }).message;
  }

  // A GraphQL response can contain an array of errors.
  if (Array.isArray((err as { errors?: unknown }).errors)) {
    const message = (err as { errors: unknown[] }).errors
      .map((e) =>
        e && (e instanceof Error || (e as { message?: unknown }).message)
          ? (e as { message: unknown }).message
          : undefined,
      )
      .filter(isNonEmptyString)
      .join(', ');
    if (message) {
      return message;
    }
  }

  // err can be a string containing a message.
  if (isNonEmptyString(err)) {
    return err;
  }

  return genericErrorMessage;
};

export const messageWithContext = (errorOrMessage: unknown, context?: string): string => {
  // parsedErrorOrMessage may or may not represent an error (e.g., it could represent debug info).
  const parsedErrorOrMessage = parseErrorMessage(errorOrMessage);

  if (context) {
    // Here we want to append a message from parsedError only if it's something potentially useful, not the generic
    // message that the parseErrorMessage function provides as a fallback.
    if (
      errorOrMessage instanceof Error &&
      isNonEmptyString(errorOrMessage.message) &&
      errorOrMessage.message !== context &&
      errorOrMessage.message !== genericErrorMessage
    ) {
      return `${context}: ${errorOrMessage.message}`;
    }
    if (
      isNonEmptyString(parsedErrorOrMessage) &&
      parsedErrorOrMessage !== context &&
      parsedErrorOrMessage !== genericErrorMessage
    ) {
      return `${context}: ${parsedErrorOrMessage}`;
    }
    return context;
  }

  return parsedErrorOrMessage;
};
