import { clsx } from 'clsx';
import type { FunctionComponent } from 'react';
import styles from './google-map.module.css';

const { gMapsKey } = window.wirechunk;

type GoogleMapProps = {
  className?: string;
  googlePlaceId: string;
};

export const GoogleMap: FunctionComponent<GoogleMapProps> = ({ className, googlePlaceId }) => {
  return (
    <div className={clsx(styles.map, className)}>
      <iframe
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${gMapsKey}&q=place_id:${googlePlaceId}`}
      ></iframe>
    </div>
  );
};
