import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { LinkComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router';
import { RenderMixerChildren } from '../RenderMixerChildren.tsx';

export const Link: FunctionComponent<LinkComponent> = (props) => {
  const to = props.to || '#';
  const target = props.openInNewTab ? '_blank' : undefined;
  const rel = props.openInNewTab ? 'noopener noreferrer' : undefined;

  if (props.isExternal) {
    return (
      <a href={to} className={componentClassName(props)} target={target} rel={rel}>
        <RenderMixerChildren>{props.children}</RenderMixerChildren>
      </a>
    );
  }

  return (
    <RouterLink to={to} className={componentClassName(props)} target={target} rel={rel}>
      <RenderMixerChildren>{props.children}</RenderMixerChildren>
    </RouterLink>
  );
};
