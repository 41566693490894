import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { MoveUserPlanComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useSequenceStageContext } from '../sequence/stage/sequence-stage-context.ts';
import { useEditSequenceUser } from './useEditUserPlanLive/use-edit-sequence-user.ts';

export const MoveSequenceUser: FunctionComponent<MoveUserPlanComponent> = (props) => {
  const { onErrorToast } = useErrorHandler();
  const stageContext = useSequenceStageContext();
  const { editSequenceUser, editSequenceUserLoading } = useEditSequenceUser(onErrorToast);

  if (!stageContext) {
    return null;
  }

  const { previousStageBlueprintId, nextStageBlueprintId } = stageContext.sequenceUser;
  if (!previousStageBlueprintId && !nextStageBlueprintId) {
    return null;
  }

  return (
    <div className={`${componentClassName(props)} p-button-group`}>
      {previousStageBlueprintId && (
        <Button
          className={clsx('pr-3', !nextStageBlueprintId && 'border-round')}
          label={
            props.previousButtonLabel ||
            `Previous ${stageContext.sequence.stageLabel.toLowerCase()}`
          }
          icon={PrimeIcons.ARROW_LEFT}
          disabled={editSequenceUserLoading}
          onClick={() => {
            void editSequenceUser(stageContext.sequence.id, previousStageBlueprintId);
          }}
        />
      )}
      {nextStageBlueprintId && (
        <Button
          className={clsx('pl-3', !previousStageBlueprintId && 'border-round')}
          label={props.nextButtonLabel || `Next ${stageContext.sequence.stageLabel.toLowerCase()}`}
          icon={PrimeIcons.ARROW_RIGHT}
          iconPos="right"
          disabled={editSequenceUserLoading}
          onClick={() => {
            return void editSequenceUser(stageContext.sequence.id, nextStageBlueprintId);
          }}
        />
      )}
    </div>
  );
};
