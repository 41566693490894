import { captureException } from '@sentry/react';
import type { ApolloClient, NormalizedCacheObject } from '@wirechunk/apollo-client';
import { MeZendeskChatTokenDocument } from './queries.generated.ts';

let { zendeskChatAuth } = window.wirechunk;

type ZE = {
  (
    object: 'messenger',
    method: 'loginUser',
    jwtCallback: (callback: (token: string | null) => void) => void | Promise<void>,
    errorCallback: (error: unknown) => void,
  ): void;
  (object: 'messenger', method: 'logoutUser' | 'open'): void;
};

// See https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/
declare global {
  interface Window {
    zE?: ZE;
  }
}

export const openMessenger = (): void => {
  window.zE?.('messenger', 'open');
};

// Authenticate the current user with Zendesk chat. This function should be called only if
// a user is signed in.
export const authenticateMessenger = (apolloClient: ApolloClient<NormalizedCacheObject>): void => {
  if (zendeskChatAuth?.enabled === false) {
    // Zendesk chat authentication is not configured.
    return;
  }
  window.zE?.(
    'messenger',
    'loginUser',
    async (callback) => {
      if (zendeskChatAuth?.enabled) {
        callback(zendeskChatAuth.token);
        // Reset so that a user can sign out and sign back in.
        zendeskChatAuth = undefined;
        return;
      }
      try {
        const { data } = await apolloClient.query({
          query: MeZendeskChatTokenDocument,
          fetchPolicy: 'no-cache',
        });
        const zendeskChatToken =
          data.me2?.__typename === 'User' ? data.me2.zendeskChatToken : undefined;
        if (zendeskChatToken) {
          callback(zendeskChatToken);
          return;
        }
      } catch (error) {
        captureException(error);
      }
      callback(null);
    },
    (error) => {
      if (error) {
        captureException(error);
      }
    },
  );
};

export const signOutMessenger = (): void => {
  window.zE?.('messenger', 'logoutUser');
};
