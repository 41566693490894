import * as React from 'react';
import { clsx } from 'clsx';
import { Slot } from '@radix-ui/react-slot';

import { requireReactElement } from '../helpers/require-react-element.js';

import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { Ref } from 'react';

interface ResetProps extends ComponentPropsWithout<typeof Slot, RemovedProps> {}
const Reset = ({
  ref: forwardedRef,
  className,
  children,
  ...props
}: ResetProps & {
  ref?: Ref<HTMLElement>;
}) => {
  return (
    <Slot {...props} ref={forwardedRef} className={clsx('rt-reset', className)}>
      {requireReactElement(children)}
    </Slot>
  );
};
Reset.displayName = 'Reset';

export { Reset };
export type { ResetProps };
