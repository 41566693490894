import * as React from 'react';
import { clsx } from 'clsx';
import { Slot } from '@radix-ui/react-slot';

import { cardPropDefs } from './card.props.js';
import { extractProps } from '../helpers/extract-props.js';
import { marginPropDefs } from '../props/margin.props.js';

import type { MarginProps } from '../props/margin.props.js';
import type { ComponentPropsWithout, RemovedProps } from '../helpers/component-props.js';
import type { GetPropDefTypes } from '../props/prop-def.js';
import type { ComponentRef, Ref } from 'react';

type CardElement = ComponentRef<'div'>;
type CardOwnProps = GetPropDefTypes<typeof cardPropDefs>;
interface CardProps extends ComponentPropsWithout<'div', RemovedProps>, MarginProps, CardOwnProps {}
const Card = ({
  ref: forwardedRef,
  ...props
}: CardProps & {
  ref?: Ref<CardElement>;
}) => {
  const { asChild, className, ...cardProps } = extractProps(props, cardPropDefs, marginPropDefs);
  const Comp = asChild ? Slot : 'div';
  return (
    <Comp
      ref={forwardedRef}
      {...cardProps}
      className={clsx('rt-reset', 'rt-BaseCard', 'rt-Card', className)}
    />
  );
};
Card.displayName = 'Card';

export { Card };
export type { CardProps };
