import * as React from 'react';
import { clsx } from 'clsx';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { alertDialogContentPropDefs } from './alert-dialog.props.js';
import { Heading } from './heading.js';
import { Text } from './text.js';
import { extractProps } from '../helpers/extract-props.js';
import { requireReactElement } from '../helpers/require-react-element.js';

import type { AlertDialogContentOwnProps } from './alert-dialog.props.js';
import type {
  ComponentPropsWithout,
  RemovedProps,
  ComponentPropsAs,
} from '../helpers/component-props.js';
import type { ComponentRef, Ref } from 'react';

interface AlertDialogRootProps
  extends React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Root> {}
const AlertDialogRoot: React.FC<AlertDialogRootProps> = (props) => (
  <AlertDialogPrimitive.Root {...props} />
);
AlertDialogRoot.displayName = 'AlertDialog.Root';

type AlertDialogTriggerElement = ComponentRef<typeof AlertDialogPrimitive.Trigger>;
interface AlertDialogTriggerProps
  extends ComponentPropsWithout<typeof AlertDialogPrimitive.Trigger, RemovedProps> {}
const AlertDialogTrigger = ({
  ref: forwardedRef,
  children,
  ...props
}: AlertDialogTriggerProps & {
  ref?: Ref<AlertDialogTriggerElement>;
}) => (
  <AlertDialogPrimitive.Trigger {...props} ref={forwardedRef} asChild>
    {requireReactElement(children)}
  </AlertDialogPrimitive.Trigger>
);
AlertDialogTrigger.displayName = 'AlertDialog.Trigger';

type AlertDialogContentElement = ComponentRef<typeof AlertDialogPrimitive.Content>;
interface AlertDialogContentProps
  extends ComponentPropsWithout<typeof AlertDialogPrimitive.Content, RemovedProps>,
    AlertDialogContentOwnProps {
  container?: React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Portal>['container'];
}
const AlertDialogContent = ({
  ref: forwardedRef,
  align,
  ...props
}: AlertDialogContentProps & {
  ref?: Ref<AlertDialogContentElement>;
}) => {
  const { align: alignPropDef, ...propDefs } = alertDialogContentPropDefs;
  const { className: alignClassName } = extractProps({ align }, { align: alignPropDef });
  const { className, forceMount, container, ...contentProps } = extractProps(props, propDefs);
  return (
    <AlertDialogPrimitive.Portal container={container} forceMount={forceMount}>
      <AlertDialogPrimitive.Overlay className="rt-BaseDialogOverlay rt-AlertDialogOverlay">
        <div className="rt-BaseDialogScroll rt-AlertDialogScroll">
          <div
            className={`rt-BaseDialogScrollPadding rt-AlertDialogScrollPadding ${alignClassName}`}
          >
            <AlertDialogPrimitive.Content
              {...contentProps}
              ref={forwardedRef}
              className={clsx('rt-BaseDialogContent', 'rt-AlertDialogContent', className)}
            />
          </div>
        </div>
      </AlertDialogPrimitive.Overlay>
    </AlertDialogPrimitive.Portal>
  );
};
AlertDialogContent.displayName = 'AlertDialog.Content';

type AlertDialogTitleElement = ComponentRef<typeof Heading>;
type AlertDialogTitleProps = ComponentPropsWithout<typeof Heading, 'asChild'>;
const AlertDialogTitle = ({
  ref: forwardedRef,
  ...props
}: AlertDialogTitleProps & {
  ref?: Ref<AlertDialogTitleElement>;
}) => (
  <AlertDialogPrimitive.Title asChild>
    <Heading size="5" mb="3" trim="start" {...props} asChild={false} ref={forwardedRef} />
  </AlertDialogPrimitive.Title>
);
AlertDialogTitle.displayName = 'AlertDialog.Title';

type AlertDialogDescriptionElement = HTMLParagraphElement;
type AlertDialogDescriptionProps = ComponentPropsAs<typeof Text, 'p'>;
const AlertDialogDescription = ({
  ref: forwardedRef,
  ...props
}: AlertDialogDescriptionProps & {
  ref?: Ref<AlertDialogDescriptionElement>;
}) => (
  <AlertDialogPrimitive.Description asChild>
    <Text as="p" size="3" {...props} asChild={false} ref={forwardedRef} />
  </AlertDialogPrimitive.Description>
);
AlertDialogDescription.displayName = 'AlertDialog.Description';

type AlertDialogActionElement = ComponentRef<typeof AlertDialogPrimitive.Action>;
interface AlertDialogActionProps
  extends ComponentPropsWithout<typeof AlertDialogPrimitive.Action, RemovedProps> {}
const AlertDialogAction = ({
  ref: forwardedRef,
  children,
  ...props
}: AlertDialogActionProps & {
  ref?: Ref<AlertDialogActionElement>;
}) => (
  <AlertDialogPrimitive.Action {...props} ref={forwardedRef} asChild>
    {requireReactElement(children)}
  </AlertDialogPrimitive.Action>
);
AlertDialogAction.displayName = 'AlertDialog.Action';

type AlertDialogCancelElement = ComponentRef<typeof AlertDialogPrimitive.Cancel>;
interface AlertDialogCancelProps
  extends ComponentPropsWithout<typeof AlertDialogPrimitive.Cancel, RemovedProps> {}
const AlertDialogCancel = ({
  ref: forwardedRef,
  children,
  ...props
}: AlertDialogCancelProps & {
  ref?: Ref<AlertDialogCancelElement>;
}) => (
  <AlertDialogPrimitive.Cancel {...props} ref={forwardedRef} asChild>
    {requireReactElement(children)}
  </AlertDialogPrimitive.Cancel>
);
AlertDialogCancel.displayName = 'AlertDialog.Cancel';

export {
  AlertDialogRoot as Root,
  AlertDialogTrigger as Trigger,
  AlertDialogContent as Content,
  AlertDialogTitle as Title,
  AlertDialogDescription as Description,
  AlertDialogAction as Action,
  AlertDialogCancel as Cancel,
};

export type {
  AlertDialogRootProps as RootProps,
  AlertDialogTriggerProps as TriggerProps,
  AlertDialogContentProps as ContentProps,
  AlertDialogTitleProps as TitleProps,
  AlertDialogDescriptionProps as DescriptionProps,
  AlertDialogActionProps as ActionProps,
  AlertDialogCancelProps as CancelProps,
};
