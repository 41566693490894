import { useMutation } from '@wirechunk/apollo-client';
import type { ComponentSpec } from '@wirechunk/lib/mixer/types/components.ts';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { use, Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { SiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { WirechunkLogo } from '../../admin/wirechunk-logo.tsx';
import { SiteLogoStandardStyle } from '../../site-logo-standard-style.tsx';
import { VerifyEmailAddressDocument } from './mutations.generated.ts';

const { admin } = window.wirechunk;

export const VerifyEmailAddress: FunctionComponent<ComponentSpec<'VerifyEmailAddress'>> = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { onError, ErrorMessage } = useErrorHandler();
  const [name, setName] = useState('');
  const [hasError, setHasError] = useState(false);
  const [verify, { loading }] = useMutation(VerifyEmailAddressDocument, {
    onError: (err) => {
      setHasError(true);
      onError(err);
    },
    onCompleted: (data) => {
      setName(data.verifyEmailAddress.firstName);
    },
  });

  const key = params.get('key');

  useEffect(() => {
    if (key) {
      void verify({ variables: { key } });
    }
  }, [key, verify]);

  const siteName = admin ? 'Wirechunk' : use(SiteContext).name;
  return (
    <div className="h-screen flex justify-content-center align-items-center surface-ground">
      <div className="w-22rem max-body-width-contained bg-white p-4 border-1 border-round mb-2">
        <div className="flex align-items-center justify-content-center gap-2 mb-4">
          {admin ? <WirechunkLogo /> : <SiteLogoStandardStyle />}
        </div>
        <ErrorMessage />
        {key ? (
          <div className="text-center">
            {loading ? (
              <Fragment>
                <div className="font-medium mb-3">Verifying your email address&hellip;</div>
                <i className="pi pi-spinner pi-spin text-xl text-color-primary" />
              </Fragment>
            ) : (
              !hasError && (
                <p className="font-medium mb-4">
                  {name ? `${name}! ` : ''}Your email address has been verified.
                </p>
              )
            )}
          </div>
        ) : (
          <p>This link is not valid.</p>
        )}
        <div className="text-center mt-3">
          <Button
            label={`Go to ${siteName}`}
            disabled={loading}
            onClick={() => {
              void navigate('/');
            }}
          />
        </div>
      </div>
    </div>
  );
};
