import type { ArrayExpression } from '@wirechunk/schemas/expressions/array-expression';
import type { BooleanExpression } from '@wirechunk/schemas/expressions/boolean-expression';
import type { Expression } from '@wirechunk/schemas/expressions/expression';
import type { NumberExpression } from '@wirechunk/schemas/expressions/number-expression';
import type { StringExpression } from '@wirechunk/schemas/expressions/string-expression';
import { isEqual, isNil, isNumber, isPlainObject } from 'lodash-es';
import { arrayOrDefaultNull } from '../arrays.ts';
import { booleanOrDefaultNull } from '../booleans.ts';
import { numberOrDefaultNull } from '../numbers.ts';
import { stringOrDefaultNull } from '../strings.ts';

// Special keys that are set on data objects only on the client.
export const CurrentTimeKey = Symbol('current-time');
export const EventSourceComponentNameKey = Symbol('event-source-component-name');
export const EventSourceComponentTypeKey = Symbol('event-source-component-type');
export const InputChangeEventValueKey = Symbol('custom-event-value');
export const PropsKey = Symbol('props');
export const UserEmailKey = Symbol('user-email');
export const UserProductItemsKey = Symbol('user-product-items');
export const UserRoleKey = Symbol('user-role');

export type SpecialKey =
  | typeof CurrentTimeKey
  | typeof EventSourceComponentNameKey
  | typeof EventSourceComponentTypeKey
  | typeof InputChangeEventValueKey
  | typeof PropsKey
  | typeof UserEmailKey
  | typeof UserProductItemsKey
  | typeof UserRoleKey;

const getPropertySafe = (obj: unknown, key: string | SpecialKey): unknown => {
  if (isPlainObject(obj)) {
    return (obj as Record<string | SpecialKey, unknown>)[key];
  }
  return null;
};

export const evaluateArrayExpression = (expr: ArrayExpression, data: unknown): unknown[] | null => {
  switch (expr.operator) {
    case 'arrayLiteral':
      return expr.value.map((element) => evaluateExpression(element, data));
    case 'filter':
      return (
        evaluateArrayExpression(expr.arrayExpression, data)?.filter((element) =>
          evaluateBooleanExpression(expr.filterExpression, element),
        ) || null
      );
    case 'inputChangeEventArrayValue':
      return arrayOrDefaultNull(getPropertySafe(data, InputChangeEventValueKey));
    case 'inputDataArray':
      return arrayOrDefaultNull(getPropertySafe(data, expr.fieldKey));
    case 'map':
      return (
        evaluateArrayExpression(expr.arrayExpression, data)?.map((element) =>
          evaluateExpression(expr.mapExpression, element),
        ) || null
      );
    case 'propsArray':
      return arrayOrDefaultNull(getPropertySafe(getPropertySafe(data, PropsKey), expr.prop));
    case 'userFeatureTags': {
      return arrayOrDefaultNull(getPropertySafe(data, UserProductItemsKey));
    }
    default:
      return null;
  }
};

export const evaluateBooleanExpression = (
  expr: BooleanExpression,
  data: unknown,
): boolean | null => {
  switch (expr.operator) {
    case 'all': {
      return (
        evaluateArrayExpression(expr.arrayExpression, data)?.every(
          // We check for equality with true so that nulls and other non-booleans are treated as false.
          (element) => evaluateBooleanExpression(expr.condition, element) === true,
        ) ?? null
      );
    }
    case 'arrayIncludes': {
      const value = evaluateExpression(expr.value, data);
      return (
        evaluateArrayExpression(expr.arrayExpression, data)?.some((element) =>
          isEqual(element, value),
        ) ?? null
      );
    }
    case 'greaterThan': {
      const left = evaluateNumberExpression(expr.left, data);
      const right = evaluateNumberExpression(expr.right, data);
      if (left === null || right === null) {
        return null;
      }
      return left >= right;
    }
    case 'booleanLiteral':
      return expr.value;
    case 'booleanValue':
      return booleanOrDefaultNull(getPropertySafe(data, expr.model.fieldKey));
    case 'both':
      return (
        evaluateBooleanExpression(expr.left, data) && evaluateBooleanExpression(expr.right, data)
      );
    case 'either':
      return (
        evaluateBooleanExpression(expr.left, data) || evaluateBooleanExpression(expr.right, data)
      );
    case 'equals':
      if (isArrayExpression(expr.left) && isArrayExpression(expr.right)) {
        return isEqual(
          evaluateArrayExpression(expr.left, data),
          evaluateArrayExpression(expr.right, data),
        );
      }
      if (isNumberExpression(expr.left) && isNumberExpression(expr.right)) {
        return (
          evaluateNumberExpression(expr.left, data) === evaluateNumberExpression(expr.right, data)
        );
      }
      if (isBooleanExpression(expr.left) && isBooleanExpression(expr.right)) {
        return (
          evaluateBooleanExpression(expr.left, data) === evaluateBooleanExpression(expr.right, data)
        );
      }
      if (isStringExpression(expr.left) && isStringExpression(expr.right)) {
        return (
          evaluateStringExpression(expr.left, data) === evaluateStringExpression(expr.right, data)
        );
      }
      return false;
    case 'exists':
      return !isNil(evaluateExpression(expr.expression, data));
    case 'inputChangeEventBooleanValue':
      return booleanOrDefaultNull(getPropertySafe(data, InputChangeEventValueKey));
    case 'inputDataBoolean':
      return booleanOrDefaultNull(getPropertySafe(data, expr.fieldKey));
    case 'not':
      return !evaluateBooleanExpression(expr.expression, data);
    case 'propsBoolean':
      return booleanOrDefaultNull(getPropertySafe(getPropertySafe(data, PropsKey), expr.prop));
    case 'some':
      return (
        evaluateArrayExpression(expr.arrayExpression, data)?.some((element) =>
          evaluateBooleanExpression(expr.condition, element),
        ) ?? null
      );
    default:
      return false;
  }
};

export const evaluateNumberExpression = (expr: NumberExpression, data: unknown): number | null => {
  switch (expr.operator) {
    case 'count': {
      return evaluateArrayExpression(expr.arrayExpression, data)?.length ?? null;
    }
    case 'currentTime':
      return numberOrDefaultNull(getPropertySafe(data, CurrentTimeKey));
    case 'inputChangeEventNumberValue':
      return numberOrDefaultNull(getPropertySafe(data, InputChangeEventValueKey));
    case 'inputDataNumber':
      return numberOrDefaultNull(getPropertySafe(data, expr.fieldKey));
    case 'numberLiteral':
      return expr.value;
    case 'propsNumber':
      return numberOrDefaultNull(getPropertySafe(getPropertySafe(data, PropsKey), expr.prop));
    case 'sum': {
      const left = evaluateNumberExpression(expr.left, data);
      const right = evaluateNumberExpression(expr.right, data);
      if (left === null || right === null) {
        return null;
      }
      return left + right;
    }
    case 'sumArray': {
      return (
        evaluateArrayExpression(expr.arrayExpression, data)?.reduce<number>(
          (sum, element) => sum + (isNumber(element) ? element : 0),
          0,
        ) ?? null
      );
    }
    case 'timeLiteral':
      return expr.value;
    default:
      return null;
  }
};

export const evaluateStringExpression = (expr: StringExpression, data: unknown): string | null => {
  switch (expr.operator) {
    case 'featureTag':
      return expr.tag;
    case 'eventSourceComponentName':
      return stringOrDefaultNull(getPropertySafe(data, EventSourceComponentNameKey));
    case 'eventSourceComponentType':
      return stringOrDefaultNull(getPropertySafe(data, EventSourceComponentTypeKey));
    case 'inputChangeEventStringValue':
      return stringOrDefaultNull(getPropertySafe(data, InputChangeEventValueKey));
    case 'inputDataString':
      return stringOrDefaultNull(getPropertySafe(data, expr.fieldKey));
    case 'propsString':
      return stringOrDefaultNull(getPropertySafe(getPropertySafe(data, PropsKey), expr.prop));
    case 'stringLiteral':
      return expr.value;
    case 'userEmail':
      return stringOrDefaultNull(getPropertySafe(data, UserEmailKey));
    case 'userRole':
      return stringOrDefaultNull(getPropertySafe(data, UserRoleKey));
    default:
      return null;
  }
};

const isArrayExpression = (expr: Expression): expr is ArrayExpression =>
  isArrayOperator(expr.operator);

export const isBooleanExpression = (expr: Expression): expr is BooleanExpression =>
  isBooleanOperator(expr.operator);

const isNumberExpression = (expr: Expression): expr is NumberExpression =>
  isNumberOperator(expr.operator);

const isStringExpression = (expr: Expression): expr is StringExpression =>
  isStringOperator(expr.operator);

export const evaluateExpression = (
  expr: Expression,
  data: unknown,
): boolean | number | string | null | unknown[] => {
  if (isArrayExpression(expr)) {
    return evaluateArrayExpression(expr, data);
  }
  if (isBooleanExpression(expr)) {
    return evaluateBooleanExpression(expr, data);
  }
  if (isNumberExpression(expr)) {
    return evaluateNumberExpression(expr, data);
  }
  if (isStringExpression(expr)) {
    return evaluateStringExpression(expr, data);
  }
  return null;
};

export type ExpressionOperator = Expression['operator'];

// An Incomplete type is an object type where all properties except for the "operator" property, if any,
// are converted to be nullable.
export type IncompleteExpression<T> = {
  [P in keyof T]: P extends 'operator'
    ? T[P]
    : T[P] extends Expression
      ? IncompleteExpression<T[P]> | null
      : T[P] extends Expression[]
        ? Array<IncompleteExpression<T[P][number]>> | null
        : T[P] | null;
};

export enum ExpressionType {
  Array = 'Array',
  Boolean = 'Boolean',
  Number = 'Number',
  String = 'String',
}

export const allExpressionTypes: ExpressionType[] = Object.values(ExpressionType);

export const expressionOperatorToHumanReadable = (operator: ExpressionOperator): string => {
  switch (operator) {
    case 'all':
      return 'All';
    case 'arrayLiteral':
      return 'Array literal';
    case 'arrayIncludes':
      return 'Array includes';
    case 'booleanLiteral':
      return 'Boolean literal';
    case 'booleanValue':
      return 'Boolean value (DEPRECATED)';
    case 'both':
      return 'Both';
    case 'count':
      return 'Count';
    case 'currentTime':
      return 'Current time';
    case 'either':
      return 'Either';
    case 'eventSourceComponentName':
      return 'Event source component’s name';
    case 'eventSourceComponentType':
      return 'Event source component’s type';
    case 'equals':
      return 'Equals';
    case 'exists':
      return 'Exists';
    case 'featureTag':
      return 'Product item';
    case 'filter':
      return 'Filter an array';
    case 'greaterThan':
      return 'Greater than';
    case 'inputChangeEventArrayValue':
      return 'Array from an input change event';
    case 'inputChangeEventBooleanValue':
      return 'Boolean from an input change event';
    case 'inputChangeEventNumberValue':
      return 'Number from an input change event';
    case 'inputChangeEventStringValue':
      return 'String from an input change event';
    case 'inputDataArray':
      return 'Array from input data';
    case 'inputDataBoolean':
      return 'Boolean from input data';
    case 'inputDataNumber':
      return 'Number from input data';
    case 'inputDataString':
      return 'String from input data';
    case 'map':
      return 'Map over an array';
    case 'not':
      return 'Not';
    case 'numberLiteral':
      return 'Number literal';
    case 'propsArray':
      return 'Array from props';
    case 'propsBoolean':
      return 'Boolean from props';
    case 'propsNumber':
      return 'Number from props';
    case 'propsString':
      return 'String from props';
    case 'some':
      return 'Some';
    case 'stringLiteral':
      return 'String literal';
    case 'sum':
      return 'Sum';
    case 'sumArray':
      return 'Sum over an array';
    case 'timeLiteral':
      return 'Time literal';
    case 'userEmail':
      return 'User’s email';
    case 'userFeatureTags':
      return 'User’s product items';
    case 'userRole':
      return 'User’s role';
  }
};

export const arrayOperators: Array<ArrayExpression['operator']> = [
  'arrayLiteral',
  'filter',
  'inputChangeEventArrayValue',
  'inputDataArray',
  'map',
  'propsArray',
  'userFeatureTags',
];

export const booleanOperators: Array<BooleanExpression['operator']> = [
  'all',
  'arrayIncludes',
  'greaterThan',
  'booleanLiteral',
  'booleanValue',
  'both',
  'either',
  'equals',
  'exists',
  'inputChangeEventBooleanValue',
  'inputDataBoolean',
  'not',
  'propsBoolean',
  'some',
];

export const numberOperators: Array<NumberExpression['operator']> = [
  'count',
  'currentTime',
  'inputChangeEventNumberValue',
  'inputDataNumber',
  'numberLiteral',
  'propsNumber',
  'sum',
  'sumArray',
  'timeLiteral',
];

export const stringOperators: Array<StringExpression['operator']> = [
  'eventSourceComponentName',
  'eventSourceComponentType',
  'featureTag',
  'inputChangeEventStringValue',
  'inputDataString',
  'propsString',
  'stringLiteral',
  'userEmail',
  'userRole',
];

const allOperators: Array<ExpressionOperator> = [
  ...arrayOperators,
  ...booleanOperators,
  ...numberOperators,
  ...stringOperators,
];

const operatorsWithoutOperands = [
  'currentTime',
  'eventSourceComponentName',
  'eventSourceComponentType',
  'inputChangeEventArrayValue',
  'inputChangeEventBooleanValue',
  'inputChangeEventNumberValue',
  'inputChangeEventStringValue',
  'userEmail',
  'userFeatureTags',
  'userRole',
] as const;

type OperatorWithoutOperands = (typeof operatorsWithoutOperands)[number];

export const isOperatorWithOperands = (
  operator: ExpressionOperator,
): operator is Exclude<ExpressionOperator, OperatorWithoutOperands> =>
  !operatorsWithoutOperands.includes(operator as never);

const isArrayOperator = (operator: ExpressionOperator): operator is ArrayExpression['operator'] =>
  arrayOperators.includes(operator as never);

const isBooleanOperator = (
  operator: ExpressionOperator,
): operator is BooleanExpression['operator'] => booleanOperators.includes(operator as never);

const isNumberOperator = (operator: ExpressionOperator): operator is NumberExpression['operator'] =>
  numberOperators.includes(operator as never);

const isStringOperator = (operator: ExpressionOperator): operator is StringExpression['operator'] =>
  stringOperators.includes(operator as never);

export const isExpressionOperator = (value: unknown): value is ExpressionOperator =>
  allOperators.includes(value as never);

export const isIncompleteArrayExpression = (
  expression: IncompleteExpression<Expression>,
): expression is IncompleteExpression<ArrayExpression> => isArrayOperator(expression.operator);

export const isIncompleteBooleanExpression = (
  expression: IncompleteExpression<Expression>,
): expression is IncompleteExpression<BooleanExpression> => isBooleanOperator(expression.operator);

export const isIncompleteNumberExpression = (
  expression: IncompleteExpression<Expression>,
): expression is IncompleteExpression<NumberExpression> => isNumberOperator(expression.operator);

export const isCompleteExpression = (
  expression: Expression | IncompleteExpression<Expression>,
): expression is Expression => {
  switch (expression.operator) {
    case 'all':
      return (
        expression.arrayExpression !== null &&
        expression.condition !== null &&
        isCompleteExpression(expression.arrayExpression) &&
        isCompleteExpression(expression.condition)
      );
    case 'arrayLiteral':
      return expression.value !== null;
    case 'arrayIncludes':
      return (
        expression.arrayExpression !== null &&
        expression.value !== null &&
        isCompleteExpression(expression.arrayExpression) &&
        isCompleteExpression(expression.value)
      );
    case 'greaterThan':
      return (
        expression.left !== null &&
        expression.right !== null &&
        isCompleteExpression(expression.left) &&
        isCompleteExpression(expression.right)
      );
    case 'booleanLiteral':
      // We cannot require truthiness here because false is a valid value.
      return expression.value !== null;
    case 'booleanValue':
      return !!expression.model?.fieldKey;
    case 'both':
      return (
        expression.left !== null &&
        expression.right !== null &&
        isCompleteExpression(expression.left) &&
        isCompleteExpression(expression.right)
      );
    case 'count':
      return (
        expression.arrayExpression !== null && isCompleteExpression(expression.arrayExpression)
      );
    case 'currentTime':
      return true;
    case 'either':
      return (
        expression.left !== null &&
        expression.right !== null &&
        isCompleteExpression(expression.left) &&
        isCompleteExpression(expression.right)
      );
    case 'eventSourceComponentName':
    case 'eventSourceComponentType':
      return true;
    case 'equals':
      return (
        expression.left !== null &&
        expression.right !== null &&
        isCompleteExpression(expression.left) &&
        isCompleteExpression(expression.right)
      );
    case 'exists':
      return expression.expression !== null && isCompleteExpression(expression.expression);
    case 'featureTag':
      return !!expression.tag;
    case 'filter':
      return (
        expression.arrayExpression !== null &&
        expression.filterExpression !== null &&
        isCompleteExpression(expression.arrayExpression) &&
        isCompleteExpression(expression.filterExpression)
      );
    case 'inputChangeEventArrayValue':
    case 'inputChangeEventBooleanValue':
    case 'inputChangeEventNumberValue':
    case 'inputChangeEventStringValue':
      return true;
    case 'inputDataBoolean':
    case 'inputDataArray':
    case 'inputDataNumber':
    case 'inputDataString':
      return !!expression.fieldKey;
    case 'map':
      return (
        expression.arrayExpression !== null &&
        expression.mapExpression !== null &&
        isCompleteExpression(expression.arrayExpression) &&
        isCompleteExpression(expression.mapExpression)
      );
    case 'not':
      return expression.expression !== null && isCompleteExpression(expression.expression);
    case 'numberLiteral':
      // We cannot require truthiness here because zero is a valid value.
      return expression.value !== null;
    case 'propsArray':
    case 'propsBoolean':
    case 'propsNumber':
    case 'propsString':
      return !!expression.prop;
    case 'some':
      return (
        expression.arrayExpression !== null &&
        expression.condition !== null &&
        isCompleteExpression(expression.arrayExpression) &&
        isCompleteExpression(expression.condition)
      );
    case 'stringLiteral':
      // Expression value can be an empty string because an empty string is value.
      return expression.value !== null;
    case 'sum':
      return (
        expression.left !== null &&
        expression.right !== null &&
        isCompleteExpression(expression.left) &&
        isCompleteExpression(expression.right)
      );
    case 'sumArray':
      return (
        expression.arrayExpression !== null && isCompleteExpression(expression.arrayExpression)
      );
    case 'timeLiteral':
      return isNumber(expression.value);
    case 'userEmail':
    case 'userFeatureTags':
    case 'userRole':
      return true;
  }
};

export const newExpressionForOperator = (
  operator: ExpressionOperator,
): IncompleteExpression<Expression> => {
  switch (operator) {
    case 'all':
      return {
        operator,
        arrayExpression: null,
        condition: null,
      };
    case 'arrayLiteral':
      return {
        operator,
        value: [],
      };
    case 'arrayIncludes':
      return {
        operator,
        arrayExpression: null,
        value: null,
      };
    case 'booleanLiteral':
      return {
        operator,
        // The UI for a null vs false value is the same, so we default to false.
        value: false,
      };
    case 'booleanValue':
      return {
        operator,
        model: null,
      };
    case 'both':
      return {
        operator,
        left: null,
        right: null,
      };
    case 'count':
      return {
        operator,
        arrayExpression: null,
      };
    case 'currentTime':
      return {
        operator,
      };
    case 'either':
      return {
        operator,
        left: null,
        right: null,
      };
    case 'eventSourceComponentName':
    case 'eventSourceComponentType':
      return {
        operator,
      };
    case 'equals':
      return {
        operator,
        left: null,
        right: null,
      };
    case 'exists':
      return {
        operator,
        expression: null,
      };
    case 'featureTag':
      return {
        operator,
        tag: null,
      };
    case 'filter':
      return {
        operator,
        arrayExpression: null,
        filterExpression: null,
      };
    case 'greaterThan':
      return {
        operator,
        left: null,
        right: null,
      };
    case 'inputChangeEventArrayValue':
    case 'inputChangeEventBooleanValue':
    case 'inputChangeEventNumberValue':
    case 'inputChangeEventStringValue':
      return {
        operator,
      };
    case 'inputDataArray':
    case 'inputDataBoolean':
    case 'inputDataNumber':
    case 'inputDataString':
      return {
        operator,
        fieldKey: null,
      };
    case 'map':
      return {
        operator,
        arrayExpression: null,
        mapExpression: null,
      };
    case 'not':
      return {
        operator,
        expression: null,
      };
    case 'numberLiteral':
      return {
        operator,
        value: null,
      };
    case 'propsArray':
    case 'propsBoolean':
    case 'propsNumber':
    case 'propsString':
      return {
        operator,
        prop: null,
      };
    case 'some':
      return {
        operator,
        arrayExpression: null,
        condition: null,
      };
    case 'stringLiteral':
      return {
        operator,
        value: '',
      };
    case 'sum':
      return {
        operator,
        left: null,
        right: null,
      };
    case 'sumArray':
      return {
        operator,
        arrayExpression: null,
      };
    case 'timeLiteral':
      return {
        operator,
        value: null,
      };
    case 'userEmail':
    case 'userFeatureTags':
    case 'userRole':
      return {
        operator,
      };
  }
};
